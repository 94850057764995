import { ArrowLeftOutlined, ArrowRightOutlined, ArrowsAltOutlined, DownOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Dropdown, Menu, Popover, Space, Tag, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { arycolor } from '../../../../assets/css/color';
import { defaultPlotlyArguments, getRecordNameShort, isMacOS, sum } from '../../../../compute/utils';
import {
  selectAggregatePeptides,
  selectBoundariesMap,
  selectColormap,
  selectCurrentRecordID,
  selectExcludedPeptides,
  selectExcludedRecordIDs,
  selectExcludedSpots,
  selectHumidityCompensationCalibrantName,
  selectHumidityCompensationPositionOffset,
  selectHumidityCompensationSubstractionGain,
  selectRecords,
  selectSensogramRenderType,
  selectSessionID,
  selectSubtractItemName,
  setBoundaries,
  setBoundariesMap,
  setRecordData,
} from '../../../../features/analysisConfig/analysisConfigSlice';
import { AryRecord, AuxiliarySensorPrettyNameMap, AuxiliarySensorType, BoundariesLockMode, EligibleAditionalSensorType, SensogramRenderType, TemperaturePrettyNameMap, TemperatureSeriesType, ZoneSelectorType } from '../../../../types/analysisTypes';
import FullscreenGraphicModal from '../../FullscreenGraphicModal';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../../../utils';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { SingleSensogramFigure } from './SingleSensogramFigure';

export const SingleSensogram: React.FC = () => {
  const { authState } = useOktaOrQueryAuth();

  const currentRecordID = useAppSelector(selectCurrentRecordID);
  const aggregatePeptides = useAppSelector(selectAggregatePeptides);
  const boundariesMap = useAppSelector(selectBoundariesMap);
  const records = useAppSelector(selectRecords);
  const subtractItemName = useAppSelector(selectSubtractItemName);
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const cmap = useAppSelector(selectColormap);
  const sessionID = useAppSelector(selectSessionID);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const excludedPeptides = useAppSelector(selectExcludedPeptides);
  const sensogramRenderType = useAppSelector(selectSensogramRenderType);

  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);
  const humidityCalibrationPositionOffset = useAppSelector(selectHumidityCompensationPositionOffset);
  const humidityCalibrationSubstractionGain = useAppSelector(selectHumidityCompensationSubstractionGain);

  const [zoneSelectorType, setZoneSelectorType] = useState<ZoneSelectorType>(ZoneSelectorType.Analyte);
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const [drawHumidity, setDrawHumidity] = useState(true);

  const [drawTemperature, setDrawTemperature] = useState(false);
  const [temperatureType, setTemperatureType] = useState<TemperatureSeriesType>(+TemperatureSeriesType.SensorTemperature);

  const [drawAuxiliarySensor, setDrawAuxiliarySensor] = useState(false);
  const [auxiliarySensorType, setAuxiliarySensorType] = useState<AuxiliarySensorType>();
  const [eligibleAuxiliarySensorTypes, setEligibleAuxiliarySensorTypes] = useState<[string, string | AuxiliarySensorType][]>();

  const [drawAdditionalSensor, setDrawAdditionalSensor] = useState(false);
  const [additionalSensorType, setAdditionalSensorType] = useState<EligibleAditionalSensorType>();
  const [eligibleAdditionalSensorTypes, setEligibleAdditionalSensorTypes] = useState<EligibleAditionalSensorType[]>();

  const [error, setError] = useState('');
  const [itemLocked, setItemLocked] = useState(BoundariesLockMode.Group);
  const [record, setRecord] = useState<AryRecord | null>(null);
  const [sensors, setSensors] = useState<string[] | undefined>();

  const [humidityReferencePosition, setHumidityReferencePosition] = useState<number | null>(null);

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    if (sensogramRenderType !== SensogramRenderType.Record) {
      return;
    }
    fetchAuthorizedAPIEndpoint(`/compute/humidity_reference_position?session_id=${sessionID}&record_id=${currentRecordID}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        aggregatePeptides,
        subtractItemName,
        excludedRecordIDs,
        excludedSpots,
        excludedPeptides,
        boundariesMap,
        humidityCompensation: {
          calibrantName: humidityCalibrationCalibrantName,
          positionOffset: humidityCalibrationPositionOffset,
          substractionGain: humidityCalibrationSubstractionGain,
        },
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((data: { PositionIndex: number }) => {
        setError('');
        setHumidityReferencePosition(data.PositionIndex);
      })
      .catch((e) => {
        Promise.resolve(e).then((resp: { Reason: string }) => {
          setError(resp.Reason);
        });
      });
  }, [
    authState,
    sessionID,
    currentRecordID,
    sensogramRenderType,
    aggregatePeptides,
    excludedRecordIDs,
    subtractItemName,
    excludedPeptides,
    excludedSpots,
    humidityCalibrationPositionOffset,
    humidityCalibrationCalibrantName,
    humidityCalibrationSubstractionGain,
    boundariesMap,
  ]);

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    if (records) setSensors(records?.find((record: AryRecord) => record.ID === currentRecordID)?.Sensors);
    fetchAuthorizedAPIEndpoint(`/record?session_id=${sessionID}&record_id=${currentRecordID}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        aggregatePeptides,
        subtractItemName,
        excludedRecordIDs,
        excludedSpots,
        excludedPeptides,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((data) => {
        setError('');
        setRecord(data);
        dispatch(setRecordData(data));
      })
      .catch((e) => {
        Promise.resolve(e).then((resp: { Reason: string }) => {
          setError(resp.Reason);
        });
      });
  }, [authState, sessionID, currentRecordID, sensogramRenderType, aggregatePeptides, excludedRecordIDs, subtractItemName, excludedPeptides, excludedSpots]);

  useEffect(() => {
    if (!record) return;
    setEligibleAuxiliarySensorTypes(
      Object.entries(AuxiliarySensorType)
        .filter(([_, value]) => typeof value === 'number')
        .filter(([_, value]) => {
          switch (value) {
            case +AuxiliarySensorType.SunriseCo2:
              return record.SunriseCo2Series !== null && sum(record.SunriseCo2Series) !== 0;
            case +AuxiliarySensorType.ZephyrAirflow:
              return record.ZephyrAirflowSeries !== null && sum(record.ZephyrAirflowSeries) !== 0;
            case +AuxiliarySensorType.PidVoc:
              return record.PidVocSeries !== null && sum(record.PidVocSeries) !== 0;
            case +AuxiliarySensorType.Pms1:
              return record.Pms1Series !== null && sum(record.Pms1Series) !== 0;
            case +AuxiliarySensorType.Pms25:
              return record.Pms25Series !== null && sum(record.Pms25Series) !== 0;
            case +AuxiliarySensorType.Pms10:
              return record.Pms10Series !== null && sum(record.Pms10Series) !== 0;
            case +AuxiliarySensorType.BmeVoc:
              return record.BmeVocSeries !== null && sum(record.BmeVocSeries) !== 0;
            case +AuxiliarySensorType.BmeCo2:
              return record.BmeCo2Series !== null && sum(record.BmeCo2Series) !== 0;
            case +AuxiliarySensorType.BmeHumidity:
              return record.BmeHumiditySeries !== null && sum(record.BmeHumiditySeries) !== 0;
            case +AuxiliarySensorType.BmeTemperature:
              return record.BmeTemperatureSeries !== null && sum(record.BmeTemperatureSeries) !== 0;
            case +AuxiliarySensorType.BmeRawPressure:
              return record.BmeRawPressureSeries !== null && sum(record.BmeRawPressureSeries) !== 0;
            case +AuxiliarySensorType.BmeBreathVoc:
              return record.BmeBreathVocSeries !== null && sum(record.BmeBreathVocSeries) !== 0;
            case +AuxiliarySensorType.PidRawVoc:
              return record.PidRawVocSeries !== null && sum(record.PidRawVocSeries) !== 0;
            default:
              return false;
          }
        })
    );
  }, [record]);

  useEffect(() => {
    if (eligibleAuxiliarySensorTypes && eligibleAuxiliarySensorTypes.length !== 0) {
      const [_, value] = eligibleAuxiliarySensorTypes[0];
      setAuxiliarySensorType(+value);
    }
  }, [eligibleAuxiliarySensorTypes]);

  useEffect(() => {
    if (!record || !record.AdditionalSensorsSeries) return;

    const _eligibleAdditionalSensorType: EligibleAditionalSensorType[] = [];
    Object.entries(record.AdditionalSensorsSeries).forEach(([label, values]) => {
      if (values !== null && sum(values) !== 0) {
        const labelSplit = label.split(':');
        _eligibleAdditionalSensorType.push({
          Type: labelSplit[0],
          Name: labelSplit[1],
          Unit: labelSplit[2] ? labelSplit[2] : null,
        });
      }
    });
    if (_eligibleAdditionalSensorType.length >= 0) setEligibleAdditionalSensorTypes(_eligibleAdditionalSensorType);
  }, [record]);

  useEffect(() => {
    if (eligibleAdditionalSensorTypes && eligibleAdditionalSensorTypes.length !== 0) {
      setAdditionalSensorType(eligibleAdditionalSensorTypes[0]);
    }
  }, [eligibleAdditionalSensorTypes]);

  if (error !== '') {
    return (
      <div>
        <Alert type="error" message={error} />
      </div>
    );
  }

  if (records === undefined) return null;
  if (record === null) return null;

  // Need them tobe able to mutate boundaries
  const boundaries = boundariesMap[currentRecordID];
  const analyteStart = boundaries[2];
  const analyteEnd = boundaries[3];

  const eligibleTemperatureTypes = Object.entries(TemperatureSeriesType)
    .filter(([_, value]) => typeof value === 'number')
    .filter(([_, value]) => {
      switch (value) {
        case +TemperatureSeriesType.SensorTemperature:
          return record.TemperatureSeries !== null && sum(record.TemperatureSeries) !== 0;
        case +TemperatureSeriesType.ThermodesorptionTemperature:
          return record.ThermodesorptionTemperatureSeries !== null && sum(record.ThermodesorptionTemperatureSeries) !== 0;
      }
    });

  const mutateBoundaries = (left: number, right: number) => {
    let _boundariesMap = { ...boundariesMap };
    switch (itemLocked) {
      case +BoundariesLockMode.Measure:
        let _boundaries = boundaries.map((e) => e); // Copy and reassign (to make sure the identity of object is different)
        if (zoneSelectorType === +ZoneSelectorType.Baseline) {
          _boundaries[0] = left;
          _boundaries[1] = right;
        } else {
          _boundaries[2] = left;
          _boundaries[3] = right;
        }
        dispatch(
          setBoundaries({
            recordID: record.ID,
            boundaries: _boundaries,
          })
        );
        return;
      case +BoundariesLockMode.Group:
        if (records === undefined) {
          return;
        }
        records.forEach((r) => {
          if (r.ItemName === record.ItemName) {
            let _boundaries = _boundariesMap[r.ID].map((e) => e);
            if (zoneSelectorType === +ZoneSelectorType.Baseline) {
              _boundaries[0] = left;
              _boundaries[1] = right;
            } else {
              _boundaries[2] = left;
              _boundaries[3] = right;
            }
            _boundariesMap[r.ID] = _boundaries;
          }
        });
        dispatch(setBoundariesMap(_boundariesMap));
        return;
    }
  };

  // Have to do it here as we need to decide whether to draw or not the dropdown
  var actualTemperatureSeries: number[] = [];
  switch (temperatureType) {
    case +TemperatureSeriesType.SensorTemperature:
      actualTemperatureSeries = record.TemperatureSeries;
      break;
    case +TemperatureSeriesType.ThermodesorptionTemperature:
      actualTemperatureSeries = record.ThermodesorptionTemperatureSeries;
      break;
  }
  const shouldActuallyDrawTemperature = drawTemperature && !drawAuxiliarySensor && actualTemperatureSeries !== null && sum(actualTemperatureSeries) !== 0;

  return (
    <div
      tabIndex={0}
      onKeyDown={(e) => {
        let n: number;
        switch (e.key) {
          case 'h':
            if (drawHumidity === true) {
              setDrawHumidity(false);
            } else {
              setDrawHumidity(true);
            }
            break;
          case 'H':
            setDrawHumidity(false);
            break;
          case 't':
            if (drawTemperature === true) {
              setDrawTemperature(false);
            } else {
              setDrawTemperature(true);
            }
            break;
          case 'T':
            setDrawTemperature(false);
            break;
          case 's':
            if (drawAuxiliarySensor === true) {
              setDrawAuxiliarySensor(false);
            } else {
              setDrawAuxiliarySensor(true);
            }
            break;
          case 'S':
            setDrawAuxiliarySensor(false);
            break;
          case 'd':
            if (drawAdditionalSensor === true) {
              setDrawAdditionalSensor(false);
            } else {
              setDrawAdditionalSensor(true);
            }
            break;
          case 'ArrowLeft':
            if (e.ctrlKey) {
              n = 1;
            } else {
              n = 5;
            }
            mutateBoundaries(analyteStart - n, analyteEnd - n);
            break;
          case 'ArrowRight':
            if (e.ctrlKey) {
              n = 1;
            } else {
              n = 5;
            }
            mutateBoundaries(analyteStart + n, analyteEnd + n);
            break;
          case 'Shift':
            setZoneSelectorType(ZoneSelectorType.Baseline);
            break;
        }
      }}
      onKeyUp={(e) => {
        switch (e.key) {
          case 'Shift':
            setZoneSelectorType(ZoneSelectorType.Analyte);
            break;
        }
      }}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '-7pt',
          zIndex: 800,
        }}
      >
        <Space
          style={{
            minHeight: '35px', // Fix min height to avoid chart "skipping" on activate/deactivate auxiliary sensors
          }}
        >
          <Popover
            style={{ display: 'flex' }}
            trigger={'hover'}
            content={
              <div>
                <p>
                  Press <Tag>H</Tag>to toggle <b>Humidity</b>
                  <br />
                  {eligibleTemperatureTypes.length !== 0 ? (
                    <>
                      Press <Tag>T</Tag>to toggle <b>Temperature</b>
                      <br />
                    </>
                  ) : null}
                  {eligibleAuxiliarySensorTypes && eligibleAuxiliarySensorTypes.length !== 0 ? (
                    <>
                      Press <Tag>S</Tag>to toggle <b>Auxiliary Sensors (SDOK)</b>
                      <br />
                    </>
                  ) : null}
                  {eligibleAdditionalSensorTypes && eligibleAdditionalSensorTypes.length !== 0 ? (
                    <>
                      Press <Tag>D</Tag>to toggle <b>Additional Sensors</b>
                      <br />
                    </>
                  ) : null}
                  Press{' '}
                  <Tag>
                    <ArrowLeftOutlined />
                  </Tag>
                  or{' '}
                  <Tag>
                    <ArrowRightOutlined />
                  </Tag>
                  to translate the <b>analyte zone</b> (press{' '}
                  {isMacOS() ? (
                    <>
                      <Tag>Ctrl</Tag>+<Tag>⌘</Tag>
                    </>
                  ) : (
                    <>
                      <Tag>Ctrl</Tag>
                    </>
                  )}
                  {''}
                  for precise control)
                  <br />
                  Press and hold <Tag>Shift</Tag> to select the <b>baseline zone</b> instead
                </p>
              </div>
            }
          >
            <FontAwesomeIcon
              icon="info-circle"
              className="clickable-icon"
              style={{
                color: arycolor.aryBlue,
              }}
            />
          </Popover>
          <Popover
            style={{ display: 'flex' }}
            trigger={'hover'}
            content={
              <div>
                <p>
                  {(function () {
                    switch (itemLocked) {
                      case BoundariesLockMode.Measure:
                        return (
                          <span>
                            Selected zone of this replicate will be set <b>independently</b>
                          </span>
                        );
                      case BoundariesLockMode.Group:
                        return (
                          <span>
                            Same selected zone will be applied to all replicates of <b>this item</b>
                          </span>
                        );
                    }
                    return null;
                  })()}
                </p>
              </div>
            }
          >
            {(function () {
              switch (itemLocked) {
                case BoundariesLockMode.Measure:
                  return <FontAwesomeIcon icon="lock-open" className="clickable-icon" style={{ color: '#5a5a5a' }} onClick={() => setItemLocked(BoundariesLockMode.Group)} />;
                case BoundariesLockMode.Group:
                  return <FontAwesomeIcon icon="lock" className="clickable-icon" onClick={() => setItemLocked(BoundariesLockMode.Measure)} />;
              }
              return null;
            })()}
          </Popover>
          <Popover
            style={{ display: 'flex' }}
            trigger={'hover'}
            content={
              <div>
                <p>Click to enable keyboard features!</p>
              </div>
            }
          >
            <FullscreenExitOutlined
              style={{
                cursor: 'pointer',
                fontSize: '14pt',
              }}
            />
          </Popover>
          {drawAuxiliarySensor && eligibleAuxiliarySensorTypes && auxiliarySensorType && eligibleAuxiliarySensorTypes.length !== 0 && (
            <Dropdown
              overlay={
                <Menu
                  items={eligibleAuxiliarySensorTypes.map(([label, value]) => {
                    return {
                      key: +value,
                      label: AuxiliarySensorPrettyNameMap[+value as AuxiliarySensorType].name,
                      onClick: () => {
                        setAuxiliarySensorType(+value);
                      },
                    };
                  })}
                />
              }
            >
              <Tooltip title="Auxiliary sensor">
                <Button style={{ borderRadius: '5px' }}>
                  <Space>
                    {AuxiliarySensorPrettyNameMap[auxiliarySensorType].name}
                    <DownOutlined />
                  </Space>
                </Button>
              </Tooltip>
            </Dropdown>
          )}
          {drawAdditionalSensor && eligibleAdditionalSensorTypes && additionalSensorType && eligibleAdditionalSensorTypes.length !== 0 && (
            <Dropdown
              overlay={
                <Menu
                  items={eligibleAdditionalSensorTypes.map((value) => {
                    return {
                      key: value.Name,
                      label: value.Name[0].toUpperCase() + value.Name.slice(1) + ' (' + value.Type + ')',
                      onClick: () => {
                        setAdditionalSensorType(value);
                      },
                    };
                  })}
                />
              }
            >
              <Tooltip title="Additional sensor">
                <Button style={{ borderRadius: '5px' }}>
                  <Space style={{ textTransform: 'capitalize' }}>
                    {additionalSensorType.Name + ' (' + additionalSensorType.Type + ')'}
                    <DownOutlined />
                  </Space>
                </Button>
              </Tooltip>
            </Dropdown>
          )}
          {shouldActuallyDrawTemperature && eligibleTemperatureTypes.length !== 0 ? (
            <Dropdown
              overlay={
                <Menu
                  items={eligibleTemperatureTypes.map(([label, value]) => {
                    return {
                      key: +value,
                      label: TemperaturePrettyNameMap[+value].name,
                      onClick: () => {
                        setTemperatureType(+value);
                      },
                    };
                  })}
                />
              }
            >
              <Tooltip title="Temperature type">
                <Button style={{ borderRadius: '5px' }}>
                  <Space>
                    {TemperaturePrettyNameMap[temperatureType].name}
                    <DownOutlined />
                  </Space>
                </Button>
              </Tooltip>
            </Dropdown>
          ) : null}
          {zoneSelectorType === ZoneSelectorType.Baseline ? (
            <Popover
              style={{ display: 'flex' }}
              trigger={'hover'}
              content={
                <div>
                  <p>
                    Selection mode: <b>baseline</b>
                  </p>
                </div>
              }
            >
              <div style={{ cursor: 'help', borderRadius: '3px', borderWidth: '1px', borderStyle: 'solid', backgroundColor: '#eee', marginBottom: '4px' }}>BL</div>
            </Popover>
          ) : null}
        </Space>
        <Space>
          <Typography.Title level={5}>{getRecordNameShort(record)}</Typography.Title>
        </Space>
        <Space>
          <ArrowsAltOutlined className="clickable-icon" onClick={() => setIsVisibleModal(true)} />
        </Space>
      </div>
      <SingleSensogramFigure
        record={record}
        sensors={sensors}
        boundaries={boundaries}
        drawHumidity={drawHumidity}
        humidityReferencePosition={humidityReferencePosition}
        drawAuxiliarySensor={drawAuxiliarySensor}
        auxiliarySensorType={auxiliarySensorType}
        drawAdditionalSensor={drawAdditionalSensor}
        additionalSensorType={additionalSensorType}
        drawTemperature={drawTemperature}
        temperatureType={temperatureType}
        cmap={cmap}
        mutateBoundaries={mutateBoundaries}
      />
      <FullscreenGraphicModal title={getRecordNameShort(record)} visible={isVisibleModal} onCancel={() => setIsVisibleModal(false)}>
        <div style={{ width: '100%', height: '100%', margin: '10px' }}>
          <SingleSensogramFigure
            record={record}
            sensors={sensors}
            boundaries={boundaries}
            drawHumidity={drawHumidity}
            humidityReferencePosition={humidityReferencePosition}
            drawAuxiliarySensor={drawAuxiliarySensor}
            auxiliarySensorType={auxiliarySensorType}
            drawAdditionalSensor={drawAdditionalSensor}
            additionalSensorType={additionalSensorType}
            drawTemperature={drawTemperature}
            temperatureType={temperatureType}
            cmap={cmap}
            mutateBoundaries={mutateBoundaries}
            overridePlotlyLayout={{
              ...defaultPlotlyArguments.layout,
              ...{
                showlegend: true,
                legend: { orientation: 'h', tracegroupgap: 5, y: -0.2 },
              },
            }}
          />
        </div>
      </FullscreenGraphicModal>
    </div>
  );
};
