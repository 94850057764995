import { Button, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectChosenTSDB, selectStartPeriod, setChosenTSDB } from '../../features/analysisConfig/runsSlice';
import { AppRoute } from '../../routes';
import { TSDBRef } from '../../types/runsType';
import { UserClaimsWithTSDB } from '../../types/userType';
import { useQueryParam } from '../../utils';

type FilterDatabaseProps = {
  userInfo: UserClaimsWithTSDB | null;
};

const FilterDatabase: React.FC<FilterDatabaseProps> = ({ userInfo }) => {
  const [chosenTSDBRefName, setChosenTSDBRefName] = useQueryParam('tsdb_ref_name', '');
  const [availableTSDBRefs, setAvailableTSDBRefs] = useState<TSDBRef[]>([]);
  const chosenTSDB = useAppSelector(selectChosenTSDB);
  const startPeriod = useAppSelector(selectStartPeriod);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (chosenTSDB) {
      setChosenTSDBRefName(chosenTSDB.Name);
    }
  }, []);

  useEffect(() => {
    if (availableTSDBRefs.length === 0) {
      return;
    }
    for (let i = 0; i < availableTSDBRefs.length; i++) {
      let tsdbRef = availableTSDBRefs[i];
      if (chosenTSDBRefName === tsdbRef.Name) {
        dispatch(setChosenTSDB(tsdbRef));
        return;
      }
    }
    dispatch(setChosenTSDB(undefined));
  }, [chosenTSDBRefName]);

  useEffect(() => {
    if (userInfo !== null) {
      var _tsdbRefs: TSDBRef[] = [];
      userInfo.tsdb_refs.forEach((tsdbRefStr) => {
        let [name, url, token] = tsdbRefStr.split('~');
        let org: string = '';
        let urlOrgRe = /(.*?)\/orgs\/(.*?)$/;
        let m = url.match(urlOrgRe);
        if (m !== null) {
          url = m[1]; // 0 is the full match
          org = m[2];
        }
        if (name && url && token && org) {
          let tsdbRef: TSDBRef = {
            Name: name,
            URL: url,
            Org: org,
            Token: token,
          };
          _tsdbRefs.push(tsdbRef);
        } else {
          console.log('Failed to parse tsdb ref: ', { tsdbRefStr, name, url, org, token });
        }
      });
      _tsdbRefs.sort((a, b) => a.Name.localeCompare(b.Name));
      var _chosenTSDBRef: TSDBRef | null = null;
      if (_tsdbRefs.length === 0) {
        _chosenTSDBRef = {
          Name: userInfo.main_tsdb_name || 'default',
          URL: userInfo.tsdb_url,
          Org: userInfo.tsdb_org,
          Token: userInfo.tsdb_token,
        };
        // Populate the refs with this "default" ref
        _tsdbRefs.push(_chosenTSDBRef);
      } else {
        _tsdbRefs.forEach((tsdbRef) => {
          if (tsdbRef.Name === userInfo.main_tsdb_name) {
            _chosenTSDBRef = tsdbRef;
          }
        });
        // If still tsdb ref not found, take the first one (we already checked if it has more than 0 members)
        if (_chosenTSDBRef === null) {
          _chosenTSDBRef = { ..._tsdbRefs[0] };
        }
      }
      setAvailableTSDBRefs(_tsdbRefs);
      if (_chosenTSDBRef !== null) {
        if (chosenTSDBRefName === '') {
          setChosenTSDBRefName(_chosenTSDBRef.Name);
          dispatch(setChosenTSDB(_chosenTSDBRef));
        } else {
          for (let i = 0; i < _tsdbRefs.length; i++) {
            let tsdbRef = _tsdbRefs[i];
            if (chosenTSDBRefName === tsdbRef.Name) {
              dispatch(setChosenTSDB(tsdbRef));
              return;
            }
          }
          dispatch(setChosenTSDB(undefined));
        }
      } else {
        console.log('Failed to deduce the chosen TSDBRef', { _tsdbRefs, userInfo });
      }
    }
  }, [userInfo]);

  if (chosenTSDB === undefined && availableTSDBRefs.length > 0) {
    dispatch(setChosenTSDB(availableTSDBRefs[0]));
  }

  return (
    <>
      <Select
        style={{ minWidth: '100%', marginBottom: 30 }}
        size="large"
        onChange={(value) => {
          if (typeof value !== 'string') {
          } else {
            setChosenTSDBRefName(value);
          }
        }}
        value={chosenTSDBRefName}
      >
        {availableTSDBRefs.map((tsdbRef) => {
          return (
            <Select.Option key={tsdbRef.Name} value={tsdbRef.Name}>
              {tsdbRef.Name.toUpperCase()}
            </Select.Option>
          );
        })}
      </Select>
      <Link to={`${AppRoute.CLOUD}/?tsdb_ref_name=${chosenTSDB?.Name}&start=${startPeriod}`} style={{ width: '100%' }}>
        <Button type="primary" block style={{ height: 50, borderRadius: 5, marginBottom: 30 }}>
          Go to runs
        </Button>
      </Link>
    </>
  );
};

export default FilterDatabase;
