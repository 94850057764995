import { ArrowsAltOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'antd';
import CardSection from '../../../components/section/CardSection';
import DashboardExperimentPlan from './DashboardExperimentPlan';
import DashboardInfo from './DashboardInfo';
import DashbordMeasures from './DashbordMeasures';
import { SessionType } from '../../../types/sessionType';
import DashbordMeasuresQualityControl from './DashbordMeasuresQualityControl';

type DashboardSelectProps = {
  isExpendedMenu: boolean;
  setIsExpandedMenu: React.Dispatch<React.SetStateAction<boolean>>;
  sessionType: SessionType;
};

const DashboardSelect: React.FC<DashboardSelectProps> = (props) => {
  return (
    <div>
      {props.isExpendedMenu ? (
        <Col id="dashbord-select-expended" style={{ height: 'calc(100% - 142px)', position: 'fixed', width: 350 }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100%' }}>
            <DashboardExperimentPlan />
            {props.sessionType === SessionType.Analysis ? <DashbordMeasures setIsExpandedMenu={props.setIsExpandedMenu} /> : <DashbordMeasuresQualityControl setIsExpandedMenu={props.setIsExpandedMenu} />}
            <DashboardInfo />
          </div>
        </Col>
      ) : (
        <Col id="dashboard-select-collapsed" style={{ height: 'calc(100% - 142px)', position: 'fixed', width: 70 }}>
          <CardSection id="card-dashbord-select" height="100%">
            <div style={{ marginTop: -10 }}>
              <ArrowsAltOutlined className="clickable-icon" onClick={() => props.setIsExpandedMenu(true)} />
            </div>

            <div id="icon" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <FontAwesomeIcon icon="list-ol" onClick={() => props.setIsExpandedMenu(true)} className="clickable-icon" style={{ marginBottom: 20 }} />
              <FontAwesomeIcon icon="cog" onClick={() => props.setIsExpandedMenu(true)} className="clickable-icon" style={{ marginBottom: 20 }} />
              <FontAwesomeIcon icon="info-circle" onClick={() => props.setIsExpandedMenu(true)} className="clickable-icon" style={{ marginBottom: 20 }} />
            </div>
          </CardSection>
        </Col>
      )}
    </div>
  );
};

export default DashboardSelect;
