import { Alert } from 'antd';
import { Layout } from 'plotly.js';
import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { useAppSelector } from '../../../app/hooks';
import { defaultPlotlyArguments } from '../../../compute/utils';
import {
  selectAggregatePeptides,
  selectBoundariesMap,
  selectChemicalCalibrationItemNames,
  selectExcludedPeptides,
  selectExcludedRecordIDs,
  selectExcludedSpots,
  selectHumidityCompensationCalibrantName,
  selectHumidityCompensationPositionOffset,
  selectHumidityCompensationSubstractionGain,
  selectRecords,
  selectSubtractItemName,
} from '../../../features/analysisConfig/analysisConfigSlice';
import { AryRecord, DistanceMatrix } from '../../../types/analysisTypes';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../../utils';

type DistanceMatrixProps = {
  sessionID: string;
};

const DistanceMatrixPanel: React.FC<DistanceMatrixProps> = ({ sessionID }) => {
  const { authState } = useOktaOrQueryAuth();

  const [error, setError] = useState<string | undefined>();
  const [distanceMatrix, setDistanceMatrix] = useState<DistanceMatrix>();

  const boundariesMap = useAppSelector<Record<number, number[]>>(selectBoundariesMap);
  const excludedRecordIDs = useAppSelector<number[]>(selectExcludedRecordIDs);
  const subtractItemName = useAppSelector<string>(selectSubtractItemName);
  const aggregatePeptides = useAppSelector<boolean>(selectAggregatePeptides);
  const records = useAppSelector<AryRecord[] | undefined>(selectRecords);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const excludedPeptides = useAppSelector(selectExcludedPeptides);

  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);
  const humidityCalibrationPositionOffset = useAppSelector(selectHumidityCompensationPositionOffset);
  const humidityCalibrationSubstractionGain = useAppSelector(selectHumidityCompensationSubstractionGain);

  const chemicalCalibrationItemNames = useAppSelector(selectChemicalCalibrationItemNames);

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    if (!records || records.length === 0) {
      setError('No distance matrix because no records');
      return;
    } else setError(undefined);

    fetchAuthorizedAPIEndpoint(`/compute/distances?session_id=${sessionID}&metric=euclidean`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        boundariesMap,
        excludedRecordIDs,
        aggregatePeptides,
        subtractItemName,
        excludedPeptides,
        excludedSpots,
        humidityCompensation: {
          calibrantName: humidityCalibrationCalibrantName,
          positionOffset: humidityCalibrationPositionOffset,
          SubstractionGain: humidityCalibrationSubstractionGain,
        },
        chemicalCalibrationItemNames,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((distanceMatrix: DistanceMatrix) => {
        for (let i = 0; i < distanceMatrix.GroupMean.length; i++) {
          for (let j = 0; j < distanceMatrix.GroupMean[0].length; j++) {
            distanceMatrix.GroupMean[i][j] *= 100;
            distanceMatrix.GroupStd[i][j] *= 100;
          }
        }
        setDistanceMatrix(distanceMatrix);
      })
      .catch((e) => {
        Promise.resolve(e).then((resp: { message: string }) => {
          setError(resp.message);
        });
      });
  }, [
    authState,
    records,
    aggregatePeptides,
    boundariesMap,
    excludedRecordIDs,
    sessionID,
    subtractItemName,
    excludedPeptides,
    excludedSpots,
    humidityCalibrationCalibrantName,
    humidityCalibrationPositionOffset,
    humidityCalibrationSubstractionGain,
    chemicalCalibrationItemNames,
  ]);

  if (error !== undefined || !distanceMatrix) {
    return <Alert type="error" message={error} style={{ borderRadius: 5, lineHeight: 1, marginTop: 20 }} />;
  }

  const hoverText: string[] = [];
  distanceMatrix.GroupLabels.forEach((_, i) => {
    return distanceMatrix.GroupLabels.forEach((_, j) => {
      hoverText.push(`${distanceMatrix.GroupMean[i][j].toFixed(1)}±${distanceMatrix.GroupStd[i][j].toFixed(1)}`);
    });
  });

  var data: Plotly.Data[] = [
    {
      type: 'heatmap',
      name: '',
      x: distanceMatrix.GroupLabels,
      y: distanceMatrix.GroupLabels,
      z: distanceMatrix.GroupMean,
      text: hoverText,
      hovertemplate: '%{x}<br>%{y}<br>%{z:.1f}<extra></extra>',
      colorscale: 'RdBu',
      showscale: false,
    },
  ];

  var layout: Partial<Layout> = {
    annotations: [],
    xaxis: {
      ticks: '',
      side: 'top',
      automargin: true,
      type: 'category',
      range: [-0.5, distanceMatrix.GroupLabels.length - 0.5],
    },
    yaxis: {
      ticks: '',
      ticksuffix: ' ',
      automargin: true,
      type: 'category',
      range: [distanceMatrix.GroupLabels.length - 0.5, -0.5],
    },
  };

  if (distanceMatrix && distanceMatrix.GroupMean.length < 15) {
    layout.annotations = [];
    for (let i = 0; i < distanceMatrix.GroupLabels.length; i++) {
      for (let j = 0; j < distanceMatrix.GroupLabels.length; j++) {
        let textColor = 'white';

        let annotation = {
          x: distanceMatrix.GroupLabels[j],
          y: distanceMatrix.GroupLabels[i],
          text: `${distanceMatrix.GroupMean[i][j].toFixed(0)}`,
          font: {
            family: 'Arial',
            size: 12,
            color: textColor,
          },
          showarrow: false,
        };
        layout.annotations.push(annotation);
      }
    }
  }

  return (
    <div tabIndex={0} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
      <>
        <Plot
          divId="distance_matrix_scatter_plot"
          debug={true}
          data={data}
          layout={{
            ...defaultPlotlyArguments.layout,
            ...layout,
          }}
          useResizeHandler={true}
          config={defaultPlotlyArguments.config}
          style={defaultPlotlyArguments.style}
        />
      </>
    </div>
  );
};

export default DistanceMatrixPanel;
