import { CSSProperties, Key, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE, nonStandardPeptidesColor, peptideColorPaletteVdW } from '../../../../compute/colormap';
import { ASCII_UPPERCASE } from '../../../../compute/utils';
import { selectExcludedSpots, setExcludedSpots } from '../../../../features/analysisConfig/analysisConfigSlice';
import { PeptideSet } from '../../../../types/analysisTypes';
import { useOktaAuth } from '@okta/okta-react';
import { FeatureFlag, UserClaimsWithTSDB } from '../../../../types/userType';

type SpotsSelectorGridProps = {
  style: { [K in Key]: CSSProperties };
  spots: { X: number[]; Y: number[]; Z: string[][] } | undefined;
  peptidesSetType: PeptideSet;
};

const SpotsSelectorGrid: React.FC<SpotsSelectorGridProps> = ({ style, spots, peptidesSetType }) => {
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const dispatch = useAppDispatch();
  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState, oktaAuth]);

  const createStyle = (x: number, y: number, spot: string): CSSProperties => {
    if (spot === '') return style.peptidesBroken;
    const coordinates = ASCII_UPPERCASE[x] + y;
    if (excludedSpots.findIndex((el) => el === coordinates) !== -1) return style.diselectedPeptides;

    if (peptidesSetType !== PeptideSet.NonStandard) {
      let color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE;
      if (peptidesSetType === PeptideSet.POR1 || peptidesSetType === PeptideSet.POR2) {
        color = peptideColorPaletteVdW[parseInt(spot)];
        if (!color) color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE;
      }

      return { backgroundColor: color };
    }

    if (peptidesSetType === PeptideSet.NonStandard) {
      let color = nonStandardPeptidesColor[Number(spot) % nonStandardPeptidesColor.length];
      return { backgroundColor: color };
    }
    return { backgroundColor: DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE };
  };

  const excludeSpots = (x: number, y: number, spot: String) => {
    if (userInfo === null || userInfo.feature_flags.includes(FeatureFlag.AALightViewQualityControl)) return;

    if (spot !== '') {
      const coordinates = ASCII_UPPERCASE[x] + y;
      const spotIndex = excludedSpots.findIndex((el) => el === coordinates);

      if (spotIndex === -1) dispatch(setExcludedSpots([...excludedSpots, coordinates]));
      else {
        const newEcludedSpots = excludedSpots.filter((_el, index) => index !== spotIndex);
        dispatch(setExcludedSpots(newEcludedSpots));
      }
    }
  };

  return (
    <table style={{ borderCollapse: 'separate' }}>
      <thead>
        <tr>
          <th key={null} style={{ ...style.base, ...style.th }}></th>
          {spots?.X.map((el) => (
            <th style={{ ...style.base, ...style.th }} key={el}>
              {el}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {spots?.Z.map((data, index) => (
          <tr key={index}>
            <th style={{ ...style.base, ...style.th }}>{ASCII_UPPERCASE[index]}</th>
            {data.map((el, a) => (
              <td style={{ ...style.base, ...style.td, ...createStyle(index, a, el) }} key={a} onClick={() => excludeSpots(index, a, el)}>
                {el}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SpotsSelectorGrid;
