import { ArrowsAltOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, Popover, Row, Space, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { NormType, SignatureFigureType } from '../../../types/analysisTypes';
import FullscreenGraphicModal from '../FullscreenGraphicModal';
import { EncodedSignatureLink } from './EncodedSignatureLink';
import { SingleSignatureFigure } from './SingleSignatureFigure';
import { SignaturePie } from './SignaturePie';
import { useAppSelector } from '../../../app/hooks';
import { selectChemicalCalibrationItemNames, selectHumidityCompensationCalibrantName, selectSessionID } from '../../../features/analysisConfig/analysisConfigSlice';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { WithFeatureFlagsCheck } from '../../../with_feature_flags_check';
import { useOktaAuth } from '@okta/okta-react';
import { FeatureFlag, UserClaimsWithTSDB } from '../../../types/userType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SingleSignatureWidget: React.FC = () => {
  const sessionID = useAppSelector(selectSessionID);
  const [figureType, setFigureType] = useState<SignatureFigureType>(+SignatureFigureType.Rose);
  const [normType, setNormType] = useState<NormType>(+NormType.Normalized);
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);

  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);

  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);
  const chemicalCalibrationItemNames = useAppSelector(selectChemicalCalibrationItemNames);

  const renderMenuItemsWithFeatureFlags = () => {
    if (userInfo === null) {
      return;
    }
    var items: ItemType[] = [];
    Object.entries(SignatureFigureType)
      .filter(([_, value]) => typeof value === 'number')
      .filter(([_, value]) => {
        if (value === SignatureFigureType.Chemistry) {
          if (userInfo.feature_flags.includes(FeatureFlag.RoleAryballe) || userInfo.feature_flags.includes(FeatureFlag.AAChemicalFamiliesAccessEnabled)) {
            return true;
          }
          return false;
        }
        if (value === SignatureFigureType.Spatial) {
          if (userInfo.feature_flags.includes(FeatureFlag.RoleAryballe) || userInfo.feature_flags.includes(FeatureFlag.AANonAggregatedPeptidesAccessEnabled)) {
            return true;
          }
          return false;
        }
        return true;
      })
      .forEach(([label, value]) => {
        switch (value) {
          case SignatureFigureType.Chemistry:
            if (userInfo.feature_flags.includes(FeatureFlag.RoleAryballe) || userInfo.feature_flags.includes(FeatureFlag.AAChemicalFamiliesAccessEnabled)) {
              items.push({
                key: +value,
                label: <WithFeatureFlagsCheck ff={FeatureFlag.AAChemicalFamiliesAccessEnabled}>{<>{label}</>}</WithFeatureFlagsCheck>, //label,
                onClick: () => {
                  setFigureType(+value);
                },
              });
            }
            break;
          case SignatureFigureType.Spatial:
            if (userInfo.feature_flags.includes(FeatureFlag.RoleAryballe) || userInfo.feature_flags.includes(FeatureFlag.AANonAggregatedPeptidesAccessEnabled)) {
              items.push({
                key: +value,
                label: <WithFeatureFlagsCheck ff={FeatureFlag.AANonAggregatedPeptidesAccessEnabled}>{<>{label}</>}</WithFeatureFlagsCheck>, //label,
                onClick: () => {
                  setFigureType(+value);
                },
              });
            }
            break;
          default:
            items.push({
              key: +value,
              label: label,
              onClick: () => {
                setFigureType(+value);
              },
            });
        }
      });
    return items;
  };

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState, oktaAuth]);

  if (userInfo === null) {
    return null;
  }

  return (
    <div style={{ width: '100%', height: '40vh', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
      <Row justify="space-between" align="middle">
        <Col></Col>
        <Col>
          <Space align="center" style={{ display: 'flex', justifyContent: 'center' }}>
            <Dropdown
              overlay={
                <Menu
                  items={Object.entries(NormType)
                    .filter(([_, value]) => typeof value === 'number')
                    .map(([label, value]) => {
                      return {
                        key: +value,
                        label: label,
                        onClick: () => {
                          setNormType(+value);
                        },
                      };
                    })}
                />
              }
            >
              <Tooltip title="Normalization">
                <Button style={{ borderRadius: '5px' }}>
                  <Space>
                    {NormType[normType]}
                    <DownOutlined />
                  </Space>
                </Button>
              </Tooltip>
            </Dropdown>
            <Dropdown overlay={<Menu items={renderMenuItemsWithFeatureFlags()} />}>
              <Tooltip title="Chart type">
                <Button style={{ borderRadius: '5px' }}>
                  <Space>
                    {SignatureFigureType[figureType]}
                    <DownOutlined />
                  </Space>
                </Button>
              </Tooltip>
            </Dropdown>
            <EncodedSignatureLink />
          </Space>
        </Col>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          {chemicalCalibrationItemNames && chemicalCalibrationItemNames.length > 0 && normType === NormType.Normalized && (
            <Popover style={{ display: 'flex' }} trigger={'hover'} content="Chemical calibration is active">
              <FontAwesomeIcon icon="balance-scale" style={{ marginRight: 10, fontSize: '13pt' }} />
            </Popover>
          )}
          {humidityCalibrationCalibrantName && (
            <Popover style={{ display: 'flex' }} trigger={'hover'} content="Humidity correction is active">
              <FontAwesomeIcon icon="droplet" style={{ marginRight: 10, fontSize: '13pt' }} />
            </Popover>
          )}
          <ArrowsAltOutlined className="clickable-icon" onClick={() => setIsVisibleModal(true)} />
        </Col>
      </Row>
      <>
        {(function () {
          switch (figureType) {
            case +SignatureFigureType.Chemistry:
              return <SignaturePie sessionID={sessionID} />;
            default:
              return <SingleSignatureFigure figureType={figureType} normType={normType} />;
          }
        })()}
      </>
      <FullscreenGraphicModal title="Signature" visible={isVisibleModal} onCancel={() => setIsVisibleModal(false)}>
        {(function () {
          switch (figureType) {
            case +SignatureFigureType.Chemistry:
              return <SignaturePie sessionID={sessionID} />;
            default:
              return <SingleSignatureFigure figureType={figureType} normType={normType} />;
          }
        })()}
      </FullscreenGraphicModal>
    </div>
  );
};
