import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOktaAuth } from '@okta/okta-react';
import { Button, Col, message, Row } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import { Children, cloneElement, CSSProperties, Key, ReactElement, ReactNode, useEffect, useState } from 'react';
import { useMediaQuery } from '../App';
import { arycolor } from '../assets/css/color';
import { FeatureFlag, UserClaimsWithTSDB } from '../types/userType';
import { fetchAuthorizedAPIEndpoint } from '../utils';

type TrialPageProps = {};
type FeatureAppProps = {
  title: string;
  children: ReactNode;
  keyTab: string;
  activeKey: string | undefined;
  setActiveKey: React.Dispatch<React.SetStateAction<string | undefined>>;
};

type typeStyle = {
  [K in Key]: CSSProperties;
};

export const FeatureApp: React.FC<FeatureAppProps> = ({ title, children, keyTab, setActiveKey, activeKey }) => {
  return (
    <div style={{ marginBottom: 50 }}>
      <Row align="middle" justify="space-between" key={keyTab}>
        <Col span={2}>
          <span style={{ width: 20, height: 20, background: 'white', borderRadius: '50%', display: 'block', cursor: 'pointer' }} onClick={() => setActiveKey(activeKey && activeKey === keyTab ? undefined : keyTab)} />
        </Col>
        <Col span={20}>
          <p style={{ margin: 0, color: 'white', fontSize: 20, fontWeight: 500, marginBottom: 0 }}>{title}</p>
        </Col>
        <Col span={2}>
          {activeKey && activeKey === keyTab ? (
            <FontAwesomeIcon icon="minus" style={{ color: arycolor.aryPink, width: 30, height: 30, cursor: 'pointer' }} onClick={() => setActiveKey(undefined)} />
          ) : (
            <FontAwesomeIcon icon="plus" style={{ color: arycolor.aryPink, width: 30, height: 30, cursor: 'pointer' }} onClick={() => setActiveKey(keyTab)} />
          )}
        </Col>
      </Row>
      {activeKey && activeKey === keyTab && (
        <Row>
          <Col span={2}></Col>
          <Col span={20} style={{ marginTop: 10 }}>
            <div style={{ color: 'white', fontSize: 16 }}>{Children.map(children, (child) => cloneElement(child as ReactElement<FeatureAppProps>))}</div>
          </Col>
          <Col span={2}></Col>
        </Row>
      )}
    </div>
  );
};

const TrialPage: React.FC<TrialPageProps> = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);
  const [activeKey, setActiveKey] = useState<string | undefined>('1');
  const isRowBased = useMediaQuery('(max-width: 768px)');

  const [licenseEndTs, setLicenseEndTs] = useState<number | null | undefined>(undefined);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    fetchAuthorizedAPIEndpoint('/user/get_trial_period', authState)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((data: { LicenseEndTs: number }) => {
        setLicenseEndTs(data.LicenseEndTs);
      });
  }, [authState, oktaAuth]);

  const startTrialPeriod = () => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    fetchAuthorizedAPIEndpoint('/user/set_trial_period', authState)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((data: { LicenseEndTs: number }) => {
        setLicenseEndTs(data.LicenseEndTs);
        message.success('Trial period activated!');
      })
      .catch((e) => {
        message.error(e);
      });
  };

  const userIsSubscribed = () => {
    if (licenseEndTs === null || licenseEndTs === undefined) {
      return false;
    }
    return licenseEndTs - Date.now() > 0;
  };

  const style: typeStyle = {
    bannerTrial: { minHeight: '75vh', background: 'url(./fondtrial.jpg) no-repeat #070623 10%', paddingBottom: 70 },
    trialContainer: { color: 'white', paddingLeft: '10vw', paddingRight: isRowBased ? '10vw' : 0 },
  };

  return (
    <>
      <Row style={{ paddingLeft: '10vw', paddingTop: '5vh', background: arycolor.aryBlueDark }}>
        {isRowBased ? (
          <img style={{ width: 60, maxWidth: '100%', maxHeight: '100%', height: 'auto' }} src="/logo64.png" alt="" />
        ) : (
          <img style={{ width: 270, maxWidth: '100%', maxHeight: '100%', height: 'auto' }} src="./aryballe-tm-white-transparent.png" alt="" />
        )}
      </Row>
      <Row style={style.bannerTrial} justify="space-between" align="middle">
        <Col span={24} md={12} style={style.trialContainer}>
          <h1 style={{ fontWeight: 'bold', fontSize: '2.813rem', color: 'white' }}>Digital Olfaction Hub</h1>
          <p style={{ fontSize: 18 }}>The gateway to your olfaction data </p>
          <Row style={{ marginTop: 30 }} gutter={[20, 20]}>
            <Col>
              {(function () {
                if (userInfo === null) {
                  return (
                    <Button type="primary" style={{ paddingLeft: 30, paddingRight: 30, borderRadius: '50px', fontSize: 18, fontWeight: 'bold', minHeight: 50 }}>
                      <a href="/">SIGN IN</a>
                    </Button>
                  );
                } else if (userInfo?.feature_flags.includes(FeatureFlag.RoleAryballe)) {
                  return (
                    <Button type="primary" style={{ paddingLeft: 30, paddingRight: 30, borderRadius: '50px', fontSize: 18, fontWeight: 'bold', minHeight: 50 }}>
                      <a href="/">GO TO DASHBOARD</a>
                    </Button>
                  );
                } else if (!userIsSubscribed()) {
                  return (
                    <Button type="primary" style={{ paddingLeft: 30, paddingRight: 30, borderRadius: '50px', fontSize: 18, fontWeight: 'bold', minHeight: 50 }} onClick={startTrialPeriod}>
                      START TRIAL
                    </Button>
                  );
                } else {
                  return (
                    <Button type="primary" style={{ paddingLeft: 30, paddingRight: 30, borderRadius: '50px', fontSize: 18, fontWeight: 'bold', minHeight: 50 }}>
                      <a href="/">GO TO DASHBOARD</a>
                    </Button>
                  );
                }
              })()}
            </Col>
            <Col>
              <Button
                type={userInfo?.saas_access === undefined ? 'default' : 'primary'}
                style={{ paddingLeft: 30, paddingRight: 30, borderRadius: '50px', fontSize: 18, fontWeight: 'bold', minHeight: 50, color: 'white', background: 'transparent', border: '2px solid white', marginBottom: 50 }}
              >
                <a href="https://aryballe.com/contact-us/" target="_blank" rel="noreferrer">
                  SUBSCRIBE
                </a>
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24} md={11}>
          <Row justify="end">
            <img src="./draw_trial.svg" alt="" style={{ maxWidth: isRowBased ? '90%' : '100%', maxHeight: '100%' }} />
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: 50 }} align="middle">
        <Col span={24} lg={12} style={{ background: '#26263C', padding: 50, marginBottom: 50 }}>
          <h1 style={{ fontSize: 36, color: arycolor.aryCyan }}>Digital olfaction features</h1>
          <span style={{ height: 5, width: 77, background: arycolor.aryCyan, display: 'inline-block', marginBottom: 50 }} />

          <FeatureApp keyTab="1" title="All your data in one place, available from anywhere" setActiveKey={setActiveKey} activeKey={activeKey}>
            <>
              <p>• Centralized access to all your team’s olfaction data</p>
              <p>• Cloud-based analytics platform enables remote management of your NeOse fleet</p>
              <p>• Combine measurements across devices, experiments and sources for analysis</p>
            </>
          </FeatureApp>

          <FeatureApp keyTab="2" title="Collaborate with colleagues" setActiveKey={setActiveKey} activeKey={activeKey}>
            <>
              <p>• Convenient access to your team’s data</p>
              <p>• Create, edit and share analysis sessions with your teammates</p>
            </>
          </FeatureApp>

          <FeatureApp keyTab="3" title="Robust set of digital olfaction data and tools" setActiveKey={setActiveKey} activeKey={activeKey}>
            <>
              <p>• Unlock olfaction expertise in data analysis with dynamic tools</p>
              <p>• Extract olfaction signatures and compensate for unwanted background</p>
              <p>• Navigate chemical and odor spaces</p>
            </>
          </FeatureApp>

          <FeatureApp keyTab="4" title="Beyond the hub" setActiveKey={setActiveKey} activeKey={activeKey}>
            <>
              <p>• Export raw data and results for further analysis in your own tools</p>
              <p>• Create, edit and export professional reports</p>
            </>
          </FeatureApp>
        </Col>
        <Col span={0} lg={1}></Col>
        <Col span={24} lg={11} style={{ marginBottom: 50 }}>
          <Row justify="center">
            <img src={`./img_feature_${activeKey}.png`} alt="" style={{ background: '#26263C', padding: 20, borderRadius: 10, maxWidth: '100%', maxHeight: '100%' }} />
          </Row>
        </Col>
      </Row>

      <Footer style={{ background: arycolor.aryBlueDark, fontSize: 12, textAlign: 'center', color: 'white', minHeight: 50, padding: '18px 50px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <div>2022 © Aryballe Technologies</div>
          {userInfo !== null && (
            <Button
              type="link"
              onClick={() => {
                return oktaAuth.signOut();
              }}
            >
              Logout
            </Button>
          )}
        </div>
      </Footer>
    </>
  );
};

export default TrialPage;
