import { Col, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Caption } from '../../components/summary/caption';
import { RunSummaryTable } from '../../components/summary/runs_summary_table';
import { selectColormap, selectSessionID } from '../../features/analysisConfig/analysisConfigSlice';
import { selectStudyTitle, selectSummaryRuns, setStudyTitle, setSummaryRuns } from '../../features/analysisConfig/pdfSlice';
import { RunMetadata } from '../../types/runsType';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../utils';

type SummaryRunsPdfProps = {};

const SummaryRunsPdf: React.FC<SummaryRunsPdfProps> = () => {
  const { authState } = useOktaOrQueryAuth();
  const dispatch = useAppDispatch();
  const [runs, setRuns] = useState<RunMetadata[]>([]);
  const sessionID = useAppSelector(selectSessionID);
  const studyTitle = useAppSelector(selectStudyTitle);
  const cmap = useAppSelector(selectColormap);
  const summaryRuns = useAppSelector(selectSummaryRuns);

  // Load runs
  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    if (sessionID !== '') {
      fetchAuthorizedAPIEndpoint(`/runs?session_id=${sessionID}`, authState)
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            throw new Error();
          }
        })
        .then((runs: RunMetadata[]) => {
          if (runs != null) {
            setRuns(runs);
            if (!studyTitle) dispatch(setStudyTitle(`Report on the study #${sessionID} - ${runs.map((run) => run.ID).join(', ')}`));
          }
        });
    }
  }, [authState, sessionID]);

  return (
    <Col span={24} id="summaryRunPdf">
      <Row justify="start">
        <Col>
          <Typography.Title level={4}>2.1. Summary of acquisition runs</Typography.Title>
        </Col>
      </Row>
      <Row justify="start">
        <Col xs={24}>
          <RunSummaryTable runs={runs} cmap={cmap} drawFooter={true} />
        </Col>
      </Row>
      <Caption title="Table 1" subtitle="Summary of acquisition runs" setObservations={setSummaryRuns} observations={summaryRuns} sessionID={sessionID} authState={authState} name="summaryRuns" />
    </Col>
  );
};

export default SummaryRunsPdf;
