export const arycolor = {
  aryBlue: '#1890FF',
  aryPurple: '#7451F1',
  aryPink: '#FD4282',
  aryYellow: '#FFC500',
  aryCyan: '#66E5DC',

  aryGreyDark: '#08213F',
  aryBlueDark: '#070623',
  aryBlueDarkTransparent: '#07062385',
  aryGrey: '#F1F2F5',
};
