import { Alert } from 'antd';
import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { useAppSelector } from '../../../app/hooks';
import { defaultColorPalette, colorToTransparent, peptideColorPaletteVdW, DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE, nonStandardPeptidesColor } from '../../../compute/colormap';
import { ASCII_UPPERCASE, closeArray, defaultPlotlyArguments, getPeptideSetType, spots2peptides } from '../../../compute/utils';
import {
  selectAggregatePeptides,
  selectBoundariesMap,
  selectChemicalCalibrationItemNames,
  selectColormap,
  selectCurrentRecordID,
  selectExcludedPeptides,
  selectExcludedRecordIDs,
  selectExcludedSpots,
  selectHumidityCompensationCalibrantName,
  selectHumidityCompensationPositionOffset,
  selectHumidityCompensationSubstractionGain,
  selectRecords,
  selectSessionID,
  selectSubtractItemName,
} from '../../../features/analysisConfig/analysisConfigSlice';
import { AryRecord, NormType, PeptideSet, Signature, SignatureFigureType } from '../../../types/analysisTypes';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../../utils';

export const SingleSignatureFigure: React.FC<{ figureType: SignatureFigureType; normType: NormType }> = (props) => {
  const { figureType, normType } = props;

  const { authState } = useOktaOrQueryAuth();

  const sessionID = useAppSelector(selectSessionID);
  const currentRecordID = useAppSelector(selectCurrentRecordID);
  const records = useAppSelector(selectRecords);
  const cmap = useAppSelector(selectColormap);
  const aggregatePeptides = useAppSelector(selectAggregatePeptides);
  const boundariesMap = useAppSelector(selectBoundariesMap);
  const subtractItemName = useAppSelector(selectSubtractItemName);
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const excludedPeptides = useAppSelector(selectExcludedPeptides);

  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);
  const humidityCalibrationPositionOffset = useAppSelector(selectHumidityCompensationPositionOffset);
  const humidityCalibrationSubstractionGain = useAppSelector(selectHumidityCompensationSubstractionGain);

  const chemicalCalibrationItemNames = useAppSelector(selectChemicalCalibrationItemNames);

  const [signature, setSignature] = useState<Signature | null>(null);
  const [record, setRecord] = useState<AryRecord | undefined>();
  const [error, setError] = useState('');
  const [peptidesSetType, setPeptidesSetType] = useState<PeptideSet>(PeptideSet.Unknown);

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    setRecord(records?.find((record: AryRecord) => record.ID === currentRecordID));
    var boundaries = boundariesMap[currentRecordID];
    if (boundaries === undefined) {
      return;
    }
    fetchAuthorizedAPIEndpoint(`/compute/signature?session_id=${sessionID}&record_id=${currentRecordID}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        aggregatePeptides,
        boundariesMap,
        subtractItemName,
        excludedRecordIDs,
        excludedSpots,
        excludedPeptides,
        humidityCompensation: {
          calibrantName: humidityCalibrationCalibrantName,
          positionOffset: humidityCalibrationPositionOffset,
          SubstractionGain: humidityCalibrationSubstractionGain,
        },
        chemicalCalibrationItemNames,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((signature: Signature) => {
        if (!signature.NormalizedSignature) {
          setError('null signature received');
          return;
        }
        setError('');
        setSignature(signature);
      })
      .catch((e) => {
        Promise.resolve(e).then((resp: { Reason: string }) => {
          setError(resp.Reason);
        });
      });
  }, [
    authState,
    sessionID,
    currentRecordID,
    boundariesMap,
    aggregatePeptides,
    subtractItemName,
    excludedRecordIDs,
    excludedPeptides,
    excludedSpots,
    humidityCalibrationCalibrantName,
    humidityCalibrationPositionOffset,
    humidityCalibrationSubstractionGain,
    chemicalCalibrationItemNames,
  ]);

  useEffect(() => {
    if (record) {
      const spotgrid = [...record.Sensors.map((s) => parseInt(s))]; // parseInt tranform peptides with coordonate to number ex: 134[A3] = 134
      setPeptidesSetType(getPeptideSetType(spotgrid, true));
    } else setPeptidesSetType(PeptideSet.Unknown);
  }, [record]);

  if (error !== '') {
    return (
      <div>
        <Alert type="error" message={error} />
      </div>
    );
  }

  if (signature === null || signature === undefined || cmap === undefined || record === undefined) {
    return null;
  }

  if (record.ItemName === humidityCalibrationCalibrantName) {
    return (
      <Alert
        style={{ marginTop: '10px' }}
        type="info"
        message={
          <>
            This item is used as <b>humidity calibrant</b> and its signature is not available
          </>
        }
      ></Alert>
    );
  }

  var signatureVector: number[] = signature.RawSignature;
  switch (normType) {
    case +NormType.Normalized:
      signatureVector = signature.NormalizedSignature.map((e) => e * 100);
      break;
  }

  var plotlyData: Plotly.Data[] = [];
  var plotlyLayout: Partial<Plotly.Layout> = { ...defaultPlotlyArguments.layout };

  switch (figureType) {
    case +SignatureFigureType.Rose:
      plotlyData = [
        {
          type: 'barpolar',
          theta: signature.Peptides.map((p) => '&nbsp;' + p), // A hack so that plotly treats peptide codes as categories (not degrees)
          r: signatureVector,
          fill: 'toself',
          hoverinfo: 'y+x',
          hoveron: 'points',
          marker: {
            color: (function () {
              var peptides = signature.Peptides;
              if (!aggregatePeptides) {
                try {
                  peptides = spots2peptides(signature.Peptides)[0];
                } catch (e) {
                  console.log(e);
                }
              }
              return peptides.map((p) => {
                let color: string = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE;
                if (peptidesSetType === PeptideSet.POR1 || peptidesSetType === PeptideSet.POR2) color = peptideColorPaletteVdW[Number(p)];
                else if (peptidesSetType === PeptideSet.NonStandard) color = nonStandardPeptidesColor[Number(p) % nonStandardPeptidesColor.length];

                if (color === undefined) color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE;

                return color;
              });
            })(),
          },
          fillcolor: colorToTransparent(defaultColorPalette[cmap[record.ItemName]], 40),
        },
      ];

      if (normType === +NormType.Normalized) {
        const range = [Math.min(...signatureVector), Math.max(...signatureVector)];
        if (plotlyLayout.polar !== undefined) {
          if (plotlyLayout.polar.radialaxis !== undefined) {
            plotlyLayout.polar.radialaxis.range = range;
          } else {
            plotlyLayout.polar.radialaxis = {
              range: range,
            };
          }
        } else {
          plotlyLayout.polar = {
            radialaxis: {
              range: range,
            },
          };
        }
      } else {
        plotlyLayout.polar = { radialaxis: { fixedrange: true } };
      }
      break;
    case +SignatureFigureType.Radar:
      plotlyData = [
        {
          type: 'scatterpolar',
          theta: closeArray(signature.Peptides.map((p) => '&nbsp;' + p)), // A hack so that plotly treats peptide codes as categories (not degrees)
          r: closeArray(signatureVector),
          fill: 'toself',
          hoverinfo: 'y+x',
          hoveron: 'points',
          line: {
            color: defaultColorPalette[cmap[record.ItemName]],
          },
          fillcolor: colorToTransparent(defaultColorPalette[cmap[record.ItemName]], 40),
        },
      ];

      if (normType === +NormType.Normalized) {
        const range = [Math.min(...signatureVector), Math.max(...signatureVector)];
        if (plotlyLayout.polar !== undefined) {
          if (plotlyLayout.polar.radialaxis !== undefined) {
            plotlyLayout.polar.radialaxis.range = range;
          } else {
            plotlyLayout.polar.radialaxis = {
              range: range,
            };
          }
        } else {
          plotlyLayout.polar = {
            radialaxis: {
              range: range,
            },
          };
        }
      } else {
        plotlyLayout.polar = { radialaxis: { fixedrange: true } };
      }
      break;
    case +SignatureFigureType.Spatial:
      try {
        let [peptideCodeStrs, peptideCoordinateInts] = spots2peptides(signature.Peptides);
        let rowNb = Math.max(...peptideCoordinateInts.map((e) => e[0]));
        let colNb = Math.max(...peptideCoordinateInts.map((e) => e[1]));

        let X: number[] = [];
        let Y: number[] = [];
        let Z: number[][] = [];

        for (let colIdx = 0; colIdx < colNb + 1; colIdx++) {
          X.push(colIdx);
        }
        for (let rowIdx = 0; rowIdx < rowNb + 1; rowIdx++) {
          Y.push(rowIdx);
        }

        for (let rowIdx = 0; rowIdx < rowNb + 1; rowIdx++) {
          let row: number[] = [];
          for (let colIdx = 0; colIdx < colNb + 1; colIdx++) {
            row.push(NaN);
          }
          Z.push(row);
        }
        peptideCoordinateInts.forEach(([row, col], i) => {
          Z[row][col] = signatureVector[i];
        });
        plotlyData = [
          {
            type: 'heatmap',
            x: X.map((v) => `&nbsp;${v}`),
            y: Y.map((v) => `&nbsp;${ASCII_UPPERCASE[v]}`),
            z: Z,
            hoverinfo: 'y+x+z',
            hoveron: 'points',
            colorscale: 'Cividis',
            showscale: false,
          },
        ];
        plotlyLayout.yaxis = {
          autorange: 'reversed',
        };
        plotlyLayout.annotations = peptideCoordinateInts.map(([row, col], i) => {
          return {
            x: col,
            y: row,
            text: peptideCodeStrs[i],
            showarrow: false,
            font: {
              color: 'white',
              family: 'century gothic',
            },
          };
        });
      } catch (e) {
        console.log(e);
        return null;
      }
      break;
    case +SignatureFigureType.Bar:
      plotlyData = [
        {
          type: 'bar',
          x: signature.Peptides.map((p) => '&nbsp;' + p), // A hack so that plotly treats peptide codes as categories (not degrees)
          y: signatureVector,
          marker: {
            color: signature.Peptides.map((peptideFullStr) => {
              let peptideCodeInt = parseInt(peptideFullStr);
              let color: string = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE;
              if (peptidesSetType === PeptideSet.POR1 || peptidesSetType === PeptideSet.POR2) color = peptideColorPaletteVdW[peptideCodeInt];
              else if (peptidesSetType === PeptideSet.NonStandard) color = nonStandardPeptidesColor[peptideCodeInt % nonStandardPeptidesColor.length];

              if (color === undefined) color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE;

              return color;
            }),
          },
          hoverinfo: 'y+x',
          hoveron: 'points',
        },
      ];

      if (normType === +NormType.Normalized) {
        const range = [Math.min(...signatureVector), Math.max(...signatureVector)];
        if (plotlyLayout.yaxis !== undefined) {
          plotlyLayout.yaxis.range = range;
          plotlyLayout.yaxis.automargin = true;
        } else {
          plotlyLayout.yaxis = {
            range: range,
            automargin: true,
          };
        }
      }

      plotlyLayout.xaxis = { automargin: true };
      break;
  }
  return <Plot divId="signature_scatter_plot" debug={true} data={plotlyData} layout={plotlyLayout} useResizeHandler={true} config={defaultPlotlyArguments.config} style={defaultPlotlyArguments.style} />;
};
