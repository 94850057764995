import { Col, Row, Switch, Typography } from 'antd';
import { ReactNode } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SingleSensogramStatic } from '../../components/graphic/sensogram/SingleSensogram/SingleSensogramStatic';
import { ObservationsInput } from '../../components/summary/caption';
import { selectSessionID } from '../../features/analysisConfig/analysisConfigSlice';
import {
  selectIsDrawHumidity,
  selectIsDrawTemperature,
  selectIsIncludeSensogramsAnnex,
  selectObservationsSingleSensogram,
  setIsDrawHumidity,
  setIsDrawTemperature,
  setIsIncludeSensogramsAnnex,
  setObservationsSingleSensogram,
} from '../../features/analysisConfig/pdfSlice';
import { AryRecord, AuxiliarySensorType, TemperatureSeriesType } from '../../types/analysisTypes';
import { useOktaOrQueryAuth } from '../../utils';
import Pagebreak from './pageBreak';
import { saveParamsInDatabase } from './pdfContainer';

type AnnexSensogramProps = {
  effectiveRecords: AryRecord[];
};

const AnnexSensogram: React.FC<AnnexSensogramProps> = ({ effectiveRecords }) => {
  const includeSensogramsAnnex = useAppSelector(selectIsIncludeSensogramsAnnex);
  const drawHumidity = useAppSelector(selectIsDrawHumidity);
  const drawTemperature = useAppSelector(selectIsDrawTemperature);
  const sessionID = useAppSelector(selectSessionID);
  const dispatch = useAppDispatch();

  const { authState } = useOktaOrQueryAuth();

  if (drawHumidity === undefined || drawTemperature === undefined) return null;

  const getAllSingleSensogram = (): ReactNode[] => {
    var children: ReactNode[] = [];
    const nChartsPerPage = 6;

    for (let i = 0; i < effectiveRecords.length; i += nChartsPerPage) {
      const records: AryRecord[] = effectiveRecords.slice(i, i + nChartsPerPage);
      children.push(
        <>
          <Row>
            {records.map((record) => {
              return (
                <Col sm={12} span={24} style={{ height: 300 }} key={record.ID}>
                  <h3 style={{ textAlign: 'center', margin: 0, padding: 0 }}>
                    {record.ItemName}
                    <span style={{ marginLeft: 5 }}>#{record.CycleNumber}</span>
                  </h3>
                  <SingleSensogramStatic
                    sessionID={sessionID}
                    recordID={record.ID}
                    drawHumidity={drawHumidity}
                    drawTemperature={drawTemperature}
                    drawAuxiliarySensor={false}
                    drawAdditionalSensor={false}
                    temperatureType={TemperatureSeriesType.SensorTemperature}
                    auxiliarySensorType={AuxiliarySensorType.PidVoc}
                    additionalSensorType={undefined}
                    overridePlotlyConfig={{
                      staticPlot: true,
                    }}
                    style={{ width: '100%', height: '100%', maxHeight: 250 }}
                  />
                </Col>
              );
            })}
          </Row>
          {effectiveRecords[effectiveRecords.length - 1] && effectiveRecords[effectiveRecords.length - 1].ID !== records[records.length - 1].ID && <Pagebreak />}
        </>
      );
    }

    return children;
  };

  return (
    <>
      <div className={includeSensogramsAnnex === false ? 'pdf-invisible' : undefined}>
        <Pagebreak />
      </div>

      <div className="pdf-invisible">
        <Row>
          <Col style={{ marginRight: 10 }}>
            <Switch
              checked={includeSensogramsAnnex}
              onChange={() => {
                dispatch(setIsIncludeSensogramsAnnex(!includeSensogramsAnnex));
                saveParamsInDatabase({ includeSensogramsAnnex: !includeSensogramsAnnex }, sessionID, authState);
              }}
            ></Switch>
          </Col>
          <Col>Include sensograms in the Annex</Col>
        </Row>
      </div>

      {includeSensogramsAnnex && (
        <>
          <Row justify="start" style={{ marginTop: 20 }}>
            <Col>
              <Typography.Title level={3}>Annexes</Typography.Title>
            </Col>
          </Row>
          <Row>
            <Row justify="start">
              <Col style={{ marginTop: 20 }}>
                <Typography.Title level={4}>Annex 1. Individual sensograms of included measurements</Typography.Title>
              </Col>
            </Row>
          </Row>
          <div className="pdf-invisible">
            <Row style={{ marginBottom: 20 }}>
              <Row style={{ marginRight: 50 }}>
                <Col style={{ marginRight: 10 }}>
                  <Switch
                    checked={drawHumidity}
                    onChange={(checked) => {
                      dispatch(setIsDrawHumidity(checked));
                      saveParamsInDatabase({ drawHumidity: checked }, sessionID, authState);
                    }}
                  ></Switch>
                </Col>
                <Col>Draw humidity</Col>
              </Row>
              <Row>
                <Col style={{ marginRight: 10 }}>
                  <Switch
                    checked={drawTemperature}
                    onChange={(checked) => {
                      dispatch(setIsDrawTemperature(checked));
                      saveParamsInDatabase({ drawTemperature: checked }, sessionID, authState);
                    }}
                  ></Switch>
                </Col>
                <Col>Draw temperature</Col>
              </Row>
            </Row>
          </div>

          {getAllSingleSensogram()}

          <InputSensogram sessionID={sessionID} authState={authState} />
        </>
      )}
    </>
  );
};

export const InputSensogram: React.FC<{ sessionID: string; authState: any }> = ({ sessionID, authState }) => {
  const observationsSingleSensogram = useAppSelector(selectObservationsSingleSensogram);

  return <ObservationsInput setObservations={setObservationsSingleSensogram} observations={observationsSingleSensogram} sessionID={sessionID} authState={authState} name="observationsSingleSensogram" />;
};

export default AnnexSensogram;
