import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Popover } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { arycolor } from '../../../assets/css/color';
import { selectAggregatePeptides, setAggregatePeptides } from '../../../features/analysisConfig/analysisConfigSlice';
import { FeatureFlag, UserClaimsWithTSDB } from '../../../types/userType';

type AggregatePeptidesSettingsProps = {
  userInfo: UserClaimsWithTSDB | null;
};
const AggregatePeptidesSettings: React.FC<AggregatePeptidesSettingsProps> = ({ userInfo }) => {
  const aggregatePeptides = useAppSelector(selectAggregatePeptides);
  const dispatch = useAppDispatch();

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 30 }}>
      <Checkbox
        disabled={userInfo === null || userInfo.feature_flags.includes(FeatureFlag.AALightViewQualityControl)}
        style={{ marginRight: 10 }}
        checked={aggregatePeptides}
        onChange={() => {
          dispatch(setAggregatePeptides(!aggregatePeptides));
        }}
      />
      <span>Aggregated peptides</span>
      <Popover
        style={{ display: 'flex' }}
        trigger={'hover'}
        placement="bottom"
        content={
          <div>
            <p style={{ marginBottom: 0 }}>
              Median-aggregate sensograms by peptide nature (codes) to auto-exclude outlying spots
              <br />
              All the processing will be performed on the aggregated data
            </p>
          </div>
        }
      >
        <FontAwesomeIcon
          icon="info-circle"
          className="clickable-icon"
          style={{
            color: arycolor.aryBlue,
            marginLeft: 20,
          }}
        />
      </Popover>
    </div>
  );
};

export default AggregatePeptidesSettings;
