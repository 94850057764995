import { Tag, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { FC } from 'react';
import { defaultColorPalette } from '../../compute/colormap';
import { getPeptideSetType, renderColoredTag, renderDeviceID, renderExposureIcon, renderFwVersion, renderPeptideSetType, renderSwVersion, renderTimeDuration, renderTimestamp, spotsGrid2PeptideSet } from '../../compute/utils';
import { RunMetadata } from '../../types/runsType';

export const RunSummaryTable: FC<{ runs: RunMetadata[]; cmap?: Record<string, string>; drawFooter: boolean }> = (props) => {
  const { runs, cmap } = props;

  const columns: ColumnsType<RunMetadata> = [
    {
      title: 'ID',
      dataIndex: 'ID',
      key: 'ID',
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      render: (value: string) => {
        return (
          <div className="no-scroll">
            <span className="tag-multilign">{value} </span>
          </div>
        );
      },
    },
    {
      title: 'Count',
      dataIndex: 'RecordsCount',
      key: 'RecordsCount',
    },
    {
      title: 'Items',
      dataIndex: 'ItemNames',
      key: 'ItemNames',
      render: (value: string[]) => {
        if (!value) {
          return null;
        }
        return (
          <div className="no-scroll" style={{ overflowY: 'scroll', maxHeight: '150px' }}>
            {value.map((itemName) => {
              if (!cmap) {
                return <Tag className="tag-multilign">{itemName}</Tag>;
              }
              return renderColoredTag(defaultColorPalette[cmap[itemName]], <>{itemName}</>);
            })}
          </div>
        );
      },
    },
    {
      title: 'Tags',
      dataIndex: 'Tags',
      key: 'Tags',
      render: (value: string[]) => {
        if (!value) {
          return null;
        }
        return (
          <div className="no-scroll" style={{ overflow: 'scroll', maxHeight: '150px' }}>
            {value.map((tag) => tag[0] !== '$' && <Tag>{tag}</Tag>)}
          </div>
        );
      },
    },
    {
      title: 'Flags',
      dataIndex: '',
      responsive: ['lg'],
      key: 'Flags',
      render(value, record) {
        if (!record.Device || !record.Device.Info) {
          return null;
        }
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            {renderPeptideSetType(getPeptideSetType(record.Device.Info.SpotsGrid))} {renderSwVersion(record.SWVersion)} {renderFwVersion(record.Device.Info.Versions?.fsp_refdesign_fw)} {renderExposureIcon(record.Device.Info.CameraExposure)}
          </div>
        );
      },
    },
  ];

  return (
    <Table
      size="small"
      style={{ width: '100%' }}
      columns={columns}
      dataSource={runs}
      pagination={false}
      scroll={{ x: '100%' }}
      expandable={{
        expandedRowRender: (run) => (
          <>
            <Typography.Paragraph>
              <b>Device ID: </b>
              {renderDeviceID(run.Device.ID)}
              <br />
              <b>Started: </b> {renderTimestamp(run.TimestampStart)}
              <br />
              <b>Ended: </b> {renderTimestamp(run.TimestampEnd)} (ended {renderTimeDuration(new Date(run.TimestampEnd), new Date())} ago)
              <br />
              <b>Duration: </b> {renderTimeDuration(new Date(run.TimestampStart), new Date(run.TimestampEnd))}
              <br />
              <b>SW Version: </b> {run.SWVersion} {renderSwVersion(run.SWVersion)}
              <br />
              <b>FW Version: </b> {run.Device?.Info?.Versions?.fsp_refdesign_fw} {renderFwVersion(run.Device?.Info?.Versions?.fsp_refdesign_fw)}
              <br />
              <b>Exposure: </b> {run.Device?.Info?.CameraExposure} μs {renderExposureIcon(run.Device?.Info?.CameraExposure)}
              <br />
              <b>Peptides: </b> {renderPeptideSetType(getPeptideSetType(run.Device?.Info?.SpotsGrid))}
              <br />
              {(function () {
                let spots = Array.from(spotsGrid2PeptideSet(run.Device?.Info?.SpotsGrid));
                let rows = [];
                for (let i = 0; i < spots.length; i += 4) {
                  rows.push(
                    <>
                      {spots.slice(i, i + 4).join(',')}
                      <br />
                    </>
                  );
                }
                return rows;
              })()}
            </Typography.Paragraph>
          </>
        ),
        rowExpandable: () => true,
        defaultExpandAllRows: true,
      }}
    />
  );
};
