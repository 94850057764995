import { message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { RunSummaryTable } from '../../components/summary/runs_summary_table';
import { selectColormap } from '../../features/analysisConfig/analysisConfigSlice';
import { fetchAuthorizedAPIEndpoint } from '../../utils';
import { useOktaAuth } from '@okta/okta-react';
import { RunMetadata } from '../../types/runsType';

export const SessionRunsTable: FC<{ sessionID: string }> = (props) => {
  const { sessionID } = props;

  const { authState } = useOktaAuth();

  const [runs, setRuns] = useState<RunMetadata[]>([]);
  const cmap = useAppSelector(selectColormap);

  useEffect(() => {
    fetchAuthorizedAPIEndpoint(`/runs?session_id=${sessionID}`, authState)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .catch((e) => {
        e.then((resp: { Reason: string }) => {
          message.error(`Error occured during data digestion in the cloud: ${resp.Reason}`, 5);
        });
        return;
      })
      .then((runs: RunMetadata[]) => {
        runs.forEach((run) => {
          if (run.Tags === null) {
            run.Tags = [];
          }
          if (run.ItemNames === null) {
            run.ItemNames = [];
          }
          if (run.Cycles === null) {
            run.Cycles = [];
          }
        });
        setRuns(runs);
      });
  }, [sessionID]);

  return <RunSummaryTable runs={runs} cmap={cmap} drawFooter={false} />;
};
