import { Col, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { getColormap } from '../../compute/colormap';
import {
  setAggregatePeptides,
  setBoundariesMap,
  setChemicalCalibrationItemNames,
  setColormap,
  setExcludedPeptides,
  setExcludedRecordIDs,
  setExcludedSpots,
  setHumidityCompensationCalibrantName,
  setHumidityCompensationPositionOffset,
  setHumidityCompensationSubstractionGain,
  setPCAEigenvalues,
  setPCAEigenvectors,
  setRecords as setRecordsRedux,
  setSessionID,
  setSubtractItemName,
} from '../../features/analysisConfig/analysisConfigSlice';
import { AnalysisConfig, AryRecord } from '../../types/analysisTypes';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../utils';
import CoordonatePdf from './coordonatePdf';
import DiscriminationPdf from './discriminationPdf';
import IntensityPdf from './intensityPdf';
import SensogramPdf from './sensogramPdf';
import SignaturesPdf from './signaturesPdf';
import SummaryRecordPdf from './summaryRecordPdf';
import SummaryRunsPdf from './summaryRunsPdf';
import TitlePdf from './titlePdf';
import {
  PdfType,
  selectIsIncludeExperimentPlan,
  setAbstract,
  setAuthor,
  setCompany,
  setDivision,
  setGenerationDate,
  setIsDrawHumidity,
  setIsDrawTemperature,
  setIsIncludeConfusionMatrix,
  setIsIncludeExperimentPlan,
  setIsIncludeSensogramsAnnex,
  setObservationsConfusion,
  setObservationsDiscrimination,
  setObservationsIntensity,
  setObservationsSensogram,
  setObservationsSignatures,
  setObservationsSingleSensogram,
  setStudySubtitle,
  setStudyTitle,
  setSummaryRecords,
  setSummaryRuns,
} from '../../features/analysisConfig/pdfSlice';
import Pagebreak from './pageBreak';
import AnnexPdf from './annexPdf';
import ButtonExportPdf from './buttonExportPdf';
import { useMediaQuery } from '../../App';
import moment from 'moment';
import { CustomAuthState } from '../../types/userType';
import ExperimentPlanPdf from './ExperimentPlanPdf';
import { ModelsConfusionType } from '../../components/graphic/matrix/Matrix';

type pdfContainerProps = {};

export const saveParamsInDatabase = (params: PdfType, sessionID: string, authState: CustomAuthState | null) => {
  fetchAuthorizedAPIEndpoint(`/pdf/upsert?session_id=${sessionID}`, authState, { method: 'POST', body: JSON.stringify(params) })
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      } else {
        throw new Error();
      }
    })
    .then((res: any) => {
      console.log(res);
    });
};

const PdfContainer: React.FC<pdfContainerProps> = () => {
  const [analysisConfig, setAnalysisConfig] = useState<AnalysisConfig>();
  const disabledPdf = true;
  const [effectiveRecords, setEffectiveRecords] = useState<AryRecord[]>([]);

  const { sessionID } = useParams<{ sessionID: string }>();
  const dispatch = useAppDispatch();
  const { authState } = useOktaOrQueryAuth();

  const [seletedModelsConfusion, setSelectedModelsConfusion] = useState<string | undefined>();

  const isRowBased = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (!authState) return;
    fetchAuthorizedAPIEndpoint(`/pdf/get?session_id=${sessionID}`, authState)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw new Error();
        }
      })
      .then((params: any) => {
        if (params) {
          dispatch(setStudyTitle(params.studyTitle));
          dispatch(setCompany(params.company));
          dispatch(setDivision(params.division));
          dispatch(setAuthor(params.author));
          dispatch(setGenerationDate(moment(params.generationDate !== null ? new Date(params.generationDate) : new Date(), 'YYYY-MM-DD').format('YYYY/MM/DD')));
          dispatch(setAbstract(params.abstract));
          dispatch(setSummaryRuns(params.summaryRuns));
          dispatch(setSummaryRecords(params.summaryRecords));
          dispatch(setObservationsSensogram(params.observationsSensogram));
          dispatch(setObservationsSignatures(params.observationsSignatures));
          dispatch(setObservationsDiscrimination(params.observationsDiscrimination));
          dispatch(setObservationsConfusion(params.observationsConfusion));
          dispatch(setObservationsIntensity(params.observationsIntensity));
          dispatch(setIsIncludeSensogramsAnnex(params.includeSensogramsAnnex ?? false));
          dispatch(setIsIncludeConfusionMatrix(params.includeConfusionMatrix ?? false));
          dispatch(setIsDrawHumidity(params.drawHumidity ?? false));
          dispatch(setIsDrawTemperature(params.drawTemperature ?? false));
          dispatch(setObservationsSingleSensogram(params.observationsSingleSensogram));
          dispatch(setStudySubtitle(params.studySubtitle));
          dispatch(setStudyTitle(params.studyTitle));
          dispatch(setIsIncludeExperimentPlan(params.includeExperimentPlan ?? true));
        }
      });
  }, [authState, dispatch, sessionID]);

  // Load config
  useEffect(() => {
    if (authState === null) {
      return;
    }

    if (sessionID !== '') {
      fetchAuthorizedAPIEndpoint(`/load_config?session_id=${sessionID}`, authState)
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            throw new Error();
          }
        })
        .then((ac: AnalysisConfig) => {
          setAnalysisConfig(ac);
          if (ac.AggregatePeptides !== null) {
            dispatch(setAggregatePeptides(ac.AggregatePeptides));
          }
          if (ac.SubtractItemName !== null) {
            dispatch(setSubtractItemName(ac.SubtractItemName));
          }
          if (ac.PCAEigenvalues !== null) {
            dispatch(setPCAEigenvalues(ac.PCAEigenvalues));
          }
          if (ac.PCAEigenvectors !== null) {
            dispatch(setPCAEigenvectors(ac.PCAEigenvectors));
          }
          if (ac.ExcludedRecordIDs !== null) {
            dispatch(setExcludedRecordIDs(ac.ExcludedRecordIDs));
          }
          if (ac.BoundariesMap !== null) {
            dispatch(setBoundariesMap(ac.BoundariesMap));
          }
          if (ac.SessionID !== null) {
            dispatch(setSessionID(ac.SessionID));
          }
          if (ac.ExcludedPeptides !== null) {
            dispatch(setExcludedPeptides(ac.ExcludedPeptides));
          }
          if (ac.ExcludedSpots !== null) {
            dispatch(setExcludedSpots(ac.ExcludedSpots));
          }
          if (ac.HumidityCompensation && ac.HumidityCompensation.CalibrantName) {
            dispatch(setHumidityCompensationCalibrantName(ac.HumidityCompensation.CalibrantName));
          }
          if (ac.HumidityCompensation && ac.HumidityCompensation.PositionOffset) {
            dispatch(setHumidityCompensationPositionOffset(ac.HumidityCompensation.PositionOffset));
          }
          if (ac.HumidityCompensation && ac.HumidityCompensation.SubstractionGain) {
            dispatch(setHumidityCompensationSubstractionGain(ac.HumidityCompensation.SubstractionGain));
          }
          if (ac.ChemicalCalibrationItemNames !== undefined && ac.ChemicalCalibrationItemNames !== null) {
            dispatch(setChemicalCalibrationItemNames(ac.ChemicalCalibrationItemNames));
          }
        });
    }
  }, [authState, sessionID, dispatch]);

  // Load records
  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    if (!analysisConfig) {
      return;
    }
    if (sessionID !== '') {
      fetchAuthorizedAPIEndpoint(`/list_records?session_id=${sessionID}&ts=${Date.now()}`, authState) // List records is a lightweight version of /records; As long as we only need metadata, we may use /list_records
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            throw new Error();
          }
        })
        .then((records: AryRecord[]) => {
          if (records != null) {
            let _effectiveRecords: AryRecord[] = [];
            records.forEach((r) => {
              if (analysisConfig.ExcludedRecordIDs !== null) {
                if (!analysisConfig.ExcludedRecordIDs.includes(r.ID)) {
                  _effectiveRecords.push(r);
                }
              } else {
                _effectiveRecords.push(r);
              }
            });

            const uniqueLabels = Array.from(new Set(records.map((r) => r.ItemName)));
            uniqueLabels.sort();
            let cmap = getColormap(uniqueLabels);
            dispatch(setColormap(cmap));
            dispatch(setRecordsRedux(records));
            setEffectiveRecords(_effectiveRecords);
          }
        });
    }
  }, [authState, sessionID, analysisConfig, dispatch]);

  useEffect(() => {
    if (authState === null || !authState.accessToken) return;

    fetchAuthorizedAPIEndpoint(`/list_models`, authState)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((models: ModelsConfusionType[]) => {
        console.log(models);
        setSelectedModelsConfusion(models[0].Name);
      });
  }, [authState]);

  if (analysisConfig === undefined) {
    return null;
  }

  if (authState === null) {
    return <>Unauthorized</>;
  }

  return (
    <>
      <ButtonExportPdf sessionID={sessionID} />
      <Row justify="center">
        <Col className="pdf-box" style={{ width: isRowBased ? '95%' : '70%', padding: isRowBased ? 20 : 50, marginTop: isRowBased ? 20 : 50, marginBottom: isRowBased ? 150 : 100 }}>
          <TitlePdf />
          <CoordonatePdf />
          <Pagebreak />

          <Row justify="start">
            <Col>
              <Typography.Title level={3}>1. Results and discussion</Typography.Title>
            </Col>
          </Row>

          <SensogramPdf effectiveRecords={effectiveRecords} />
          <SignaturesPdf effectiveRecords={effectiveRecords} />
          <DiscriminationPdf effectiveRecords={effectiveRecords} sessionID={sessionID} disabledPdf={disabledPdf} seletedModelsConfusion={seletedModelsConfusion} />
          <IntensityPdf effectiveRecords={effectiveRecords} sessionID={sessionID} disabledPdf={disabledPdf} />

          <Row justify="start">
            <Col>
              <Typography.Title level={3}>2. Materials and methods</Typography.Title>
            </Col>
          </Row>

          <Row justify="start">
            <Col span={24}>
              <SummaryRunsPdf />
            </Col>
          </Row>
          <Pagebreak />

          <Row justify="start">
            <Col span={24}>
              <SummaryRecordPdf analysisConfig={analysisConfig} />
            </Col>
          </Row>

          <Row justify="start">
            <Col span={24}>
              <ExperimentPlanPdf />
            </Col>
          </Row>

          <Row justify="start">
            <Col>
              <Typography.Title level={3}>3. Miscellaneous</Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography.Paragraph>
                Link to the original study (note: persistency of the link is not yet assured): <a href={`${window.location.origin}/dashboard/${sessionID}`}>{sessionID}</a>
                <br />
                Generated with <b>Aryballe Digital Olfaction Hub</b> ver.
                <b> {process.env.REACT_APP_VERSION}</b>
                <br />
                2022 Aryballe
              </Typography.Paragraph>
            </Col>
          </Row>

          <AnnexPdf effectiveRecords={effectiveRecords} />
        </Col>
      </Row>
    </>
  );
};

export default PdfContainer;
