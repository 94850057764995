import { useOktaAuth } from '@okta/okta-react';
import { Popover } from 'antd';
import { FC, ReactElement, useEffect, useState } from 'react';
import { FeatureFlag, UserClaimsWithTSDB } from './types/userType';

export const WithFeatureFlagsCheck: FC<{ ff: FeatureFlag; children: ReactElement<any, any> | null }> = ({ ff, children }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState, oktaAuth]);

  if (userInfo === null) {
    return null;
  }

  var featureFlagEnabled: boolean = false;
  var roleAryballe: boolean = false;
  if (userInfo.feature_flags.includes(FeatureFlag.RoleAryballe)) {
    roleAryballe = true;
  }
  if (userInfo.feature_flags.includes(ff)) {
    featureFlagEnabled = true;
  }

  if (!featureFlagEnabled && !roleAryballe) {
    return null;
  }

  return (
    <div style={{ display: 'flex' }}>
      <>
        {children}
        {roleAryballe && (
          <Popover
            overlayStyle={{ zIndex: '1051' }}
            content={
              <>
                This feature is only available to users
                <br /> with feature_flag <b>"{ff}"</b> activated
              </>
            }
          >
            <span style={{ fontSize: '14pt', color: 'red', marginLeft: '5px', cursor: 'help' }}>*</span>
          </Popover>
        )}
      </>
    </div>
  );
};
