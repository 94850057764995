/* ---------------------------
        DEVICE INFO
------------------------------ */
export interface VersionsInfo {
  Camera: string;
  Sensogram: string;
  fsp_refdesign_hw: string;
  fsp_refdesign_fw: string;
  fsp_control_hw: string;
  fsp_control_fw: string;
}

export interface DeviceInfo {
  DeviceClass: string;
  PumpPower: number;
  SensogramFrequency: number;
  ShellSerial: string;
  CoreSensorSerial: string;
  CameraExposure: number;
  CameraFrequency: number;
  CameraWidth: number;
  CameraHeight: number;
  CameraDepth: number;
  vcselPower: number;
  SpotsGrid: number[];
  PeakPhaseGains: number[];
  PeakPhaseOffsets: number[];
  PeakMaskBottomLeft: number[];
  PeakMaskTopRight: number[];
  PeakMaskAngle: number[];
  PeakMaskRadius: number[];
  Versions: VersionsInfo;
}

export interface DeviceMetadata {
  ID: string;
  RunUID: string;
  HostName: string;
  Info: DeviceInfo;
}

/* ---------------------------
        SOURCES - TSDB
------------------------------ */
export enum SourceType {
  HTML = 0,
  SQLite,
  TSDB,
}

export interface Source {
  Type: SourceType;
  Name: string;
}

export interface TSDBRef {
  Name: string;
  URL: string;
  Org: string;
  Token: string;
}

/* ---------------------------
            RUN
------------------------------ */
export interface RunMetadata {
  UID: string;
  ID: string;
  Device: DeviceMetadata;

  Name: string;
  ProtocolName: string;
  RecordsCount: number;
  TimestampStart: number;
  TimestampEnd: number;
  Tags: string[];
  UserTags: string[];
  Cycles: number[];
  ItemNames: string[];
  SWVersion: string;
  ExperimentPlan: string;
  UserEmail: string;

  Source: Source;
}

export interface SelectedRun {
  TSDBRef: TSDBRef;
  UID: string;
}
