import { Col, Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { Caption } from '../../components/summary/caption';
import { RecordSummaryTable } from '../../components/summary/records_summary_table';
import { selectColormap, selectRecords } from '../../features/analysisConfig/analysisConfigSlice';
import { selectIsIncludeSensogramsAnnex, selectSummaryRecords, setSummaryRecords } from '../../features/analysisConfig/pdfSlice';
import { AnalysisConfig } from '../../types/analysisTypes';
import { useOktaOrQueryAuth } from '../../utils';
import Pagebreak from './pageBreak';

type SummaryRecordPdfProps = {
  analysisConfig: AnalysisConfig | undefined;
};

const SummaryRecordPdf: React.FC<SummaryRecordPdfProps> = ({ analysisConfig }) => {
  const cmap = useAppSelector(selectColormap);
  const records = useAppSelector(selectRecords);
  const summaryRecords = useAppSelector(selectSummaryRecords);
  const isIncludeSensogramsAnnex = useAppSelector(selectIsIncludeSensogramsAnnex);

  const { sessionID } = useParams<{ sessionID: string }>();
  const { authState } = useOktaOrQueryAuth();

  if (analysisConfig === undefined || !records) {
    return null;
  }

  return (
    <div id="summaryrecordPdf">
      <Row justify="start">
        <Col>
          <Typography.Title level={4}>2.2 Summary of records</Typography.Title>
        </Col>
      </Row>
      <Row justify="start">
        <Col xs={24}>
          <RecordSummaryTable records={records} analysisConfig={analysisConfig} cmap={cmap} />
        </Col>
      </Row>
      <Caption title="Table 2" subtitle="Summary of records" setObservations={setSummaryRecords} observations={summaryRecords} sessionID={sessionID} authState={authState} name="summaryRecords" />

      {isIncludeSensogramsAnnex && (
        <div>
          <Row justify="start" style={{ marginTop: 30 }}>
            <Col>
              <Typography.Title level={4}>2.3 Sensogram profile</Typography.Title>
            </Col>
          </Row>
          <p>Sensogram profile of every non-excluded record with baseline/analyte zones is visible in Annex 1.</p>
        </div>
      )}
      <Pagebreak />
    </div>
  );
};

export default SummaryRecordPdf;
