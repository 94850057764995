import { Col, Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { MultiSensogram } from '../../components/graphic/sensogram/MultiSensogram';
import { Caption } from '../../components/summary/caption';
import { selectColormap } from '../../features/analysisConfig/analysisConfigSlice';
import { selectObservationsSensogram, setObservationsSensogram } from '../../features/analysisConfig/pdfSlice';
import { AryRecord } from '../../types/analysisTypes';
import { useOktaOrQueryAuth } from '../../utils';
import Legend from './legend';
import Pagebreak from './pageBreak';

type SensogramPdfProps = {
  effectiveRecords: AryRecord[];
};

const SensogramPdf: React.FC<SensogramPdfProps> = ({ effectiveRecords }) => {
  const cmap = useAppSelector(selectColormap);
  const observationsSensogram = useAppSelector(selectObservationsSensogram);

  const { sessionID } = useParams<{ sessionID: string }>();
  const { authState } = useOktaOrQueryAuth();

  return (
    <div id="sensogramPdf">
      <Row justify="start">
        <Col>
          <Typography.Title level={4}>1.1. Sensograms</Typography.Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col sm={18} span={24}>
          <div style={{ width: '100%', aspectRatio: '16/9' }}>
            <MultiSensogram isStatic={true} />
          </div>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Legend effectiveRecords={effectiveRecords} cmap={cmap} />
        </Col>
      </Row>
      <Caption
        title="Fig 1"
        subtitle="Averaged signal profiles (sensograms) of the measured samples (excluded samples not taken into account)"
        setObservations={setObservationsSensogram}
        observations={observationsSensogram}
        sessionID={sessionID}
        authState={authState}
        name="observationsSensogram"
      />
      <Pagebreak />
    </div>
  );
};

export default SensogramPdf;
