import { Tooltip } from 'antd';
import { ReactNode } from 'react';
import { AvgErrValue } from '../types/analysisTypes';

export const computeRelativeError = (item: AvgErrValue): string => (100 * (item.err / item.avg)).toFixed(1);

export const formatRelErrValue = (item: AvgErrValue): ReactNode => {
  if (item.avg !== 0) {
    return <span>{`(${computeRelativeError(item)}%)`}</span>;
  }
  return null;
};

export const FormatErrValue: React.FC<AvgErrValue> = ({ avg, err }) => (
  <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
    <Tooltip title={avg.toFixed(5)}>{avg.toFixed(2)}</Tooltip>±<Tooltip title={err.toFixed(5)}>{err.toFixed(2)}</Tooltip>
    &nbsp;
    {formatRelErrValue({ avg, err })}
  </div>
);
