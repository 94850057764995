type IProps = {
  children: string
  style?: React.CSSProperties
}

export const HtmlDescription: React.FC<IProps> = (props) => (
  // Thanks react but no thanks.
  // eslint-disable-next-line react/no-danger
  <div dangerouslySetInnerHTML={{ __html: props.children }} style={props.style} />
);

HtmlDescription.defaultProps = {
  style: undefined,
};
