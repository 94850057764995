import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'antd';
import CardSection from '../../../components/section/CardSection';
import { AppRoute } from '../../../routes';
import { Link } from 'react-router-dom';

type SavedSessionsHomeProps = {};

const SavedSessionsHome: React.FC<SavedSessionsHomeProps> = () => {
  return (
    <>
      <Link to={AppRoute.SESSIONS}>
        <CardSection height={134} backgroundColor="#1890FF10" marginBottom={0} hoverable={true}>
          <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <FontAwesomeIcon icon="save" style={{ width: 20, height: 20 }} />
            <h3 style={{ fontSize: 16, margin: 0, marginTop: 10, textAlign: 'center' }}>My saved sessions</h3>
          </Col>
        </CardSection>
      </Link>
    </>
  );
};

export default SavedSessionsHome;
