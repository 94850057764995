import { Breadcrumb } from 'antd';

export type CrumbsType = {
  route: string;
  label: string;
};

type BreadcrumbComponentProps = {
  crumbs: CrumbsType[];
};

const BreadcrumbComponent: React.FC<BreadcrumbComponentProps> = (props) => {
  return (
    <Breadcrumb style={{ lineHeight: '50px' }}>
      {props.crumbs.map((crumb, key) => (
        <Breadcrumb.Item key={key} href={crumb.route}>
          {crumb.label}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbComponent;
