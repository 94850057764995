import { ArrowLeftOutlined, ArrowRightOutlined, ArrowsAltOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOktaAuth } from '@okta/okta-react';
import { Alert, Popover, Space, Tag, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import tinycolor from 'tinycolor2';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { arycolor } from '../../../assets/css/color';
import { aryballeColorPalette, defaultColorPalette, colorToTransparent } from '../../../compute/colormap';
import { defaultPlotlyArguments, getGroupedMap, getRecordNameWithBr } from '../../../compute/utils';
import {
  selectAggregatePeptides,
  selectBoundariesMap,
  selectColormap,
  selectCurrentItemName,
  selectExcludedPeptides,
  selectExcludedRecordIDs,
  selectExcludedSpots,
  selectRecords,
  selectSessionID,
  selectSubtractItemName,
  setBoundariesMap,
} from '../../../features/analysisConfig/analysisConfigSlice';
import { AryRecord, AryRecordEnvelope } from '../../../types/analysisTypes';
import { fetchAuthorizedAPIEndpoint } from '../../../utils';
import RecordsLegend from '../../RecordsLegend';
import FullscreenGraphicModal from '../FullscreenGraphicModal';

export const ItemwiseSensogram: FC<{ showLegend?: boolean; isStatic?: boolean }> = ({ showLegend, isStatic }) => {
  const aggregatePeptides = useAppSelector(selectAggregatePeptides);
  const boundariesMap = useAppSelector(selectBoundariesMap);
  const subtractItemName = useAppSelector(selectSubtractItemName);
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const sessionID = useAppSelector(selectSessionID);
  const cmap = useAppSelector(selectColormap);
  const records = useAppSelector(selectRecords);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const excludedPeptides = useAppSelector(selectExcludedPeptides);

  const currentItemName = useAppSelector(selectCurrentItemName);

  const [plotlyData, setPlotlyData] = useState<Plotly.Data[]>([]);
  const [plotlyLayout, setPlotlyLayout] = useState<Partial<Plotly.Layout>>({});

  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);

  const { authState } = useOktaAuth();

  const dispatch = useAppDispatch();

  const [recordEnvelopes, setRecordEnvelopes] = useState<AryRecordEnvelope[]>([]);
  const [error, setError] = useState('');
  const [effectiveCommonAnalyteLeft, setEffectiveCommonAnalyteLeft] = useState(0);
  const [effectiveCommonAnalyteRight, setEffectiveCommonAnalyteRight] = useState(0);
  const [shouldDrawCommonBoundaries, setShouldDrawCommonBoundaries] = useState(true);

  useEffect(() => {
    if (!records) {
      return;
    }
    fetchAuthorizedAPIEndpoint(`/record_envelopes?session_id=${sessionID}&item_name=${currentItemName}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        aggregatePeptides,
        subtractItemName,
        excludedRecordIDs,
        excludedPeptides,
        excludedSpots,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((receivedEnvelopes: AryRecordEnvelope[]) => {
        setRecordEnvelopes(receivedEnvelopes);
        setError('');
      })
      .catch((e) => {
        Promise.resolve(e).then((resp: { Reason: string }) => {
          setError(resp.Reason);
        });
      });
  }, [sessionID, aggregatePeptides, subtractItemName, excludedRecordIDs, excludedSpots, excludedPeptides]);

  useEffect(() => {
    if (records === undefined) {
      return;
    }

    if (recordEnvelopes === undefined || recordEnvelopes === null) {
      return;
    }

    if (cmap === undefined) {
      return;
    }

    let effectiveLabels: string[] = [];
    let effectiveRecords: AryRecord[] = [];

    if (recordEnvelopes.length === 0) {
      return;
    }

    records.forEach((record) => {
      if (!excludedRecordIDs.includes(record.ID)) {
        effectiveRecords.push(record);
        effectiveLabels.push(record.ItemName);
      }
    });
    if (effectiveRecords.length === 0) {
      return;
    }

    const groupedEffectiveRecords: Record<string, AryRecord[]> = getGroupedMap(effectiveLabels, effectiveRecords);

    var commonBoundariesMap: Record<string, Set<string>> = {};
    effectiveRecords.forEach((record) => {
      var boundaries: number[] = [0, 0, 1e6, -1e6];
      boundaries[2] = Math.min(boundaries[2], boundariesMap[record.ID][2]);
      boundaries[3] = Math.max(boundaries[3], boundariesMap[record.ID][3]);
      let boundariesKey = `${boundaries[2]}_${boundaries[3]}`;

      if (commonBoundariesMap[boundariesKey] === undefined) {
        commonBoundariesMap[boundariesKey] = new Set<string>();
      }
      commonBoundariesMap[boundariesKey].add(record.ItemName);
    });

    let commonBoundariesArr = Object.entries(commonBoundariesMap);
    commonBoundariesArr.sort(([keyA, valueA], [keyB, valueB]) => valueB.size - valueA.size);

    // Should only draw "common" zone if at least 2 groups have common boundaries
    if (commonBoundariesArr[0][1].size < 2) {
      setShouldDrawCommonBoundaries(false);
    }

    let mostCommonBoundariesStr = commonBoundariesArr[0][0];
    let mostCommonBoundaries = mostCommonBoundariesStr.split('_').map((e) => parseInt(e));
    mostCommonBoundaries.splice(0, 0, ...[0, 0]);

    if (mostCommonBoundaries[2] !== effectiveCommonAnalyteLeft) {
      setEffectiveCommonAnalyteLeft(mostCommonBoundaries[2]);
    }
    if (mostCommonBoundaries[3] !== effectiveCommonAnalyteRight) {
      setEffectiveCommonAnalyteRight(mostCommonBoundaries[3]);
    }

    // Compute Plotly Data and Layout

    const _plotlyLayout: Partial<Plotly.Layout> = { ...defaultPlotlyArguments.layout };
    _plotlyLayout.dragmode = 'select';
    _plotlyLayout.yaxis = { title: { text: 'Intensity, rad' }, automargin: true };
    _plotlyLayout.xaxis = { title: { text: 'Time, sec' }, automargin: true };
    _plotlyLayout.annotations = [];
    if (showLegend) {
      _plotlyLayout.showlegend = true;
      _plotlyLayout.legend = {
        orientation: 'v',
      };
    }

    let _plotlyData: Plotly.Data[] = [];

    var apexMax = 0;
    var apexMin = 0;

    recordEnvelopes.forEach((recordEnvelope) => {
      var frameNb = recordEnvelope.MedianDataSeries.length;
      for (let frameIdx = 0; frameIdx < frameNb; frameIdx++) {
        let _avg = recordEnvelope.MedianDataSeries[frameIdx];
        apexMax = Math.max(apexMax, _avg);
        apexMin = Math.min(apexMin, _avg);
      }
    });

    // Set it to true once common boundaries are drawn once. Do not re-draw them for every group
    var isCommonBoundariesDrawn = false;

    Object.entries(groupedEffectiveRecords).forEach(([groupName, groupRecords]) => {
      if (groupName !== currentItemName) {
        return;
      }

      let boundaryLeftDefault = 1e6;
      let boundaryRightDefault = -1e6;

      var boundaries: number[] = [0, 0, boundaryLeftDefault, boundaryRightDefault];
      var isLeftBoundaryHeterogeneous = false;
      var isRightBoundaryHeterogeneous = false;

      groupRecords.forEach((record) => {
        if (boundaries[2] !== boundariesMap[record.ID][2]) {
          if (boundaries[2] !== boundaryLeftDefault) {
            isLeftBoundaryHeterogeneous = true;
          }
          boundaries[2] = Math.min(boundaries[2], boundariesMap[record.ID][2]);
        }
        if (boundaries[3] !== boundariesMap[record.ID][3]) {
          if (boundaries[3] !== boundaryRightDefault) {
            isRightBoundaryHeterogeneous = true;
          }
          boundaries[3] = Math.max(boundaries[3], boundariesMap[record.ID][3]);
        }
      });

      recordEnvelopes.forEach((envelope, j) => {
        var record: AryRecord | null = null;
        for (let i = 0; i < groupRecords.length; i++) {
          if (groupRecords[i].ID === envelope.RecordID) {
            record = groupRecords[i];
            break;
          }
        }
        if (record === null) {
          return;
        }

        const color = tinycolor(defaultColorPalette[cmap[groupName]]);
        const p = j / recordEnvelopes.length;
        const k = 20;
        if (p < 0.5) {
          color.darken(k * (1 - p));
        } else {
          color.brighten(k * p);
        }
        color.setAlpha(0.85);
        _plotlyData.push({
          type: 'scatter',
          name: getRecordNameWithBr(record),
          hoverlabel: { namelength: -1 },
          x: envelope.MedianDataSeries.map((_, i) => i / 3).map((e) => e.toFixed(1)),
          y: envelope.MedianDataSeries.map((e) => e.toFixed(3)),
          line: {
            color: color.toPercentageRgbString(),
          },
        });
      });

      let anColorLine = defaultColorPalette[cmap[groupName]];
      let anColorFill = colorToTransparent(defaultColorPalette[cmap[groupName]], 40);
      let marker: Partial<Plotly.ScatterMarker> = {};
      let traceName = groupName;

      let analyteLeft = boundaries[2] / 3;
      let analyteRight = (boundaries[3] - 1) / 3;

      // Set common boundaries
      if (shouldDrawCommonBoundaries && boundaries[2] === mostCommonBoundaries[2] && boundaries[3] === mostCommonBoundaries[3]) {
        if (isCommonBoundariesDrawn === true) {
          return;
        }
        anColorLine = aryballeColorPalette.gray;
        anColorFill = colorToTransparent(aryballeColorPalette.gray, 40);

        marker = {
          symbol: 'square',
          size: 8,
        };

        traceName = 'Common analyte';
        isCommonBoundariesDrawn = true;
      }

      _plotlyData.push({
        type: 'scatter',
        x: [analyteLeft, analyteLeft],
        y: [apexMin, apexMax],
        name: traceName,
        legendgroup: groupName,
        showlegend: false,
        line: {
          color: anColorLine,
        },
        marker: marker,
      });
      _plotlyData.push({
        type: 'scatter',
        x: [analyteRight, analyteRight],
        y: [apexMin, apexMax],
        name: traceName,
        legendgroup: groupName,
        showlegend: false,
        fill: 'tonextx',
        line: {
          color: anColorLine,
        },
        fillcolor: anColorFill,
        marker: marker,
      });

      if (isLeftBoundaryHeterogeneous) {
        _plotlyLayout.annotations?.push({
          x: analyteLeft,
          y: apexMax,
          ax: -10,
          ay: 0,
          showarrow: true,
          text: '⚠️',
          font: {
            size: 16,
          },
          hovertext: 'Some record(s) have independently set boundaries',
        });
      }

      if (isRightBoundaryHeterogeneous) {
        _plotlyLayout.annotations?.push({
          x: analyteRight,
          y: apexMax,
          ax: 10,
          ay: 0,
          showarrow: true,
          text: '⚠️',
          font: {
            size: 16,
          },
          hovertext: 'Some record(s) have independently set boundaries',
        });
      }
    });
    setPlotlyData(_plotlyData);
    setPlotlyLayout(_plotlyLayout);
  }, [recordEnvelopes, boundariesMap, currentItemName]); // As in the main fetch but with the boundaries

  const mutateBoundaries = (startIdx: number, endIdx: number) => {
    if (records === undefined) {
      return;
    }
    let _boundariesMap = { ...boundariesMap };
    records.forEach((r) => {
      if (r.ItemName !== currentItemName) {
        return;
      }
      let _boundaries = _boundariesMap[r.ID].map((e) => e);
      _boundaries[2] = startIdx;
      _boundaries[3] = endIdx;
      _boundariesMap[r.ID] = _boundaries;
    });
    dispatch(setBoundariesMap(_boundariesMap));
  };

  if (error !== '') {
    return (
      <div>
        <Alert type="error" message={error} />
      </div>
    );
  }

  let plotlyConfig: Partial<Plotly.Config> = { ...defaultPlotlyArguments.config };
  plotlyConfig.displayModeBar = true;
  plotlyConfig.modeBarButtons = [['zoom2d', 'select2d'] as Plotly.ModeBarDefaultButtons[]];
  if (isStatic) {
    plotlyConfig.staticPlot = isStatic;
  }

  if (plotlyData.length < 3) {
    return null;
  }

  return (
    <>
      <div
        tabIndex={0}
        onKeyDown={(e) => {
          let n: number;
          switch (e.key) {
            case 'ArrowLeft':
              if (e.ctrlKey) {
                n = 1;
              } else {
                n = 5;
              }
              mutateBoundaries(effectiveCommonAnalyteLeft - n, effectiveCommonAnalyteRight - n);
              break;
            case 'ArrowRight':
              if (e.ctrlKey) {
                n = 1;
              } else {
                n = 5;
              }
              mutateBoundaries(effectiveCommonAnalyteLeft + n, effectiveCommonAnalyteRight + n);
              break;
          }
        }}
        style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
      >
        {!isStatic && (
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-7pt' }}>
            <Space
              style={{
                minHeight: '35px', // Fix min height to avoid chart "skipping" on activate/deactivate auxiliary sensors
              }}
            >
              <Popover
                style={{ display: 'flex' }}
                trigger={'hover'}
                content={
                  <div>
                    <p>
                      Press{' '}
                      <Tag>
                        <ArrowLeftOutlined />
                      </Tag>
                      or{' '}
                      <Tag>
                        <ArrowRightOutlined />
                      </Tag>
                      to translate <b>analyte zone</b> (press <Tag>Ctrl</Tag>for precise control)
                    </p>
                  </div>
                }
              >
                <FontAwesomeIcon icon="info-circle" className="clickable-icon" style={{ color: arycolor.aryBlue }} />
              </Popover>
              <Popover
                style={{ display: 'flex' }}
                trigger={'hover'}
                placement="bottom"
                content={
                  <div>
                    <p>
                      <span>
                        Same analyte zone will be applied to all replicates of <b>this item</b>
                      </span>
                    </p>
                  </div>
                }
              >
                <FontAwesomeIcon icon="lock" className="clickable-icon" />
              </Popover>
              <Popover
                style={{ display: 'flex' }}
                trigger={'hover'}
                content={
                  <div>
                    <p>Click to enable keyboard features!</p>
                  </div>
                }
              >
                <FullscreenExitOutlined
                  style={{
                    cursor: 'pointer',
                    fontSize: '14pt',
                  }}
                />
              </Popover>
            </Space>
            <Space>
              <Typography.Title level={5}>{currentItemName}</Typography.Title>
            </Space>
            <Space>
              <ArrowsAltOutlined className="clickable-icon" onClick={() => setIsVisibleModal(true)} />
            </Space>
          </div>
        )}
        <Plot
          divId="sensogram_scatter_plot"
          debug={true}
          data={plotlyData}
          layout={plotlyLayout}
          config={plotlyConfig}
          style={defaultPlotlyArguments.style}
          useResizeHandler={true}
          onSelected={(e) => {
            if (e.range === undefined) {
              return;
            }
            if (records === undefined) {
              return;
            }

            let range = e.range.x;
            let [startTime, endTime] = range;
            let startIdx = Math.round(startTime * 3);
            let endIdx = Math.round(endTime * 3);

            mutateBoundaries(startIdx, endIdx);
          }}
        />
        <FullscreenGraphicModal title={currentItemName} visible={isVisibleModal} onCancel={() => setIsVisibleModal(false)}>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%', marginTop: '10px' }}>
            <Plot
              divId="sensogram_scatter_plot"
              debug={true}
              data={plotlyData}
              layout={plotlyLayout}
              config={plotlyConfig}
              style={defaultPlotlyArguments.style}
              useResizeHandler={true}
              onSelected={(e) => {
                if (e.range === undefined) {
                  return;
                }
                if (records === undefined) {
                  return;
                }

                let range = e.range.x;
                let [startTime, endTime] = range;
                let startIdx = Math.round(startTime * 3);
                let endIdx = Math.round(endTime * 3);

                mutateBoundaries(startIdx, endIdx);
              }}
            />
            <RecordsLegend />
          </div>
        </FullscreenGraphicModal>
      </div>
    </>
  );
};
