import { FC, useEffect, useState } from 'react';
import Layout from 'antd/lib/layout/layout';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Security, LoginCallback, SecureRoute, useOktaAuth } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { AppMenu } from './components/menu/AppMenu';
import AppHeader from './components/menu/AppHeader';
import AppContent from './components/menu/AppContent';
import PdfContainer from './routes/pdf/pdfContainer';
import TrialPage from './routes/trial';
import { fetchAuthorizedAPIEndpoint } from './utils';
import { FeatureFlag, UserClaimsWithTSDB } from './types/userType';

export const useMediaQuery = (query: string) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e: any) => {
      setMatches(e.matches);
    };
    mediaMatch.addEventListener('change', handler);
    return () => mediaMatch.removeEventListener('change', handler);
  }, []);
  return matches;
};

const App: FC = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const isRowBased = useMediaQuery('(max-width: 768px)');
  const { authState, oktaAuth } = useOktaAuth();

  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);
  const [licenseEndTs, setLicenseEndTs] = useState<number | null | undefined>(undefined);

  const history = useHistory();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    fetchAuthorizedAPIEndpoint('/user/get_trial_period', authState)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((data: { LicenseEndTs: number }) => {
        setLicenseEndTs(data.LicenseEndTs);
      });
  }, [authState, oktaAuth]);

  const userIsSubscribed = () => {
    if (licenseEndTs === null || licenseEndTs === undefined) {
      return false;
    }
    return licenseEndTs - Date.now() > 0;
  };

  if (userInfo === undefined || userInfo === null) {
    return null;
  }

  if (licenseEndTs === undefined) {
    return null;
  }

  if (!userInfo.feature_flags.includes(FeatureFlag.RoleAryballe) && !userIsSubscribed()) {
    history.push('/home');
  }

  return (
    <>
      {isRowBased ? (
        <Layout style={{ minHeight: '100vh', display: 'flex', flexDirection: 'row' }} hasSider>
          <AppMenu isCollapsed={true} setIsCollapsed={setIsCollapsed} />
          <Layout style={{ marginLeft: 0 }}>
            <AppHeader isCollapsed={true} />
            <AppContent />
          </Layout>
        </Layout>
      ) : (
        <Layout style={{ minHeight: '100vh', display: 'flex', flexDirection: 'row' }} hasSider>
          <AppMenu isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
          <Layout style={{ marginLeft: isCollapsed ? 60 : 240 }}>
            <AppHeader isCollapsed={isCollapsed} />
            <AppContent />
          </Layout>
        </Layout>
      )}
    </>
  );
};

const OKTAApp: FC = () => {
  const history = useHistory();

  const oktaAuth = new OktaAuth({
    issuer: 'https://auth.aryballe.com/oauth2/default',
    clientId: '0oa1e6sn0vPjqPS3W5d7',
    scopes: ['openid', 'email', 'profile', 'groups', 'multisource'],
    redirectUri: window.location.origin + '/signin/callback',
    postLogoutRedirectUri: window.location.origin + '/signout/callback',
  });

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    console.log('restoring original uri', originalUri);
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route path="/signin/callback" component={LoginCallback} />
        <Route path="/signout/callback">
          <Redirect to="/" />
        </Route>
        <Route path="/:sessionID/pdf" component={PdfContainer} />
        <Route path="/home" component={TrialPage} />
        <SecureRoute path="/" component={App} />
      </Switch>
    </Security>
  );
};

export default OKTAApp;
