import { Popover, Row, Skeleton, Typography } from 'antd';
import { useAppSelector } from '../../../app/hooks';
import CardSection from '../../../components/section/CardSection';
import { selectRecords } from '../../../features/analysisConfig/analysisConfigSlice';
import Device from '../../../assets/images/iconDevice.png';

const DashboardInfo: React.FC = () => {
  const records = useAppSelector(selectRecords);

  if (records === undefined) {
    return (
      <CardSection>
        <Row justify="center" align="middle">
          <Skeleton active />
        </Row>
      </CardSection>
    );
  }

  var uniqueRunIDs: Set<string> = new Set();
  var uniqueDeviceIDs: Set<string> = new Set();
  var uniqueItemNames: Set<string> = new Set();
  records.forEach((record) => {
    uniqueRunIDs.add(record.RunID);
    uniqueDeviceIDs.add(record.DeviceID);
    uniqueItemNames.add(record.ItemName);
  });

  return (
    <CardSection>
      <Row justify="space-between" align="top">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
          <Popover
            style={{ display: 'flex' }}
            trigger={'hover'}
            placement="top"
            content={
              <div>
                {Array.from(uniqueDeviceIDs).map((deviceID) => {
                  return (
                    <Typography.Text key={deviceID} copyable={true} style={{ marginLeft: '10px', display: 'block' }}>
                      {deviceID}
                    </Typography.Text>
                  );
                })}
              </div>
            }
          >
            <img src={Device} alt="" style={{ width: 'auto', height: 25, display: 'flex', margin: 'auto', marginBottom: 5 }} />
            <span style={{ fontWeight: 500 }}>Devices</span>
          </Popover>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span style={{ fontSize: 25, lineHeight: '25px', fontWeight: 'bold', margin: 'auto', marginBottom: 2, marginTop: 3 }}>{records.length}</span>
          <span style={{ fontWeight: 500, wordWrap: 'normal', textAlign: 'center' }}>Total records</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span style={{ fontSize: 25, lineHeight: '25px', fontWeight: 'bold', margin: 'auto', marginBottom: 2, marginTop: 3 }}>{uniqueItemNames.size}</span>
          <span style={{ fontWeight: 500, wordWrap: 'normal', textAlign: 'center' }}>Unique items</span>
        </div>
      </Row>
    </CardSection>
  );
};

export default DashboardInfo;
