import { MenuFoldOutlined, MenuUnfoldOutlined, ShrinkOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { Row, Skeleton, Space, Tooltip, Tree } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { useCallback, useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import CardSection from '../../../components/section/CardSection';
import { defaultColorPalette, getColormap, getColormapQualityControl } from '../../../compute/colormap';
import { getRecordNameShort, renderColoredTag, spots2peptides, getRecordNameOneLine } from '../../../compute/utils';
import {
  selectColormap,
  selectColormapRef,
  selectCurrentItemName,
  selectCurrentRecordID,
  selectExcludedFirstCycleParamState,
  selectExcludedRecordIDs,
  selectRecords,
  selectSubtractItemName,
  setColormap,
  setColormapRef,
  setExcludedFirstCycleParamState,
  setExcludedRecordIDs,
  setRecords,
} from '../../../features/analysisConfig/analysisConfigSlice';
import { AryRecord, ComparisonMeasuresType } from '../../../types/analysisTypes';
import { fetchAuthorizedAPIEndpoint } from '../../../utils';
import { FeatureFlag, UserClaimsWithTSDB } from '../../../types/userType';
import { useOktaAuth } from '@okta/okta-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arycolor } from '../../../assets/css/color';

type DashbordMeasuresProps = {
  setIsExpandedMenu: React.Dispatch<React.SetStateAction<boolean>>;
};
const DashbordMeasures: React.FC<DashbordMeasuresProps> = (props) => {
  const { authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);

  const { sessionID } = useParams<{ sessionID: string }>();

  const [isExpandedAll, setIsExpandedAll] = useState(true);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [filterText, setFilterText] = useState<string>('');

  const [isShowFullNames, setIsShowFullNames] = useState(false);

  const [recordsMap, setRecordsMap] = useState<Record<string, AryRecord[]>>({});
  const [treeData, setTreeData] = useState<Record<string, DataNode[]>>({});

  const dispatch = useAppDispatch();

  const currentRecordID = useAppSelector(selectCurrentRecordID);
  const currentItemName = useAppSelector(selectCurrentItemName);
  const excludedFirstCycleParamState = useAppSelector(selectExcludedFirstCycleParamState);
  const [isPeptideSetIncoherent, setIsPeptideSetIncoherent] = useState(false);

  const cmapRef = useAppSelector(selectColormapRef);
  const records = useAppSelector(selectRecords);

  const getNodeTitle = useCallback((record: AryRecord) => (isShowFullNames ? getRecordNameOneLine(record) : getRecordNameShort(record)), [isShowFullNames]);

  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const subtractedItemName = useAppSelector(selectSubtractItemName);

  // set user info
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState]);

  useEffect(() => {
    if (authState === null || !authState.accessToken) return;

    fetchAuthorizedAPIEndpoint(`/list_records?session_id=${sessionID}&ts=${Date.now()}`, authState)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw new Error();
        }
      })
      .then((records: AryRecord[]) => {
        if (records === null) {
          return;
        }

        var checkedIDs: number[] = [];
        records.forEach((record) => {
          if (!excludedRecordIDs.includes(record.ID)) {
            checkedIDs.push(record.ID);
          }
        });
        let checked: React.Key[] = checkedIDs;
        setCheckedKeys(checked);

        var _recordsMap = { ...recordsMap };
        _recordsMap['Reference'] = records.filter((r) => r.Tags.includes('$comparison-reference')).sort((a: any, b: any) => a.ItemName.localeCompare(b.ItemName));
        _recordsMap['Test'] = records.filter((r) => r.Tags.includes('$comparison-test')).sort((a: any, b: any) => a.ItemName.localeCompare(b.ItemName));
        _recordsMap['Other'] = records.filter((r) => !r.Tags.includes('$comparison-test') && !r.Tags.includes('$comparison-reference')).sort((a: any, b: any) => a.ItemName.localeCompare(b.ItemName));
        setRecordsMap(_recordsMap);

        const _cmap: Record<string, Record<string, string>> = {};
        Object.entries(ComparisonMeasuresType).forEach(([label, value]) => {
          _cmap[value] = getColormapQualityControl(Array.from(new Set(_recordsMap[label].map((r) => r.ItemName))), value);
        });
        dispatch(setColormapRef(_cmap));
        // const uniqueLabels = Array.from(new Set(records.map((r) => r.ItemName)));
        // uniqueLabels.sort();
        // let cmap = getColormap(uniqueLabels);
        // dispatch(setColormap(cmap));
        dispatch(setRecords(records));
      });
  }, [authState, sessionID, currentRecordID]);

  useEffect(() => {
    if (records === undefined || records === null) return;

    var checkedIDs: number[] = [];
    records.forEach((record) => {
      if (!excludedRecordIDs.includes(record.ID)) {
        checkedIDs.push(record.ID);
      }
    });
    let checked: React.Key[] = checkedIDs;
    setCheckedKeys(checked);
  }, [excludedRecordIDs]);

  useEffect(() => {
    if (records === undefined || records === null) return;
    if (excludedFirstCycleParamState === false) return;

    let uniqueRecords: Map<number, string> = new Map();
    records.forEach((r) => {
      if (!Array.from(uniqueRecords.values()).includes(r.ItemName)) uniqueRecords.set(r.ID, r.ItemName);
    });
    const firstCycleId = Array.from(uniqueRecords.keys());
    const newExludeRecordsId = Array.from(new Set([...excludedRecordIDs, ...firstCycleId]));
    dispatch(setExcludedRecordIDs(newExludeRecordsId));
  }, [dispatch, records, excludedFirstCycleParamState]);

  useEffect(() => {
    if (records === undefined || records === null) return;
    if (excludedFirstCycleParamState === true) return;
    let uniqueRecords: Map<number, string> = new Map();
    records.forEach((r) => {
      if (!Array.from(uniqueRecords.values()).includes(r.ItemName)) uniqueRecords.set(r.ID, r.ItemName);
    });

    if (Array.from(uniqueRecords.keys()).every((v) => excludedRecordIDs.includes(v))) dispatch(setExcludedFirstCycleParamState(true));
  }, [dispatch, excludedFirstCycleParamState, excludedRecordIDs, records]);

  useEffect(() => {
    // var _recordsMap = { ...recordsMap };
    var _treeData = { ...treeData };
    if (records === undefined || records.length === 0 || Object.keys(recordsMap).length === 0 || cmapRef === undefined) {
      return;
    }
    // _recordsMap['Reference'] = records.filter((r) => r.Tags.includes('$comparison-reference')).sort((a: any, b: any) => a.ItemName.localeCompare(b.ItemName));
    // _recordsMap['Test'] = records.filter((r) => r.Tags.includes('$comparison-test')).sort((a: any, b: any) => a.ItemName.localeCompare(b.ItemName));
    // _recordsMap['Other'] = records.filter((r) => !r.Tags.includes('$comparison-test') && !r.Tags.includes('$comparison-reference')).sort((a: any, b: any) => a.ItemName.localeCompare(b.ItemName));
    // setRecordsMap(_recordsMap);

    // Keep track of uniquePeptideSets
    var commonUniquePeptidesNb: number = 0;

    Object.entries(recordsMap).forEach(([label, _r]) => {
      const test: DataNode[] = [];
      const _cmap = cmapRef[label];
      _r.forEach((r) => {
        var recordTitle = getNodeTitle(r);
        var [peptideCodeStrs] = spots2peptides(r.Sensors);
        var uniquePeptideCodeStrsSet = new Set(peptideCodeStrs);
        let uniquePeptideCodeStrs = Array.from(uniquePeptideCodeStrsSet);

        if (commonUniquePeptidesNb === 0) commonUniquePeptidesNb = uniquePeptideCodeStrs.length;
        if (commonUniquePeptidesNb !== uniquePeptideCodeStrs.length) setIsPeptideSetIncoherent(true);

        var recordInfo = (
          <>
            <b>Timestamp:</b> {new Date(r.AbsoluteTimestamp * 1e3).toLocaleString()}
            <br />
            <b>Device:</b> {r.DeviceID}
            <br />
            <b>RunID:</b> {r.RunID}
            <br />
            <b>Peptides:</b> {uniquePeptideCodeStrs.length} on {r.Sensors.length} spots
          </>
        );
        var recordTitleNode = (
          <Tooltip placement="right" mouseLeaveDelay={0} mouseEnterDelay={0.1} overlay={recordInfo} overlayInnerStyle={{ minWidth: '300px' }}>
            {recordTitle}
            {r.ID === currentRecordID && <span style={{ width: 13, height: 13, marginLeft: 10, marginBottom: -2, borderRadius: '50%', background: _cmap[r.ItemName], display: 'inline-block' }}></span>}
          </Tooltip>
        );
        var isRecordOutfiltered = false;
        if (filterText !== '') {
          let filterRe = new RegExp(filterText, 'gi');
          if (!recordTitle.match(filterRe)) {
            isRecordOutfiltered = true;
          }
        }

        for (let i = 0; i < test.length; i++) {
          let node = test[i];
          if (node.key === r.ItemName) {
            if (node.children === undefined) {
              node.children = [];
            }
            node.children.push({
              style: isRecordOutfiltered ? { display: 'none' } : {},
              key: r.ID,
              className: !userInfo?.feature_flags.includes(FeatureFlag.AALightViewQualityControl) ? `checkbox-${_cmap[r.ItemName]}` : '',
              title:
                filterText === '' ? (
                  recordTitleNode
                ) : (
                  <Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }} searchWords={[filterText]} autoEscape textToHighlight={recordTitle}>
                    {recordTitleNode}
                  </Highlighter>
                ),
            } as DataNode);
            return;
          }
        }

        test.push({
          title: (
            <div>
              {renderColoredTag(
                _cmap[r.ItemName],
                <>
                  {r.ItemName}
                  {subtractedItemName === r.ItemName && (
                    <Tooltip
                      overlay={
                        <>
                          Average sensogram of this item (its selected records) is subtracted from senosograms of other items
                          <br />
                          <br />
                          This item is excluded from signature analysis
                        </>
                      }
                    >
                      <VerticalAlignBottomOutlined />
                    </Tooltip>
                  )}
                </>
              )}
            </div>
          ),
          className: !userInfo?.feature_flags.includes(FeatureFlag.AALightViewQualityControl) ? `checkbox-${_cmap[r.ItemName]}` : '',
          key: r.ItemName,
          children: [
            {
              key: r.ID,
              style: isRecordOutfiltered ? { display: 'none' } : {},
              className: !userInfo?.feature_flags.includes(FeatureFlag.AALightViewQualityControl) ? `checkbox-${_cmap[r.ItemName]}` : '',
              title:
                filterText === '' ? (
                  recordTitleNode
                ) : (
                  <Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }} searchWords={[filterText]} autoEscape textToHighlight={recordTitle}>
                    {recordTitleNode}
                  </Highlighter>
                ),
            } as DataNode,
          ],
        });
      });
      _treeData[label] = test;
    });
    setTreeData(_treeData);
  }, [sessionID, filterText, isShowFullNames, cmapRef, subtractedItemName, currentRecordID, currentItemName, recordsMap]);

  const updateFirstCycle = () => {
    if (records === undefined || records === null) return;
    if (excludedFirstCycleParamState === false) dispatch(setExcludedFirstCycleParamState(true));
    else {
      dispatch(setExcludedFirstCycleParamState(false));
      let uniqueRecords: Map<number, string> = new Map();
      records.forEach((r) => {
        if (!Array.from(uniqueRecords.values()).includes(r.ItemName)) uniqueRecords.set(r.ID, r.ItemName);
      });
      dispatch(setExcludedRecordIDs(excludedRecordIDs.filter((id) => !Array.from(uniqueRecords.keys()).includes(id))));
    }
  };

  if (records === undefined || records.length === 0 || Object.keys(recordsMap).length === 0) {
    return (
      <CardSection flexGrow="1" scroll="auto" marginBottom="2%">
        <Row justify="space-between" style={{ marginTop: -10 }}>
          <p style={{ fontWeight: 500, marginBottom: 10 }}>Measurements</p>
          <ShrinkOutlined className="clickable-icon" onClick={() => props.setIsExpandedMenu(false)} />
        </Row>
        <Row align="middle" justify="center" style={{ paddingTop: 100 }}>
          <Skeleton active />
          <Skeleton active />
        </Row>
      </CardSection>
    );
  }

  const uniqueItemNames = Array.from(new Set(records.map((r) => r.ItemName)));
  uniqueItemNames.sort();

  return (
    <>
      {Object.values(ComparisonMeasuresType).map((label) => (
        <CardSection flexGrow="1" scroll="auto" marginBottom="2%" height="30%">
          <Row justify="space-between" style={{ marginTop: -10 }}>
            <p style={{ fontWeight: 500, marginBottom: 10 }}>{label}</p>
            {label === ComparisonMeasuresType.Reference && <ShrinkOutlined className="clickable-icon" onClick={() => props.setIsExpandedMenu(false)} />}
          </Row>
          <Space direction="vertical">
            {label === ComparisonMeasuresType.Reference && (
              <Space align="center">
                {!isExpandedAll ? (
                  <Tooltip overlay="Collapse all">
                    <MenuUnfoldOutlined
                      className="clickable-icon"
                      style={{ display: 'flex' }}
                      onClick={() => {
                        setExpandedKeys([]);
                        setIsExpandedAll(true);
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip overlay="Expand all">
                    <MenuFoldOutlined
                      className="clickable-icon"
                      style={{ display: 'flex' }}
                      onClick={() => {
                        setExpandedKeys(uniqueItemNames);
                        setIsExpandedAll(false);
                      }}
                    />
                  </Tooltip>
                )}
                <Tooltip title="Include 1st cycle">
                  <FontAwesomeIcon
                    icon="arrows-spin"
                    className="clickable-icon"
                    style={{ marginLeft: 5, color: excludedFirstCycleParamState ? arycolor.aryBlue : arycolor.aryGreyDark, cursor: userInfo && userInfo.feature_flags.includes(FeatureFlag.AALightViewQualityControl) ? 'not-allowed' : 'pointer' }}
                    onClick={() => updateFirstCycle()}
                  />
                </Tooltip>
              </Space>
            )}

            <Tree
              checkable={true}
              showIcon={false}
              expandedKeys={expandedKeys}
              onExpand={(keys) => {
                setExpandedKeys(keys);
              }}
              disabled={userInfo?.feature_flags.includes(FeatureFlag.AALightViewQualityControl)}
              checkedKeys={checkedKeys}
              onCheck={(currentlyCheckedKeys) => {
                var _keys = currentlyCheckedKeys as React.Key[];
                const test = recordsMap[label].map((r) => r.ID);
                var _excludedRecordIDs: number[] = excludedRecordIDs.filter((r) => !test.includes(r));
                let uniqueRecords: Map<number, string> = new Map();

                setCheckedKeys(_keys);
                recordsMap[label].forEach((record) => {
                  let key = record.ID;
                  if (!_keys.includes(key)) {
                    _excludedRecordIDs.push(record.ID);
                  }
                  if (!Array.from(uniqueRecords.values()).includes(record.ItemName)) uniqueRecords.set(record.ID, record.ItemName);
                });
                dispatch(setExcludedRecordIDs(Array.from(new Set(_excludedRecordIDs))));
                if (excludedFirstCycleParamState === true) {
                  if (Array.from(uniqueRecords.keys()).some((id) => !_excludedRecordIDs.includes(id))) dispatch(setExcludedFirstCycleParamState(false));
                }
              }}
              treeData={treeData[label]}
            />
          </Space>
        </CardSection>
      ))}
    </>
  );
};

export default DashbordMeasures;
