import { InfoCircleOutlined, LinkOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOktaAuth } from '@okta/okta-react';
import { Badge, Col, Popover, Row, Typography } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '../../App';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { renderTimeDuration, renderTimestamp } from '../../compute/utils';
import { selectSessionID } from '../../features/analysisConfig/analysisConfigSlice';
import { selectCrumbs, selectVisibleSettings, setVisibleSettings } from '../../features/analysisConfig/sessionInfoSlice';
import { fetchAuthorizedAPIEndpoint } from '../../utils';
import { AppRoute, getTrueDashboardRoute } from '../../routes';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import Exports from './Exports';
import ShareSession from './ShareSession';
import UserInfoWidget from './UserInfoWidget';
import HelpWidget from './HelpWidget';
import { Session } from '../../types/sessionType';
import { UserClaimsWithTSDB } from '../../types/userType';

type AppHeaderProps = {
  isCollapsed: boolean;
};

const SessionHeader: React.FC = () => {
  const sessionID = useAppSelector(selectSessionID);
  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);
  const { oktaAuth, authState } = useOktaAuth();
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    if (userInfo === null) {
      return;
    }
    fetchAuthorizedAPIEndpoint(`/session?session_id=${sessionID}`, authState)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((session: Session) => {
        setSession(session);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [sessionID, userInfo]);

  if (userInfo === null || sessionID === null || session === null) {
    return null;
  }

  const userIsTheAuthor = () => {
    return userInfo.sub === session.User.ID;
  };

  const handleUpdateLockSession = (isLocked: boolean) => {
    if (!userIsTheAuthor()) {
      return;
    }
    fetchAuthorizedAPIEndpoint(`/session/update_lock?session_id=${sessionID}&value=${isLocked}`, authState)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((session: Session) => {
        setSession(session);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleUpdateDescriptionSession = (value: string) => {
    if (!userIsTheAuthor()) {
      return;
    }
    fetchAuthorizedAPIEndpoint(`/session/update_description?session_id=${sessionID}&value=${encodeURIComponent(value)}`, authState)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((session: Session) => {
        setSession(session);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const renderedLock = (
    <>
      {session.IsLocked ? (
        <FontAwesomeIcon icon="lock" className="clickable-icon" style={{ fontSize: '16px' }} onClick={() => handleUpdateLockSession(false)} />
      ) : (
        <FontAwesomeIcon icon="lock-open" className="clickable-icon" style={{ fontSize: '16px', color: '#5a5a5a' }} onClick={() => handleUpdateLockSession(true)} />
      )}
    </>
  );

  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <Typography.Title level={5} style={{ margin: 0, marginTop: '3px' }} copyable={{ text: window.location.href, icon: <LinkOutlined style={{ color: '#5a5a5a', marginLeft: '7px' }} color="#5a5a5a" />, tooltips: ['Copy link', 'Link copied!'] }}>
        {sessionID}
      </Typography.Title>
      <span style={{ width: '10px' }}></span>
      <Popover
        trigger="click"
        content={
          <>
            <b>Author:</b> {session.User.GivenName} {session.User.FamilyName} (<a href={`mailto:${session.User.Email}`}>{session.User.Email}</a>) {userIsTheAuthor() ? `(it's you)` : null}
            <br />
            <b>Description: </b> <Typography.Text editable={{ onChange: handleUpdateDescriptionSession }}>{session.Description}</Typography.Text>
            <br />
            <b>Created at:</b> {renderTimestamp(session.CreatedAt)}
            <br />
            <b>Last updated:</b> {renderTimeDuration(new Date(session.UpdatedAt), new Date())}
          </>
        }
      >
        <InfoCircleOutlined style={{ fontSize: '16px' }} />
      </Popover>
      <span style={{ width: '10px' }}></span>
      {userIsTheAuthor() ? (
        <Popover
          placement="bottom"
          trigger={'hover'}
          content={
            <div style={{ textAlign: 'left', maxWidth: '500px' }}>
              Changes made on <b>locked</b> sessions will <b>not be saved</b>.
              <br />
              Next time you open this session it will be in the last state before the Lock
              <br />
              <br />
              <b>Note:</b> only sessoin owners can lock/unlock sessions
              <br />
              <b>Note:</b> unlocked sessions can be modified by anyone in your organisation who has the link
            </div>
          }
        >
          <Badge>{renderedLock}</Badge>
        </Popover>
      ) : (
        <Popover
          content={
            <div style={{ textAlign: 'center' }}>
              You cannot modify the session as it belongs to
              <br />
              <b>
                {session.User.GivenName} {session.User.FamilyName} (<a href={`mailto:${session.User.Email}`}>{session.User.Email}</a>)
              </b>
              <br />
              Please contact the owner of the session or duplicate it to create your own!
            </div>
          }
        >
          <Badge dot>{renderedLock}</Badge>
        </Popover>
      )}
    </div>
  );
};

const AppHeader: React.FC<AppHeaderProps> = ({ isCollapsed }) => {
  const crumbs = useAppSelector(selectCrumbs);
  const sessionID = useAppSelector(selectSessionID);
  const isRowBased = useMediaQuery('(max-width: 768px)');
  const visibleSettings = useAppSelector(selectVisibleSettings);
  const location = useLocation();
  const dispatch = useAppDispatch();

  return (
    <Header style={{ background: 'white', height: 50, lineHeight: '50px', padding: '0 30px', position: 'fixed', width: isRowBased ? '100%' : `calc(100% - ${isCollapsed ? '60px' : '240px'})`, zIndex: 1001, boxShadow: '0px 1px 4px #00000010' }}>
      <Row justify="space-between" style={{ height: '100%' }}>
        <Col span={2} md={8} style={{ height: '100%' }}>
          {isRowBased ? <img alt="logo" style={{ width: ' 30px', lineHeight: '30px' }} src="/logo64.png" /> : <Breadcrumb crumbs={crumbs} />}
        </Col>
        {location.pathname === getTrueDashboardRoute(sessionID, AppRoute.ANALYSE) && (
          <Col span={14} md={8} style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <SessionHeader />
          </Col>
        )}
        {location.pathname === AppRoute.DASHBOARD && (
          <Col span={14} md={8} style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img alt="logo" style={{ width: ' 30px', lineHeight: '30px' }} src="/logo64.png" />
            <h1 style={{ fontSize: 18, margin: 0, marginLeft: 10 }}>Digital Olfaction Hub</h1>
          </Col>
        )}
        <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%' }}>
          {location.pathname === getTrueDashboardRoute(sessionID, AppRoute.ANALYSE) && (
            <FontAwesomeIcon onClick={() => dispatch(setVisibleSettings(!visibleSettings))} icon="cogs" style={{ width: 20, height: 20, cursor: 'pointer', marginRight: 10 }} />
          )}
          {sessionID && <Exports sessionID={sessionID} />}
          {location.pathname === getTrueDashboardRoute(sessionID, AppRoute.ANALYSE) && <ShareSession sessionID={sessionID} />}
          <HelpWidget />
          <UserInfoWidget />
        </Col>
      </Row>
    </Header>
  );
};

export default AppHeader;
