import { Col, Row, Skeleton } from 'antd';
import { FC } from 'react';

export const TableSkeleton: FC = () => {
  return (
    <Row justify="center" style={{ width: '100%' }}>
      {/* <Skeleton active={true} /> */}
      <Skeleton.Input active={true} block={true} />
      <Row justify="space-between" style={{ width: '100%', marginBottom: 10, marginTop: 10 }}>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
      </Row>
      <Row justify="space-between" style={{ width: '100%', marginBottom: 10, marginTop: 10 }}>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
      </Row>
      <Row justify="space-between" style={{ width: '100%', marginBottom: 10, marginTop: 10 }}>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
      </Row>
      <Row justify="space-between" style={{ width: '100%', marginBottom: 10, marginTop: 10 }}>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
      </Row>
      <Row justify="space-between" style={{ width: '100%', marginBottom: 10, marginTop: 10 }}>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
        <Col span={5}>
          <Skeleton.Button active={true} size="small" shape="round" block={true} />
        </Col>
      </Row>
    </Row>
  );
};
