import { CloudOutlined, CloudUploadOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOktaAuth } from '@okta/okta-react';
import { Badge, Button, Col, Input, InputRef, message, Popconfirm, Row, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { FC, useEffect, useRef, useState } from 'react';
import SectionPage from '../components/section/SectionPage';
import { defaultColorPalette, getColormap } from '../compute/colormap';
import { getPeptideSetType, renderColoredTag, renderPeptideSetType, renderTimeDuration, renderTimestamp } from '../compute/utils';
import { Session, SessionUser } from '../types/sessionType';
import { PeptideSet } from '../types/analysisTypes';
import { UserClaimsWithTSDB } from '../types/userType';
import { fetchAuthorizedAPIEndpoint } from '../utils';
import { Source, SourceType } from '../types/runsType';

function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
}

const UserSessionsTable: FC<{ isSharedSessions: boolean }> = ({ isSharedSessions }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);

  const [sessions, setSessions] = useState<Session[]>([]);

  const searchInput = useRef<InputRef>(null);
  const [dataSource, setDataSource] = useState<DataType[]>([]);

  const [updateIncrement, setUpdateIncrement] = useState(0);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [uniqueSourcesName, setUniqueSourcesName] = useState<string[]>([]);
  const [uniquePeptidesType, setUniquePeptidesType] = useState<PeptideSet[]>([]);
  const [uniqueItems, setUniqueItems] = useState<string[]>([]);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    let path: string = `/user_sessions`;
    if (isSharedSessions) {
      path = '/user_shared_sessions';
    }
    fetchAuthorizedAPIEndpoint(path, authState)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((_sessions: Session[]) => {
        if (_sessions !== null && _sessions !== undefined) {
          setSessions(_sessions.filter((session) => !session.ID.includes('CFG')));
        }
      });
  }, [userInfo, updateIncrement]);

  useEffect(() => {
    var uniqueSetSourcesName: Set<string> = new Set();
    var uniqueSetPeptidesType: Set<PeptideSet> = new Set();
    var uniqueSetItems: Set<string> = new Set();

    const _dataSource: DataType[] = sessions.map((session) => {
      var nbRecords: number = 0;
      var uniqueItemNames: Set<string> = new Set();
      var uniqueSources: Record<string, Source> = {};
      var peptideSetTypes: Set<PeptideSet> = new Set();

      session.Runs.forEach((run) => {
        nbRecords += run.RecordsCount;
        uniqueSources[run.Source.Name] = run.Source;

        uniqueSetSourcesName.add(run.Source.Name);
        run.ItemNames.forEach((itemName) => {
          uniqueItemNames.add(itemName);
          uniqueSetItems.add(itemName);
        });
        if (run.Device && run.Device.Info && run.Device.Info.SpotsGrid) {
          peptideSetTypes.add(getPeptideSetType(run.Device.Info.SpotsGrid));
          uniqueSetPeptidesType.add(getPeptideSetType(run.Device.Info.SpotsGrid));
        }
      });

      return {
        key: session.ID,
        id: session.ID,
        user: session.User,
        description: session.Description,
        createdAt: session.CreatedAt,
        updatedAt: session.UpdatedAt,
        isLocked: session.IsLocked,
        items: Array.from(uniqueItemNames),
        sources: Object.values(uniqueSources),
        runs: session.Runs.map((run) => ({
          id: run.ID,
          name: run.Name,
        })),
        totalRecords: nbRecords,
        peptideSetTypes: Array.from(peptideSetTypes),
        diskSize: session.DiskSize,
      };
    });
    setDataSource(_dataSource);
    setUniqueSourcesName(Array.from(uniqueSetSourcesName));
    setUniquePeptidesType(Array.from(uniqueSetPeptidesType));
    setUniqueItems(Array.from(uniqueSetItems));
  }, [sessions]);

  const getColumnSearchProps = (dataIndex: keyof DataType, isTagged: boolean): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input ref={searchInput} placeholder={`Search ${dataIndex}`} value={selectedKeys[0]} onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => confirm()} style={{ marginBottom: 8, display: 'block' }} />
        <Space>
          <Button type="primary" onClick={() => confirm()} size="small" style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              setSelectedKeys([]);
              confirm({ closeDropdown: true });
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (dataIndex === 'runs') {
        return record[dataIndex]
          .map((r) =>
            r.name
              .toString()
              .toLowerCase()
              .includes((value as string).toLowerCase())
          )
          .includes(true);
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const handleDeleteSession = (sessionID: string) => {
    return () => {
      fetchAuthorizedAPIEndpoint(`/session/delete?session_id=${sessionID}`, authState)
        .then((resp) => {
          if (resp.ok) {
            return resp.text();
          } else {
            throw resp.json();
          }
        })
        .then(() => {
          setUpdateIncrement(updateIncrement + 1);
        })
        .then(() => {
          message.success(
            <>
              Session <b>{sessionID}</b> was successfully removed!
            </>
          );
        })
        .catch((e) => {
          console.log(e);
        });
    };
  };

  const handleLockSession = (sessionID: string) => {
    return (value: boolean) => {
      fetchAuthorizedAPIEndpoint(`/session/update_lock?session_id=${sessionID}&value=${value}`, authState)
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            throw resp.json();
          }
        })
        .then(() => {
          setUpdateIncrement(updateIncrement + 1);
        })
        .then(() => {
          message.success(
            <>
              Successfully {value ? 'locked' : 'unlocked'} session <b>{sessionID}</b>!
            </>
          );
        })
        .catch((e) => {
          console.log(e);
        });
    };
  };

  const handleSaveSpaceSession = (sessionID: string) => {
    return () => {
      fetchAuthorizedAPIEndpoint(`/session/delete_dataset?session_id=${sessionID}`, authState)
        .then((resp) => {
          if (resp.ok) {
            return resp.text();
          } else {
            throw resp.json();
          }
        })
        .then(() => {
          setUpdateIncrement(updateIncrement + 1);
        })
        .then(() => {
          message.success(
            <>
              Successfully saved space for session <b>{sessionID}</b>!
            </>
          );
        })
        .catch((e) => {
          console.log(e);
        });
    };
  };

  const handleUpdateDescriptionSession = (sessionID: string) => {
    return (value: string) => {
      if (value === 'empty') {
        return;
      }
      fetchAuthorizedAPIEndpoint(`/session/update_description?session_id=${sessionID}&value=${encodeURIComponent(value)}`, authState)
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            throw resp.json();
          }
        })
        .then(() => {
          setUpdateIncrement(updateIncrement + 1);
        })
        .catch((e) => {
          console.log(e);
        });
    };
  };

  const sourcesAreFromTSDB = (sources: DataType['sources']) => {
    for (let i = 0; i < sources.length; i++) {
      const source = sources[i];
      if (source.Type !== SourceType.TSDB) {
        return false;
      }
    }
    return true;
  };

  interface DataType {
    key: string;
    id: string;
    user: SessionUser;
    description: string;
    isLocked: boolean;
    createdAt: string;
    updatedAt: string;
    totalRecords: number;
    runs: {
      id: string;
      name: string;
    }[];
    items: string[];
    sources: Source[];
    peptideSetTypes: PeptideSet[];
    diskSize: number;
  }

  var columns: ColumnsType<DataType> = [
    {
      title: 'Session',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render(value) {
        return (
          <a rel="noreferrer" target="_blank" href={`/dashboard/${value}`}>
            {value}
          </a>
        );
      },
      ...getColumnSearchProps('id', false),
    },
    {
      title: 'Lock',
      dataIndex: 'isLocked',
      key: 'isLocked',
      sorter: (a, b) => (a.isLocked > b.isLocked ? 1 : -1),
      render(value, record) {
        let lock = value ? (
          <FontAwesomeIcon
            icon="lock"
            className="clickable-icon"
            style={{ fontSize: '16px' }}
            onClick={() => {
              if (userInfo && userInfo.sub === record.user.ID) {
                handleLockSession(record.id)(false);
              }
            }}
          />
        ) : (
          <FontAwesomeIcon
            icon="lock-open"
            className="clickable-icon"
            style={{ fontSize: '16px', color: '#91d5ff' }}
            onClick={() => {
              if (userInfo && userInfo.sub === record.user.ID) {
                handleLockSession(record.id)(true);
              }
            }}
          />
        );
        return isSharedSessions ? <Badge dot>{lock}</Badge> : lock;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render(value: string, record) {
        const editable = !isSharedSessions
          ? {
              onChange: (value: string) => {
                if (userInfo && userInfo.sub === record.user.ID) {
                  handleUpdateDescriptionSession(record.id)(value);
                }
              },
            }
          : false;
        let descr = value ? (
          <Typography.Text style={{ maxWidth: '150px', width: '150px' }} ellipsis={{ tooltip: value }} editable={editable}>
            {value}
          </Typography.Text>
        ) : (
          <Typography.Text italic type="secondary" editable={editable}>
            empty
          </Typography.Text>
        );
        return isSharedSessions ? <Badge dot>{descr}</Badge> : descr;
      },
      sorter: (a, b) => (a.description > b.description ? 1 : -1),
      ...getColumnSearchProps('description', false),
    },
    {
      title: 'Total records',
      dataIndex: 'totalRecords',
      sorter: (a, b) => (a.totalRecords > b.totalRecords ? 1 : -1),
    },
    {
      title: 'Runs',
      dataIndex: 'runs',
      key: 'runs',
      ...getColumnSearchProps('runs', false),
      render(value: DataType['runs']) {
        return (
          <div style={{ overflow: 'scroll', maxHeight: '150px' }}>
            {value.map((r) => (
              <Typography.Text style={{ maxWidth: '150px' }} ellipsis={{ tooltip: `${r.id}: ${r.name}` }}>
                {r.name}
              </Typography.Text>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Items',
      dataIndex: 'items',
      render(value: DataType['items']) {
        const cmap = getColormap(value);
        return (
          <div style={{ overflow: 'scroll', maxHeight: '150px' }}>
            {value.map((itemName) => {
              return renderColoredTag(defaultColorPalette[cmap[itemName]], <>{itemName}</>);
            })}
          </div>
        );
      },
      filterSearch: true,
      filters: uniqueItems.map((e) => {
        return { text: e, value: e };
      }),
      onFilter: (value, dataSource) => {
        return dataSource.items.map((item) => item.toLowerCase().includes((value as string).toLowerCase())).includes(true);
      },
    },
    {
      title: 'Sources',
      dataIndex: 'sources',
      filterSearch: true,
      filters: uniqueSourcesName.map((e) => {
        return { text: e.toUpperCase(), value: e };
      }),
      onFilter: (value, dataSource) => {
        return dataSource.sources.map((s) => s.Name.includes(value as string)).includes(true);
      },
      render(value: DataType['sources']) {
        return (
          <>
            {value.map((source) => {
              return (
                <Tag
                  color={(function () {
                    switch (source.Type) {
                      case 0:
                        return 'cyan';
                      case 1:
                        return 'magenta';
                      case 2:
                        return 'geekblue';
                    }
                  })()}
                >
                  {source.Name.toUpperCase()}
                </Tag>
              );
            })}
          </>
        );
      },
    },
    {
      title: 'Flags',
      dataIndex: 'peptideSetTypes',
      filters: uniquePeptidesType.map((e) => {
        return { text: renderPeptideSetType(e), value: e };
      }),
      onFilter: (value, dataSource) => {
        return dataSource.peptideSetTypes.map((type) => type === value).includes(true);
      },
      render(value: DataType['peptideSetTypes']) {
        return (
          <>
            {value.map((peptideSetType) => {
              return renderPeptideSetType(peptideSetType);
            })}
          </>
        );
      },
    },
    {
      title: 'Date/Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1),
      render(value) {
        return renderTimestamp(value);
      },
    },
    {
      title: 'Last update',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: (a, b) => (new Date(a.updatedAt) > new Date(b.updatedAt) ? 1 : -1),
      render(value) {
        return renderTimeDuration(new Date(), new Date(value));
      },
    },
    {
      title: 'Size on disk',
      dataIndex: 'diskSize',
      sorter: (a, b) => (a.diskSize > b.diskSize ? 1 : -1),
      render(value: DataType['diskSize']) {
        if (value === 0) {
          return (
            <Tooltip overlay={<div style={{ textAlign: 'center' }}>This session will be loaded on request</div>}>
              <CloudOutlined style={{ color: defaultColorPalette['blue'], fontSize: '12pt' }} />
            </Tooltip>
          );
        }
        return (
          <Tooltip
            overlay={
              <div style={{ textAlign: 'center' }}>
                This session is cached and is available immediately
                <br />
                It occupies some space on the disk though..
              </div>
            }
          >
            {nFormatter(value, 0)}
          </Tooltip>
        );
      },
    },
  ];

  if (!isSharedSessions) {
    columns = [
      ...columns,
      {
        title: 'Actions',
        render(value, record) {
          return (
            <Space>
              <Tooltip
                overlay={
                  <div style={{ textAlign: 'center' }}>
                    Remove session
                    {sourcesAreFromTSDB(record.sources) && (
                      <>
                        <br />
                        (but not the underlyong data)
                      </>
                    )}
                  </div>
                }
              >
                <Popconfirm
                  title={
                    <>
                      Are you sure you want to delete this session?
                      <br />
                      <b>Note</b>{' '}
                      {sourcesAreFromTSDB(record.sources) ? (
                        <>that the underlying data won't be deleted, just the reference</>
                      ) : (
                        <>
                          that underlying data <b>cannot be restored</b> as the data comes from <b>local storage</b>
                        </>
                      )}
                    </>
                  }
                  okText="Yes"
                  cancelText="No"
                  placement="left"
                  onConfirm={handleDeleteSession(record.id)}
                >
                  <DeleteOutlined style={{ color: 'red', fontSize: '12pt' }} />
                </Popconfirm>
              </Tooltip>
              {record.diskSize > 0 && sourcesAreFromTSDB(record.sources) && (
                <Tooltip overlay={<div style={{ textAlign: 'center' }}>Save space</div>}>
                  <Popconfirm
                    title={
                      <>
                        Are you sure you want to save space
                        <br />
                        by removing the immediately-available session cache?
                        <br />
                        <b>Note</b> that cache will be restored next time you access the session
                      </>
                    }
                    okText="Yes"
                    cancelText="No"
                    placement="left"
                    onConfirm={handleSaveSpaceSession(record.id)}
                  >
                    <CloudUploadOutlined style={{ fontSize: '12pt' }} />
                  </Popconfirm>
                </Tooltip>
              )}
            </Space>
          );
        },
      },
    ];
  }

  if (isSharedSessions) {
    columns = [
      {
        title: 'Owner',
        dataIndex: 'user',
        render(value: DataType['user']) {
          return (
            <>
              {value.FamilyName} {value.GivenName}
              <br />
              <span style={{ color: '#666666' }}>({value.Email})</span>
            </>
          );
        },
      },
      ...columns,
    ];
  }

  return (
    <>
      <Table
        size="small"
        style={{ width: '100%' }}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: '100%' }}
        pagination={{
          pageSize: 10,
        }}
        footer={() => {
          return (
            <>
              Total sessions: <b>{sessions.length}</b>
              <br />
              Total size on disk:{' '}
              {nFormatter(
                sessions.reduce((acc, cur) => (acc += cur.DiskSize), 0),
                1
              )}
            </>
          );
        }}
        expandable={{
          expandedRowRender: (record) => {
            let value = record.items;
            const cmap = getColormap(value);
            return (
              <div style={{ maxWidth: '80vh' }}>
                {value.map((itemName) => {
                  return renderColoredTag(defaultColorPalette[cmap[itemName]], <>{itemName}</>);
                })}
              </div>
            );
          },
        }}
        rowSelection={
          !isSharedSessions
            ? {
                selectedRowKeys: selectedKeys,
                onChange(selectedRowKeys, selectedRows) {
                  setSelectedKeys(selectedRowKeys);
                },
              }
            : undefined
        }
      />
      {!isSharedSessions &&
        selectedKeys.length > 0 &&
        (function () {
          return (
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Space>
                <Popconfirm
                  title={
                    <>
                      Are you sure you want to delete selected sessions?
                      <br />
                      <b>Note</b> that the underlying data stored in <b>Cloud Storage</b> won't be deleted, just the reference
                      <br />
                      <b>Note</b> that underlying data coming from <b>Local Storage</b> <b>cannot be restored</b> as the data comes from <b>local storage</b>
                    </>
                  }
                  okText="Yes"
                  cancelText="No"
                  onConfirm={async () => {
                    for (let i = 0; i < selectedKeys.length; i++) {
                      const sessionID = selectedKeys[i] as string;
                      await handleDeleteSession(sessionID)();
                    }
                    setSelectedKeys([]);
                  }}
                >
                  <Button type="primary" danger>
                    Delete selected sessions
                  </Button>
                </Popconfirm>
              </Space>
            </div>
          );
        })()}
    </>
  );
};

export const SessionsPage: FC = () => {
  return (
    <Row justify="center">
      <Col span={23} style={{ marginBottom: '60px' }}>
        <Row>
          <Col span={24}>
            <SectionPage>
              <Typography.Title level={4}>Your sessions</Typography.Title>
              <UserSessionsTable isSharedSessions={false} />
            </SectionPage>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <SectionPage>
              <Typography.Title level={4}>Shared with you</Typography.Title>
              <UserSessionsTable isSharedSessions={true} />
            </SectionPage>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
