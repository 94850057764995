import { Checkbox, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { FC } from 'react';
import { defaultColorPalette } from '../../compute/colormap';
import { renderColoredTag } from '../../compute/utils';
import { AnalysisConfig, AryRecord } from '../../types/analysisTypes';

export const RecordSummaryTable: FC<{ records: AryRecord[]; analysisConfig: AnalysisConfig; cmap?: Record<string, string> }> = (props) => {
  const { records, analysisConfig, cmap } = props;

  const columns: ColumnsType<AryRecord> = [
    {
      title: '',
      dataIndex: 'ID',
      key: 'isIncluded',
      render: (value, record) => {
        if (value === null || value === undefined) {
          return null;
        }
        let className: string = '';
        if (cmap !== undefined) {
          className = `checkbox-${cmap[record.ItemName]}`;
        }

        if (!analysisConfig.ExcludedRecordIDs) {
          return <Checkbox className={className} checked={true} />;
        }
        return <Checkbox className={className} checked={!analysisConfig.ExcludedRecordIDs.includes(value)} />;
      },
    },
    {
      title: '#',
      dataIndex: 'ID',
      key: 'ID',
    },
    {
      title: 'Run',
      dataIndex: 'RunID',
      key: 'RunID',
    },
    {
      title: 'Name',
      dataIndex: 'ItemName',
      key: 'ItemName',
      render: (value) => {
        let itemName = value;
        if (!cmap) {
          return <Tag>{itemName}</Tag>;
        }
        return renderColoredTag(defaultColorPalette[cmap[itemName]], <>{itemName}</>);
      },
    },
    {
      title: 'Cycle',
      dataIndex: 'CycleNumber',
      key: 'CycleNumber',
    },
    {
      title: 'Tags',
      dataIndex: 'Tags',
      key: 'Tags',

      render: (value: string[]) => {
        if (!value) {
          return '';
        }
        return (
          <>
            {value.map((tag) => (
              <Tag>{tag}</Tag>
            ))}
          </>
        );
      },
    },
    {
      title: 'Baseline zone, sec',
      dataIndex: '',
      render: (value, record) => {
        let boundaries = analysisConfig.BoundariesMap[record.ID];
        let boundariesSecStr = boundaries.map((v) => (v / 3).toFixed(1));
        return (
          <>
            {boundariesSecStr[0]} - {boundariesSecStr[1]}
          </>
        );
      },
    },
    {
      title: 'Analyte zone, sec',
      dataIndex: '',
      render: (value, record) => {
        let boundaries = analysisConfig.BoundariesMap[record.ID];
        let boundariesSecStr = boundaries.map((v) => (v / 3).toFixed(1));
        return (
          <>
            {boundariesSecStr[2]} - {boundariesSecStr[3]}
          </>
        );
      },
    },
  ];

  return <Table size="small" style={{ width: '100%' }} columns={columns} dataSource={records} pagination={false} scroll={{ x: '100%' }} />;
};
