import { Col, Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { MultiSignaturesFigure } from '../../components/graphic/signature/MultiSignaturesFigure';
import { Caption } from '../../components/summary/caption';
import { selectColormap } from '../../features/analysisConfig/analysisConfigSlice';
import { selectObservationsSignatures, setObservationsSignatures } from '../../features/analysisConfig/pdfSlice';
import { AryRecord, NormType, MultiSignaturesFigureType } from '../../types/analysisTypes';
import { useOktaOrQueryAuth } from '../../utils';
import Legend from './legend';
import Pagebreak from './pageBreak';

type SignaturesPdfProps = {
  effectiveRecords: AryRecord[];
};

const SignaturesPdf: React.FC<SignaturesPdfProps> = ({ effectiveRecords }) => {
  const cmap = useAppSelector(selectColormap);
  const observationsSignatures = useAppSelector(selectObservationsSignatures);

  const { sessionID } = useParams<{ sessionID: string }>();
  const { authState } = useOktaOrQueryAuth();

  return (
    <div id="signaturesPdf">
      <Row justify="start">
        <Col>
          <Typography.Title level={4}>1.2. Signatures</Typography.Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col sm={12} span={24}>
          <div style={{ width: '100%', aspectRatio: '1/1' }}>
            <MultiSignaturesFigure figureType={MultiSignaturesFigureType.Radar} normType={NormType.Normalized} heatmapRangeValue={[0, 1]} setHeatmapRangeValue={(value) => null} />
          </div>
        </Col>
        <Col sm={12} span={24}>
          <div style={{ width: '100%', aspectRatio: '1/1' }}>
            <MultiSignaturesFigure figureType={MultiSignaturesFigureType.Radar} normType={NormType.Measured} heatmapRangeValue={[0, 1]} setHeatmapRangeValue={(value) => null} />
          </div>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Legend effectiveRecords={effectiveRecords} cmap={cmap} />
        </Col>
      </Row>
      <Caption
        title="Fig 2"
        subtitle="Superposed odor signatures of measured samples. Left - normalized. Right - measured"
        setObservations={setObservationsSignatures}
        observations={observationsSignatures}
        sessionID={sessionID}
        authState={authState}
        name="observationsSignatures"
      />
      <Pagebreak />
    </div>
  );
};

export default SignaturesPdf;
