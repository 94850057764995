import ArrowsAltOutlined from '@ant-design/icons/lib/icons/ArrowsAltOutlined';
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Dropdown, Menu, Popover, Row, Space, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { arycolor } from '../../../assets/css/color';
import FullscreenGraphicModal from '../FullscreenGraphicModal';
import ConfusionMatrixPanel from './ConfusionMatrix';
import DistanceMatrixPanel from './DistanceMatrix';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../../utils';
import { selectHumidityCompensationCalibrantName, selectChemicalCalibrationItemNames } from '../../../features/analysisConfig/analysisConfigSlice';
import { useAppSelector } from '../../../app/hooks';

type MatrixProps = {
  sessionID: string;
};

enum MatrixFigureType {
  DistanceMatrix,
  ConfusionMatrix,
}

export type ModelsConfusionType = {
  Name: string;
  Label: string;
};

const Matrix: React.FC<MatrixProps> = ({ sessionID }) => {
  const [figureType, setFigureType] = useState<MatrixFigureType>(MatrixFigureType.DistanceMatrix);
  const [modelsConfusion, setModelsConfusion] = useState<ModelsConfusionType[] | undefined>();
  const [seletedModelsConfusion, setSelectedModelsConfusion] = useState<string | undefined>();
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const { authState } = useOktaOrQueryAuth();

  useEffect(() => {
    if (figureType !== MatrixFigureType.ConfusionMatrix) return;

    if (authState === null || !authState.accessToken) return;

    fetchAuthorizedAPIEndpoint(`/list_models`, authState)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((models: ModelsConfusionType[]) => {
        console.log(models);
        setModelsConfusion(models);
        setSelectedModelsConfusion(models[0].Name);
      });
  }, [authState, figureType]);

  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);
  const chemicalCalibrationItemNames = useAppSelector(selectChemicalCalibrationItemNames);

  return (
    <div tabIndex={0} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
      <Row justify="space-between" align="middle">
        <Col></Col>
        <Col>
          <Space align="center" style={{ display: 'flex', justifyContent: 'center' }}>
            <Dropdown
              overlay={
                <Menu
                  items={Object.entries(MatrixFigureType)
                    .filter(([_, value]) => typeof value === 'number')
                    .map(([label, value]) => {
                      return {
                        key: +value,
                        label: label,
                        onClick: () => {
                          setFigureType(+value);
                        },
                      };
                    })}
                />
              }
            >
              <Tooltip title="Chart type">
                <Button style={{ borderRadius: '5px' }}>
                  <Space>
                    {MatrixFigureType[figureType]}
                    <DownOutlined />
                  </Space>
                </Button>
              </Tooltip>
            </Dropdown>
            <Popover
              content={(function () {
                switch (figureType) {
                  case +MatrixFigureType.DistanceMatrix:
                    return (
                      <>
                        <b>Euclidean</b> distances between groups in <b>normalized signature space</b> (x100)
                        <br />
                        (mean inter-group distances are shown)
                        <br />
                        <br />
                        Distances are <b>comparable</b> to those between points in <b>PCA space</b>
                      </>
                    );
                  case +MatrixFigureType.ConfusionMatrix:
                    return (
                      <>
                        The confusion matrix indicates where a model might be getting confused <br /> when sorting measures into different categories (the predicted labels).
                      </>
                    );
                }
              })()}
            >
              <FontAwesomeIcon
                icon="info-circle"
                className="clickable-icon"
                style={{
                  color: arycolor.aryBlue,
                }}
              />
            </Popover>
            {figureType === MatrixFigureType.ConfusionMatrix && modelsConfusion && (
              <>
                <Dropdown
                  overlay={
                    <Menu
                      items={modelsConfusion.map((model) => {
                        return {
                          key: model.Name,
                          label: model.Label,
                          onClick: () => {
                            setSelectedModelsConfusion(model.Name);
                          },
                        };
                      })}
                    />
                  }
                >
                  <Tooltip title="Chart type">
                    <Button style={{ borderRadius: '5px' }}>
                      <Space>
                        {modelsConfusion.find((model) => model.Name === seletedModelsConfusion)?.Label}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Tooltip>
                </Dropdown>
                <Popover
                  content={(function () {
                    switch (seletedModelsConfusion) {
                      case modelsConfusion[0].Name:
                        return (
                          <>
                            In the clustering approach, the model will deduce groups of signatures, looking at their similarities.
                            <br />
                            To produce this confusion matrix, a GMM algorithm is used with a BIC criterion to choose optimal number of clusters.
                          </>
                        );
                      case modelsConfusion[1].Name:
                        return (
                          <>
                            The classification confusion matrix tells us how many times the model got it right (on the diagonal) <br /> and where it got mixed up (off the diagonal).
                            <br />
                            To produce this confusion matrix, a 2-fold cross-validation is used with an SVM algorithm.
                          </>
                        );
                    }
                  })()}
                >
                  <FontAwesomeIcon
                    icon="info-circle"
                    className="clickable-icon"
                    style={{
                      color: arycolor.aryBlue,
                    }}
                  />
                </Popover>
              </>
            )}
          </Space>
        </Col>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          {chemicalCalibrationItemNames && chemicalCalibrationItemNames.length > 0 && (
            <Popover style={{ display: 'flex' }} trigger={'hover'} content="Chemical calibration is active">
              <FontAwesomeIcon icon="balance-scale" style={{ marginRight: 10, fontSize: '13pt' }} />
            </Popover>
          )}
          {humidityCalibrationCalibrantName && (
            <Popover style={{ display: 'flex' }} trigger={'hover'} content="Humidity correction is active">
              <FontAwesomeIcon icon="droplet" style={{ marginRight: 10, fontSize: '13pt' }} />
            </Popover>
          )}
          <ArrowsAltOutlined className="clickable-icon" onClick={() => setIsVisibleModal(true)} />
        </Col>
      </Row>
      {figureType === MatrixFigureType.DistanceMatrix ? <DistanceMatrixPanel sessionID={sessionID} /> : <ConfusionMatrixPanel sessionID={sessionID} seletedModelsConfusion={seletedModelsConfusion} />}
      <FullscreenGraphicModal title={MatrixFigureType[figureType]} visible={isVisibleModal} onCancel={() => setIsVisibleModal(false)}>
        {figureType === MatrixFigureType.DistanceMatrix ? <DistanceMatrixPanel sessionID={sessionID} /> : <ConfusionMatrixPanel sessionID={sessionID} seletedModelsConfusion={seletedModelsConfusion} />}
      </FullscreenGraphicModal>
    </div>
  );
};

export default Matrix;
