import { LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOktaAuth } from '@okta/okta-react';
import { Button, Col, Drawer, Modal, notification, Row, Tag } from 'antd';
import { CSSProperties, Key, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { aryballeColorPalette } from '../compute/colormap';
import { selectAvailableTSDB } from '../features/analysisConfig/runsSlice';
import { selectVisibleDeleteRuns, setVisibleDeleteRuns } from '../features/analysisConfig/sessionInfoSlice';
import { RunMetadata, SelectedRun, Source, SourceType, TSDBRef } from '../types/runsType';
import { fetchAuthorizedAPIEndpoint } from '../utils';
import ModalComponent from './Modal/ModalComponent';

type DashboardSettingsProps = {
  selectedDeleteRuns: string[];
  setSelectedDeleteRuns: React.Dispatch<React.SetStateAction<string[]>>;
  runsMap: Record<string, RunMetadata[]>;
  startParam: string;
  chosenTSDBRef: TSDBRef | null | undefined;
  setIsRunsListLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setRunsMap: React.Dispatch<React.SetStateAction<Record<string, RunMetadata[]>>>;
};

type typeStyle = {
  [K in Key]: CSSProperties;
};

type DeleteReturn = {
  RunsDeleteFailure: Map<string, string>;
  RunsDeleteSuccess: string[];
};

const DashboardSettings: React.FC<DashboardSettingsProps> = ({ selectedDeleteRuns, setSelectedDeleteRuns, runsMap, startParam, chosenTSDBRef, setIsRunsListLoading, setRunsMap }) => {
  const visibleDeleteRuns = useAppSelector(selectVisibleDeleteRuns);
  const [runsDeleteArray, setRunsDeleteArray] = useState<RunMetadata[]>([]);
  const [runsDeleteMap, setRunsDeleteMap] = useState<Record<string, SelectedRun[]>>({});
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const availableTSDBRefs = useAppSelector<TSDBRef[] | undefined>(selectAvailableTSDB);
  const { authState } = useOktaAuth();

  const dispatch = useAppDispatch();
  const style: typeStyle = {
    container: { position: 'relative', height: '100vh', width: visibleDeleteRuns ? 300 : 0, padding: visibleDeleteRuns ? 48 : 0, overflow: 'hidden', textAlign: 'center' },
  };

  useEffect(() => {
    const runs = Object.values(runsMap).flat();
    const _runsDeleteArray: RunMetadata[] = [];
    selectedDeleteRuns.forEach((uid) => {
      const run = runs.find((r) => r.UID === uid);
      if (run) _runsDeleteArray.push(run);
    });
    setRunsDeleteArray(_runsDeleteArray);
  }, [runsMap, selectedDeleteRuns]);

  const callForHandleIDs = (runs: SelectedRun[]) => {
    return new Promise<DeleteReturn>((resolve, reject) => {
      let query = new URLSearchParams({
        run_uids: runs.map((run) => run.UID).join(','),
      });

      fetchAuthorizedAPIEndpoint(`/clouddb/delete_runs?${query.toString()}`, authState, {
        method: 'POST',
        body: JSON.stringify(runs[0].TSDBRef),
      })
        .then((resp: Response) => {
          if (resp.ok) return resp.json();
          return Promise.reject(resp);
        })
        .then((body: DeleteReturn) => {
          resolve(body);
        })
        .catch((e) => {
          console.log(e);
          return reject(e);
        });
    });
  };

  const reloadListRuns = () => {
    if (chosenTSDBRef === null || chosenTSDBRef === undefined) {
      return;
    }
    fetchAuthorizedAPIEndpoint(
      `/clouddb/list_runs?start=${startParam}`,
      authState,
      {
        method: 'POST',
        body: JSON.stringify(chosenTSDBRef),
      },
      chosenTSDBRef.URL
    )
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw new Error();
        }
      })
      .then((runs: RunMetadata[] | null) => {
        if (runs === null) {
          runs = [];
        }
        var _runsMap = { ...runsMap };
        _runsMap[chosenTSDBRef.Name] = runs.map((run) => (run = { ...run, Source: { Name: chosenTSDBRef.Name, Type: SourceType.TSDB } }));
        setRunsMap(_runsMap);
        setIsRunsListLoading(false);
      })
      .catch((e: Error) => {
        setIsRunsListLoading(false);
      });
  };

  const handleDelete = () => {
    if (!availableTSDBRefs) return;
    setLoading(true);
    let promises: Promise<DeleteReturn>[] = [];
    const uniqueSources: Source[] = Array.from(new Set(runsDeleteArray.map((run) => run.Source)));
    let _runsDeleteMap = { ...runsDeleteMap };
    uniqueSources.forEach((source) => {
      _runsDeleteMap[source.Name] = runsDeleteArray.filter((run) => run.Source.Name === source.Name).map((run) => ({ UID: run.UID, TSDBRef: availableTSDBRefs.filter((tsdb) => tsdb.Name === source.Name)[0] }));
    });
    setRunsDeleteMap(_runsDeleteMap);

    Object.entries(_runsDeleteMap).forEach(([k, selectedRuns]) => {
      promises.push(callForHandleIDs(selectedRuns));
    });

    Promise.all(promises)
      .then((response: DeleteReturn[]) => {
        let failedMessage: { uid: string; msg: string }[] = [];

        const numberRunsFailed = response.map((r) => Object.keys(r.RunsDeleteFailure).length);
        const initialValue = 0;
        const totalRunsFailed = numberRunsFailed.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);

        const runsFailed = response.map((r) => r.RunsDeleteFailure);
        const runsSuccess = response.map((r) => r.RunsDeleteSuccess).flat();

        if (totalRunsFailed !== 0) {
          runsFailed.forEach((groupRuns) => {
            Object.entries(groupRuns).forEach(([key, value]) => {
              return failedMessage.push({
                uid: key,
                msg: value,
              });
            });
          });

          setIsVisibleModal(false);
          setLoading(false);
          Modal.error({
            closable: true,
            title: 'Error when delete runs',
            centered: true,
            content: (
              <>
                <p>
                  All runs could not be deleted, please contact support at support@aryballe.com. <br />
                </p>
                <p>The following runs could not be deleted :</p>
                {failedMessage.map((msg) => (
                  <p style={{ marginBottom: 5 }}>
                    - <span style={{ fontWeight: 'bold' }}>{msg.uid}:</span> {msg.msg}
                  </p>
                ))}
              </>
            ),
          });
          setSelectedDeleteRuns(failedMessage.map((run) => run.uid));
          if (runsSuccess.length !== 0) reloadListRuns();
        } else {
          setRunsDeleteMap({});
          setSelectedDeleteRuns([]);
          setIsVisibleModal(false);
          setLoading(false);
          dispatch(setVisibleDeleteRuns(false));
          notification.success({
            message: 'Success',
            description: (
              <div>
                <p>Runs have been deleted</p>
                {runsSuccess.map((r) => (
                  <p style={{ marginBottom: 5 }}>- {r}</p>
                ))}
              </div>
            ),
          });
          reloadListRuns();
        }
      })
      .catch((e) => {
        console.log('Promise error', e);
        setIsVisibleModal(false);
        Modal.error({
          closable: true,
          title: 'Error when delete runs',
          centered: true,
          maskClosable: false,
          style: { borderRadius: 5 },
          className: 'modal-delete-error',
          content: (
            <>
              <p>Unexpected error occured. Probably a network or DB error. Please contact support.</p>
              {typeof e === 'string' && <p>{e}</p>}
            </>
          ),
          okText: 'Contact support',
          onOk() {
            window.open('https://aryballe.atlassian.net/servicedesk/customer/portal/1', '_blank');
          },
          okButtonProps: {
            block: true,
            style: {
              background: 'red',
              border: 'none',
              borderRadius: 5,
            },
          },
        });
      });
  };

  return (
    <div style={style.container}>
      <Drawer
        title="Dashboard settings"
        placement="right"
        visible={visibleDeleteRuns}
        getContainer={false}
        bodyStyle={{ overflowY: 'scroll', textAlign: 'left', paddingBottom: 50 }}
        style={{ position: 'fixed', right: 0, top: 50, width: style.container.width, height: 'calc(100% - 50px)' }}
        headerStyle={{ borderBottom: 'none' }}
        mask={false}
        onClose={() => dispatch(setVisibleDeleteRuns(false))}
      >
        <div style={{ paddingBottom: 40 }}>
          {runsDeleteArray.map((run) => {
            return (
              <div>
                <Row style={{ flexFlow: 'row', marginBottom: 5 }} align="middle">
                  <FontAwesomeIcon icon="times" style={{ color: 'red', marginRight: 15, cursor: 'pointer' }} onClick={() => setSelectedDeleteRuns(selectedDeleteRuns.filter((el) => el !== run.UID))} />
                  <p style={{ wordBreak: 'break-word', marginBottom: 0 }}>{run.ID}</p>
                </Row>
                <Row style={{ marginLeft: 20, width: '100%', marginBottom: 15 }}>
                  <Row justify="start" style={{ width: '100%' }}>
                    <Col span={6}>
                      <span style={{ fontWeight: 'bold' }}>Name:</span>
                    </Col>
                    <Col>
                      <p style={{ marginBottom: 0 }}>{run.Name}</p>
                    </Col>
                  </Row>
                  <Row justify="start" style={{ width: '100%' }}>
                    <Col span={6}>
                      <span style={{ fontWeight: 'bold' }}>UID:</span>
                    </Col>
                    <Col span={18}>
                      <p style={{ marginBottom: 0 }}>{run.UID}</p>
                    </Col>
                  </Row>
                  <Row justify="start" style={{ width: '100%' }}>
                    <Col span={6}>
                      <span style={{ fontWeight: 'bold' }}>Neose:</span>
                    </Col>
                    <Col span={18}>
                      <p style={{ marginBottom: 0 }}>
                        <Tag>{run.Device.HostName}</Tag>
                      </p>
                    </Col>
                  </Row>
                  <Row justify="start" style={{ width: '100%' }}>
                    <Col span={6}>
                      <span style={{ fontWeight: 'bold' }}>Core sensor:</span>
                    </Col>
                    <Col span={18}>
                      <p style={{ marginBottom: 0 }}>
                        <Tag>{run.Device && run.Device.Info && run.Device.Info.CoreSensorSerial.replace(/CS22.\-/i, '')}</Tag>
                      </p>
                    </Col>
                  </Row>
                  <Row justify="start" style={{ width: '100%' }}>
                    <Col span={6}>
                      <span style={{ fontWeight: 'bold' }}>Source:</span>
                    </Col>
                    <Col span={18}>
                      <p style={{ marginBottom: 0 }}>{run.Source.Name.toUpperCase()}</p>
                    </Col>
                  </Row>
                </Row>
              </div>
            );
          })}
        </div>
        <Row style={{ position: 'fixed', width: '80%', bottom: 0, height: 80, background: 'white' }}>
          <Col span={24}>
            <Row style={{ marginBottom: 10 }} align="middle">
              <p style={{ marginBottom: 0, fontSize: 13 }}>
                <span style={{ fontWeight: 'bold' }}>{selectedDeleteRuns.length}</span> runs selected for delete
              </p>
              <FontAwesomeIcon icon="times" style={{ marginLeft: 15, color: 'red', cursor: 'pointer', width: 15, height: 15 }} onClick={() => setSelectedDeleteRuns([])} />
            </Row>
            {runsDeleteArray && runsDeleteArray.length !== 0 ? (
              <Button style={{ color: 'red', border: '1px solid red', fontWeight: 'bold', borderRadius: 5 }} block onClick={() => setIsVisibleModal(true)}>
                Delete
              </Button>
            ) : (
              <Button style={{ color: 'grey', background: '#dadada', border: '1px solid #dadada', fontWeight: 'bold', borderRadius: 5, cursor: 'default' }} block disabled onClick={() => setIsVisibleModal(true)}>
                Delete
              </Button>
            )}
          </Col>
        </Row>
      </Drawer>
      <ModalComponent
        title="Delete runs"
        visible={isVisibleModal}
        maskClosable={false}
        footer={[
          <Button type="primary" style={{ background: 'red', borderRadius: 5, color: 'white', border: 'none', fontWeight: 'bold', paddingLeft: 30, paddingRight: 30 }} onClick={() => handleDelete()}>
            {!loading ? 'DELETE' : <LoadingOutlined />}
          </Button>,
          <Button style={{ borderRadius: 5, paddingLeft: 30, paddingRight: 30 }} onClick={() => setIsVisibleModal(false)}>
            Cancel
          </Button>,
        ]}
        onCancel={() => setIsVisibleModal(false)}
        width="40vw"
      >
        <p>Do you confirm deletion of runs? Deleted runs cannot be recovered.</p>
        {runsDeleteArray.map((run) => (
          <Row align="middle" style={{ marginBottom: 20 }}>
            <FontAwesomeIcon icon="times" style={{ color: 'red', marginRight: 15, width: 15, height: 15 }} />
            <p style={{ marginBottom: 0 }}>{run.ID}</p>
          </Row>
        ))}
      </ModalComponent>
    </div>
  );
};

export default DashboardSettings;
