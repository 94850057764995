import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getAPIEndpoint } from './endpoint';
import { CustomAuthState, IOktaOrQueryAuth } from './types/userType';

export // A custom hook that builds on useLocation to parse
// the query string for you.
function useQueryParam(key: string, initialValue: string): [string, (newValue: string) => void] {
  const { state, hash, pathname, search } = useLocation();
  let history = useHistory();

  let value: string = initialValue;
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const alreadyDefinedValue = query.get(key);
  if (alreadyDefinedValue !== null && alreadyDefinedValue !== '') {
    value = alreadyDefinedValue;
  }

  const dispatch = (newValue: string) => {
    // do not update history if nothing has changed
    if (newValue === query.get(key)) {
      return;
    }
    if (newValue !== '') {
      query.set(key, newValue);
    } else {
      if (!query.has(key)) {
        // no need to update if newValue is '' and query already does not include it
        return;
      }
      query.delete(key);
    }
    history.push({
      state: state,
      hash: hash,
      pathname: pathname,
      search: '?' + query.toString(),
    });
  };

  return [value, dispatch];
}

export const fetchAuthorizedAPIEndpoint = (pathAndQuery: string, authState: CustomAuthState | null, init?: RequestInit, tsdbUrl?: string): Promise<Response> => {
  if (!authState || !authState.accessToken || !authState.accessToken.accessToken) {
    throw new Error('authState does not have the accessToken');
  }
  if (!init) {
    init = {
      headers: [['Authorization', `Bearer ${authState.accessToken.accessToken}`]],
    };
  } else {
    init.headers = [['Authorization', `Bearer ${authState.accessToken.accessToken}`]];
  }
  const input = `${getAPIEndpoint(tsdbUrl)}${pathAndQuery}`;
  return fetch(input, init);
};

export const useOktaOrQueryAuth = (): IOktaOrQueryAuth => {
  const [finalAuthState, setFinalAuthState] = useState<CustomAuthState | null>(null);
  const { authState: oktaAuthState } = useOktaAuth();

  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (oktaAuthState !== null && oktaAuthState.isAuthenticated) {
      setFinalAuthState(oktaAuthState);
    } else {
      let accessToken = urlParams.get('accessToken');
      if (accessToken !== null) {
        setFinalAuthState({ accessToken: { accessToken }, isAuthenticated: true });
      }
    }
  }, [search, oktaAuthState]);

  return { authState: finalAuthState };
};
