import { Upload, message, Button, Col, Row, Divider } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { FC, useEffect, useState } from 'react';
import { AnalysisConfig } from '../types/analysisTypes';
import { useAppDispatch } from '../app/hooks';
import { resetAnalysisConfig } from '../features/analysisConfig/analysisConfigSlice';
import { getAPIEndpoint } from '../endpoint';
import { UploadFile } from 'antd/lib/upload/interface';
import { useHistory } from 'react-router-dom';
import SectionPage from '../components/section/SectionPage';
import { useOktaAuth } from '@okta/okta-react';
import { fetchAuthorizedAPIEndpoint } from '../utils';
import { UserClaimsWithTSDB } from '../types/userType';

const { Dragger } = Upload;

export const UploadFileComponent: FC = () => {
  const [handleIDs, setHandleIDs] = useState<string[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const { authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);

  let history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState]);

  useEffect(() => {
    dispatch(resetAnalysisConfig());
  }, [dispatch]);

  const handleContinue = () => {
    setIsUploading(true);

    fetchAuthorizedAPIEndpoint(`/init_session?handle_ids=${handleIDs.join(',')}`, authState)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .catch((e) => {
        e.then((resp: { Reason: string }) => {
          setIsUploading(false);
          message.error(`Error occurred during data digestion in the cloud: ${resp.Reason}`);
        });
      })
      .then((analysisConfig: AnalysisConfig) => {
        dispatch(resetAnalysisConfig());
        history.push(`/dashboard/${analysisConfig.SessionID}`);
      });
  };

  return (
    <SectionPage>
      <Row justify="center" align="middle" style={{ height: '80vh' }}>
        <Col span={18}>
          <Row>
            <Col span={24}>
              <Dragger
                action={`${getAPIEndpoint()}/upload_html?source_name=${userInfo?.main_tsdb_name}&user_id=${userInfo?.sub}`}
                name="html-file"
                accept="html"
                fileList={fileList}
                multiple={true}
                onRemove={(file) => {
                  const fileIndex = fileList.indexOf(file);
                  const newFileList = fileList.slice();
                  newFileList.splice(fileIndex, 1);
                  setFileList(newFileList);

                  if (file.response !== undefined) {
                    let newHandleIDs = handleIDs.slice();
                    const handleIndex = handleIDs.indexOf(file.response.HandleID);
                    newHandleIDs.splice(handleIndex, 1);
                    setHandleIDs(newHandleIDs);
                  }
                }}
                onChange={(info) => {
                  let file = info.file;
                  if (file.status === 'done' && file.response !== undefined) {
                    setHandleIDs([...handleIDs, file.response.HandleID]);
                  }
                  let newFileList = [...info.fileList];
                  setFileList(newFileList);

                  let _isDisabled = newFileList.length === 0;
                  newFileList.forEach((f) => {
                    if (f.status !== 'done') {
                      _isDisabled = true;
                    }
                  });
                  setIsDisabled(_isDisabled);
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Support for Amplifier HTML reports</p>
              </Dragger>
            </Col>
          </Row>
          <Divider></Divider>
          <Row>
            <Col span={24}>
              <Button onClick={handleContinue} size="large" loading={isUploading} style={{ width: '100%' }} type="primary" disabled={isDisabled}>
                Continue
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </SectionPage>
  );
};

export const UploadPage: FC = () => {
  return <UploadFileComponent />;
};
