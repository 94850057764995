import { useAppSelector } from '../../../app/hooks';
import { selectSensogramRenderType } from '../../../features/analysisConfig/analysisConfigSlice';
import { SensogramRenderType } from '../../../types/analysisTypes';
import { ItemwiseSensogram } from './ItemSensogram';
import { MultiSensogram } from './MultiSensogram';
import { SingleSensogram } from './SingleSensogram/SingleSensogram';

export const SensogramPanel: React.FC = () => {
  const sensogramRenderType = useAppSelector(selectSensogramRenderType);
  return (
    <div style={{ width: '100%', height: '40vh', display: 'flex', flexDirection: 'row' }}>
      {(function () {
        switch (sensogramRenderType) {
          case +SensogramRenderType.Record:
            return <SingleSensogram />;
          case +SensogramRenderType.Item:
            return <ItemwiseSensogram />;
          case +SensogramRenderType.Dataset:
            return <MultiSensogram />;
          default:
            return null;
        }
      })()}
    </div>
  );
};
