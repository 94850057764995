import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, message, Popover } from 'antd';
import { CSSProperties, Key, useState } from 'react';
import { Link } from 'react-router-dom';
import { AnalysisConfig } from '../../types/analysisTypes';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../utils';

type ShareSessionProps = {
  sessionID: string;
};

type typeStyle = {
  [K in Key]: CSSProperties;
};

enum ItemMenu {
  NewSession = 'New session',
  DuplicateSession = 'Duplicate session',
  CopyLink = 'Copy link of the session',
}

const ShareSession: React.FC<ShareSessionProps> = ({ sessionID }) => {
  const [checkCopy, setCheckCopy] = useState<boolean>(false);
  const { authState } = useOktaOrQueryAuth();

  const style: typeStyle = {
    li: { height: 25, lineHeight: '25px' },
  };

  const handleDuplicateSession = () => {
    fetchAuthorizedAPIEndpoint(`/duplicate_session?session_id=${sessionID}`, authState)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw Error(resp.statusText);
        }
      })
      .then((analysisConfig: AnalysisConfig) => {
        window.open(`/dashboard/${analysisConfig.SessionID}`, '_blank')?.focus();
      })
      .catch((e) => {
        e.then((resp: { Reason: string }) => {
          message.error(`Error occured during data fetching: ${resp.Reason}`, 5);
        });
      })
      .catch((e) => {
        message.error(`Unexpected error occured. Probably a network or DB error. Please contact support: ${e}`, 5);
      });
  };

  const handleCopyLink = () => {
    if (!window.location.href) return null;
    setCheckCopy(true);
    return navigator.clipboard.writeText(window.location.href);
  };

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      overlayInnerStyle={{ borderRadius: 5 }}
      title={null}
      content={
        <Menu
          onMouseLeave={() => setCheckCopy(false)}
          style={{ borderRight: 'none', marginLeft: -10, marginRight: -10 }}
          items={[
            {
              label: (
                <Link to="/" target={'_blank'}>
                  {ItemMenu.NewSession}
                </Link>
              ),
              key: ItemMenu.NewSession,
              style: style.li,
            },
            {
              label: <span onClick={() => handleDuplicateSession()}>{ItemMenu.DuplicateSession}</span>,
              key: ItemMenu.DuplicateSession,
              style: style.li,
            },
            {
              label: (
                <span onClick={() => handleCopyLink()}>
                  {ItemMenu.CopyLink}
                  {checkCopy && <FontAwesomeIcon icon="check" style={{ width: 10, height: 10, marginLeft: 10, color: 'green' }} />}
                </span>
              ),
              key: ItemMenu.CopyLink,
              style: style.li,
            },
          ]}
        />
      }
    >
      <FontAwesomeIcon icon="share-square" style={{ width: 20, height: 20, cursor: 'pointer', marginRight: 10 }} />
    </Popover>
  );
};

export default ShareSession;
