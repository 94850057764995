import { Card } from 'antd';
import { ReactNode } from 'react';

type SectionPageProps = {
  children: ReactNode;
  backgroundColor?: 'transparent' | 'white';
  boxShadow?: boolean;
  width?: string | number;
  marginTop?: string | number;
  paddingBottom?: string | number;
  paddingTop?: string | number;
  height?: string | number;
  heightBody?: string | number;
  id?: string;
  marginBottom?: string | number;
};

const SectionPage: React.FC<SectionPageProps> = ({ children, backgroundColor, boxShadow, width, marginTop, paddingBottom, paddingTop, height, heightBody, id, marginBottom }) => {
  const style = {
    card: {
      marginLeft: backgroundColor === 'white' ? '1vw' : 0,
      marginRight: backgroundColor === 'white' ? '1vw' : 0,
      marginBottom: marginBottom,
      marginTop: marginTop ? marginTop : backgroundColor === 'white' ? '30px' : 'calc(30px - 24px)',
      backgroundColor: backgroundColor,
      width: width,
      borderRadius: 10,
      boxShadow: boxShadow ? '4px 4px 4px 0 #07062310' : 'none',
      height: height,
    },
  };
  return (
    <Card id={id} style={style.card} bodyStyle={{ paddingBottom, paddingTop, height: heightBody }}>
      {children}
    </Card>
  );
};

SectionPage.defaultProps = {
  backgroundColor: 'white',
  boxShadow: true,
  paddingBottom: 24,
  paddingTop: 24,
  height: 'auto',
};

export default SectionPage;
