import { Alert, Button, message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { AnalysisConfig } from '../types/analysisTypes';
import { useHistory } from 'react-router-dom';
import { RunsTable } from '../components/runs_table';
import { resetAnalysisConfig } from '../features/analysisConfig/analysisConfigSlice';
import { useAppDispatch } from '../app/hooks';
import SectionPage from '../components/section/SectionPage';
import { TableSkeleton } from '../components/table_skeleton';
import { useOktaAuth } from '@okta/okta-react';
import { fetchAuthorizedAPIEndpoint } from '../utils';
import { UserClaimsWithTSDB } from '../types/userType';
import { RunMetadata, SelectedRun, TSDBRef } from '../types/runsType';

export const SQLiteSelectorComponent: FC = () => {
  const [runs, setRuns] = useState<RunMetadata[]>([]);
  const [isError, setIsError] = useState(false);
  const [isTransferringHandle, setIsTransferringHandle] = useState(false);
  const [retryCounter, setRetryCounter] = useState(0);
  const [selectedRunsMap, setSelectedRunsMap] = useState<Record<TSDBRef['Name'], SelectedRun[]>>({ SQLite: [] });

  const { authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);

  let history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState]);

  useEffect(() => {
    dispatch(resetAnalysisConfig());
    const controller = new AbortController();

    fetch('http://127.0.0.1:8205/list_runs', { signal: controller.signal })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw new Error();
        }
      })
      .then((runs: RunMetadata[]) => {
        setRuns(runs);
      })
      .catch((e: Error) => {
        console.log(e.name, e.cause, e.message);
        setIsError(true);
      });
  }, [retryCounter, dispatch]);

  if (isError) {
    return (
      <SectionPage backgroundColor={undefined}>
        <Alert
          showIcon
          type="warning"
          style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
          description={
            <span>
              An error occurred when connecting to <b>Local Storage Connector</b>.
              <br />
              Please make sure you have the connector <b>running</b> on you computer <b>in the background</b>.
              <br />
              If you don't have the connector yet, download it from <a href="https://local-storage-connector.s3.eu-west-3.amazonaws.com/aryballe-local-storage-connector-latest.exe">here</a>!
            </span>
          }
          action={
            <Button
              style={{ minWidth: 150 }}
              onClick={() => {
                setIsError(false);
                setRetryCounter(retryCounter + 1);
              }}
            >
              Retry
            </Button>
          }
        />
      </SectionPage>
    );
  }

  let uniqueItemsSet: Set<string> = new Set();
  runs.forEach((run) => {
    run.ItemNames.forEach((itemName) => {
      uniqueItemsSet.add(itemName);
    });
  });
  let uniqueItems: string[] = Array.from(uniqueItemsSet);
  uniqueItems.sort();

  var allSelectedRuns: SelectedRun[] = [];
  Object.entries(selectedRunsMap).forEach(([_, runs]) => {
    runs.forEach((run) => {
      allSelectedRuns.push(run);
    });
  });

  const isDisabled = () => {
    return allSelectedRuns.length === 0;
  };

  const handleContinue = () => {
    setIsTransferringHandle(true);

    fetch(`http://127.0.0.1:8205/get_runs/gob?run_uids=${selectedRunsMap['SQLite'].map((run) => run.UID).join(',')}`)
      .then((resp) => {
        if (resp.ok) {
          return resp.arrayBuffer();
        } else {
          throw resp.json();
        }
      })
      .then((blob) => {
        fetchAuthorizedAPIEndpoint(`/upload_gob?source_name=${userInfo?.main_tsdb_name}`, authState, {
          method: 'POST',
          body: blob,
        })
          .then((resp) => {
            if (resp.ok) {
              return resp.json();
            } else {
              throw resp.json();
            }
          })
          .catch((e) => {
            e.then((resp: { Reason: string }) => {
              message.error(`Error occured during data uploading: ${resp.Reason}`, 5);
              setIsTransferringHandle(false);
            });
          })
          .then((body: { HandleID: string }) => {
            fetchAuthorizedAPIEndpoint(`/init_session?handle_ids=${body.HandleID}`, authState)
              .then((resp) => {
                if (resp.ok) return resp.json();
                else throw resp.json();
              })
              .catch((e) => {
                e.then((resp: { Reason: string }) => {
                  message.error(`Error occured during data digestion in the cloud: ${resp.Reason}`, 5);
                  setIsTransferringHandle(false);
                });
              })
              .then((analysisConfig: AnalysisConfig) => {
                dispatch(resetAnalysisConfig());
                history.push(`/dashboard/${analysisConfig.SessionID}`);
              });
          });
      })
      .catch((e) => {
        e.then((resp: { Reason: string }) => {
          message.error(`Error occured during data fetching: ${resp.Reason}`, 5);
          setIsTransferringHandle(false);
        });
      });
  };

  return (
    <SectionPage backgroundColor={undefined}>
      {runs.length === 0 ? (
        <TableSkeleton />
      ) : (
        <>
          <RunsTable runs={runs} sourceRef={{ Name: 'SQLite', Org: '', Token: '', URL: '' }} selectedRunsMap={selectedRunsMap} setSelectedRunsMap={setSelectedRunsMap} />
          <Button onClick={handleContinue} size="large" block type="primary" disabled={isDisabled()} loading={isTransferringHandle}>
            Continue
          </Button>
        </>
      )}
    </SectionPage>
  );
};

export const SQLitePage: FC = () => {
  return <SQLiteSelectorComponent />;
};
