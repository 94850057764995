import ModalComponent from '../Modal/ModalComponent';

type FullscreenGraphicModalProps = {
  title: React.ReactNode;
  children: React.ReactNode;
  visible: boolean;
  onCancel: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
};

const FullscreenGraphicModal: React.FC<FullscreenGraphicModalProps> = (props) => {
  const { children, title, visible, onCancel } = props;
  return (
    <ModalComponent title={title} visible={visible} footer={null} onCancel={onCancel} style={{ paddingTop: '50px' }}>
      <div style={{ height: '80vh' }}>{children}</div>
    </ModalComponent>
  );
};

export default FullscreenGraphicModal;
