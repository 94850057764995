import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Row, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { HtmlDescription } from '../../../components/HtmlDescription';
import ModalComponent from '../../../components/Modal/ModalComponent';
import CardSection from '../../../components/section/CardSection';
import { selectSessionID } from '../../../features/analysisConfig/analysisConfigSlice';
import { RunMetadata } from '../../../types/runsType';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../../utils';

type DashboardExperimentPlanProps = {};

const DashboardExperimentPlan: React.FC<DashboardExperimentPlanProps> = () => {
  const { authState } = useOktaOrQueryAuth();
  const [runs, setRuns] = useState<RunMetadata[]>([]);
  const sessionID = useAppSelector(selectSessionID);
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<string>('0');

  // Load runs
  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    if (sessionID !== '') {
      setSelectedPlan('0');
      fetchAuthorizedAPIEndpoint(`/runs?session_id=${sessionID}`, authState)
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            throw new Error();
          }
        })
        .then((runs: RunMetadata[]) => {
          if (runs != null) {
            setRuns(runs);
          }
        });
    }
  }, [authState, sessionID]);

  return (
    <CardSection scroll="auto" marginBottom="2%" minHeight="90px">
      <Row justify="space-between" style={{ marginTop: -10 }}>
        <p style={{ fontWeight: 500, marginBottom: 10 }}>Experiment plan</p>
        <FontAwesomeIcon icon="search-plus" className="clickable-icon" onClick={() => setIsVisibleModal(true)} />
      </Row>
      <div style={{ maxHeight: 50, overflowY: 'auto' }}>
        {runs.map((run, index) => (
          <Row
            align="middle"
            style={{ cursor: 'pointer', marginBottom: 5 }}
            onClick={() => {
              setIsVisibleModal(true);
              setSelectedPlan(index.toString());
            }}
          >
            <FontAwesomeIcon icon="plus-square" className="clickable-icon" style={{ marginRight: 10 }} />
            <p style={{ marginBottom: 0 }}>{run.ID}</p>
          </Row>
        ))}
      </div>
      <ModalComponent
        title="Experiment plan"
        className="experiment-plan-modal"
        footer={[
          <Button type="primary" style={{ borderRadius: 5 }} onClick={() => setIsVisibleModal(false)}>
            OK
          </Button>,
        ]}
        visible={isVisibleModal}
        onCancel={() => setIsVisibleModal(false)}
        width="80vw"
      >
        <Tabs activeKey={selectedPlan} onTabClick={(key) => setSelectedPlan(key)}>
          {runs.map((run, index) => (
            <Tabs.TabPane tab={run.ID} key={index} style={{ maxHeight: `calc(80vh - 200px)`, overflowY: 'auto', paddingBottom: 50 }}>
              {run.ExperimentPlan.length !== 0 ? (
                <pre style={{ whiteSpace: 'pre-wrap' }}>
                  <HtmlDescription style={{ fontSize: 16 }}>{run.ExperimentPlan.replace(/\\r\\n/g, '<br />').replace(/\\n/g, '<br />')}</HtmlDescription>
                </pre>
              ) : (
                <Alert description="The plan of this run is not available." type="info" style={{ borderRadius: 5 }} />
              )}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </ModalComponent>
    </CardSection>
  );
};

export default DashboardExperimentPlan;
