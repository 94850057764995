import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import {
  selectAggregatePeptides,
  selectBoundariesMap,
  selectChemicalCalibrationItemNames,
  selectCurrentRecordID,
  selectExcludedPeptides,
  selectExcludedRecordIDs,
  selectExcludedSpots,
  selectHumidityCompensationCalibrantName,
  selectHumidityCompensationPositionOffset,
  selectHumidityCompensationSubstractionGain,
  selectSessionID,
  selectSubtractItemName,
} from '../../../features/analysisConfig/analysisConfigSlice';
import { Signature } from '../../../types/analysisTypes';
import { FeatureFlag } from '../../../types/userType';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../../utils';
import { WithFeatureFlagsCheck } from '../../../with_feature_flags_check';

export const EncodedSignatureLink: React.FC = () => {
  const { authState } = useOktaOrQueryAuth();
  const sessionID = useAppSelector(selectSessionID);
  const currentRecordID = useAppSelector(selectCurrentRecordID);
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const [signature, setSignature] = useState<Signature | null>(null);

  const subtractItemName = useAppSelector(selectSubtractItemName);
  const boundariesMap = useAppSelector(selectBoundariesMap);
  const aggregatePeptides = useAppSelector(selectAggregatePeptides);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const excludedPeptides = useAppSelector(selectExcludedPeptides);

  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);
  const humidityCalibrationPositionOffset = useAppSelector(selectHumidityCompensationPositionOffset);
  const humidityCalibrationSubstractionGain = useAppSelector(selectHumidityCompensationSubstractionGain);

  const chemicalCalibrationItemNames = useAppSelector(selectChemicalCalibrationItemNames);

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    var boundaries = boundariesMap[currentRecordID];
    if (boundaries === undefined) {
      return;
    }
    fetchAuthorizedAPIEndpoint(`/compute/signature?session_id=${sessionID}&record_id=${currentRecordID}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        aggregatePeptides,
        boundariesMap,
        subtractItemName,
        excludedRecordIDs,
        excludedPeptides,
        excludedSpots,

        humidityCompensation: {
          calibrantName: humidityCalibrationCalibrantName,
          positionOffset: humidityCalibrationPositionOffset,
          SubstractionGain: humidityCalibrationSubstractionGain,
        },
        chemicalCalibrationItemNames,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw new Error();
        }
      })
      .then((data: Signature) => {
        setSignature(data);
      })
      .catch((e) => {
        setSignature(null);
      });
  }, [
    authState,
    sessionID,
    currentRecordID,
    boundariesMap,
    aggregatePeptides,
    subtractItemName,
    excludedRecordIDs,
    excludedPeptides,
    excludedSpots,
    humidityCalibrationCalibrantName,
    humidityCalibrationPositionOffset,
    humidityCalibrationSubstractionGain,
    chemicalCalibrationItemNames,
  ]);

  if (signature === null || signature.EncodedSignature === '') {
    return null;
  }

  return (
    <WithFeatureFlagsCheck ff={FeatureFlag.AAChemicalFamiliesAccessEnabled}>
      <Tooltip title="Search in Olfactive Library">
        <a
          href={`https://library.aryballe.com/?searchType=3&searchSignature=${encodeURIComponent(signature.EncodedSignature)}`}
          target="_blank"
          rel="noreferrer"
          style={{
            fontSize: '14pt',
            textAlign: 'center',
          }}
        >
          <FontAwesomeIcon className="clickable-icon" icon={faUpRightFromSquare} />
        </a>
      </Tooltip>
    </WithFeatureFlagsCheck>
  );
};
