import { Col, Row, Switch, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ConfusionMatrixPanel from '../../components/graphic/matrix/ConfusionMatrix';
import DistanceMatrixPanel from '../../components/graphic/matrix/DistanceMatrix';
import { PCAPanel } from '../../components/graphic/pca';
import { Caption } from '../../components/summary/caption';
import { selectColormap } from '../../features/analysisConfig/analysisConfigSlice';
import { selectIsIncludeConfusionMatrix, selectObservationsConfusion, selectObservationsDiscrimination, setIsIncludeConfusionMatrix, setObservationsConfusion, setObservationsDiscrimination } from '../../features/analysisConfig/pdfSlice';
import { AryRecord } from '../../types/analysisTypes';
import { useOktaOrQueryAuth } from '../../utils';
import Legend from './legend';
import Pagebreak from './pageBreak';
import { saveParamsInDatabase } from './pdfContainer';

type DiscriminationPdfProps = {
  effectiveRecords: AryRecord[];
  disabledPdf: boolean;
  sessionID: string;
  seletedModelsConfusion: string | undefined;
};

const DiscriminationPdf: React.FC<DiscriminationPdfProps> = ({ effectiveRecords, sessionID, disabledPdf, seletedModelsConfusion }) => {
  const cmap = useAppSelector(selectColormap);
  const observationsDiscrimination = useAppSelector(selectObservationsDiscrimination);
  const includeConfusionMatrix = useAppSelector(selectIsIncludeConfusionMatrix);
  const observationsConfusion = useAppSelector(selectObservationsConfusion);
  const dispatch = useAppDispatch();

  const { authState } = useOktaOrQueryAuth();

  return (
    <div id="discriminationPdf">
      <Row justify="start">
        <Col>
          <Typography.Title level={4}>1.3. Discrimination</Typography.Title>
        </Col>
      </Row>
      <Row justify="start">
        <Col>
          <Typography.Title level={5}>1.3.1. PCA and distance matrix</Typography.Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col sm={12} span={24}>
          <div style={{ width: '100%', aspectRatio: '1/1' }}>
            <PCAPanel sessionID={sessionID} disabledPdf={disabledPdf} />
          </div>
        </Col>
        <Col sm={12} span={24}>
          <div style={{ width: '100%', aspectRatio: '1/1' }}>
            <DistanceMatrixPanel sessionID={sessionID} />
          </div>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Legend effectiveRecords={effectiveRecords} cmap={cmap} />
        </Col>
      </Row>
      <Caption
        title="Fig 3"
        subtitle="Discrimination metrics of measured samples: Principle Component Analysis (PCA) projection of normalized signatures (left) and distance matrix (right). The values in the matrix cells are distances in normalized space"
        setObservations={setObservationsDiscrimination}
        observations={observationsDiscrimination}
        sessionID={sessionID}
        authState={authState}
        name="observationsDiscrimination"
      />

      <Pagebreak />

      <div className="pdf-invisible" style={{ marginTop: 20, marginBottom: 20 }}>
        <Row>
          <Col style={{ marginRight: 10 }}>
            <Switch
              checked={includeConfusionMatrix}
              onChange={() => {
                dispatch(setIsIncludeConfusionMatrix(!includeConfusionMatrix));
                saveParamsInDatabase({ includeConfusionMatrix: !includeConfusionMatrix }, sessionID, authState);
              }}
            ></Switch>
          </Col>
          <Col>Include confusion matrix</Col>
        </Row>
      </div>
      {includeConfusionMatrix && (
        <>
          <Row justify="start">
            <Col>
              <Typography.Title level={5}>1.3.2. Confusion matrix</Typography.Title>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={16}>
              <div style={{ width: '100%', aspectRatio: '1/1' }}>
                <ConfusionMatrixPanel sessionID={sessionID} seletedModelsConfusion={seletedModelsConfusion} />
              </div>
            </Col>
          </Row>
          <Caption
            title="Fig 4"
            subtitle="Discrimination metrics of measured samples: confusion matrix."
            setObservations={setObservationsConfusion}
            observations={observationsConfusion}
            sessionID={sessionID}
            authState={authState}
            name="observationsConfusion"
          />
          <Pagebreak />
        </>
      )}
    </div>
  );
};

export default DiscriminationPdf;
