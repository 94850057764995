import { FC, useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  selectAggregatePeptides,
  selectBoundariesMap,
  selectChemicalCalibrationItemNames,
  selectColormap,
  selectExcludedFirstCycleParamState,
  selectExcludedPeptides,
  selectExcludedRecordIDs,
  selectExcludedSpots,
  selectHumidityCompensationCalibrantName,
  selectHumidityCompensationPositionOffset,
  selectHumidityCompensationSubstractionGain,
  selectPCAEigenvalues,
  selectRecords,
  selectSigmaMultiplier,
  selectSubtractItemName,
  setPCAEigenvalues,
  setPCAEigenvectors,
} from '../../features/analysisConfig/analysisConfigSlice';
import { defaultPlotlyArguments } from '../../compute/utils';
import { AryRecord, PCASet, pipeLabels, PipeType } from '../../types/analysisTypes';
import { Alert, Button, Col, Dropdown, Menu, Popover, Space, Tooltip } from 'antd';
import { ArrowsAltOutlined, DownOutlined } from '@ant-design/icons';
import { getGroupedMap, getRecordNameWithBrB, sum } from '../../compute/utils';
import { getCqs, getGaussianEllipse, getPcaFigure, scaleEllipsesSigma } from '../../compute/pca';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullscreenGraphicModal from './FullscreenGraphicModal';
import RecordsLegend from '../RecordsLegend';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../utils';

export const PCAPanel: FC<{ sessionID: string; disabledPdf?: boolean }> = (props: { sessionID: string; disabledPdf?: boolean }) => {
  const { sessionID, disabledPdf } = props;

  const { authState } = useOktaOrQueryAuth();

  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);

  const [pca, setPCA] = useState<PCASet | null>(null);

  const [effectiveRecords, setEffectiveRecords] = useState<AryRecord[]>([]);
  const [error, setError] = useState('');
  const [pcaEigenvectorsLocked, setPCAEigenvectorsLocked] = useState(false);

  const boundariesMap = useAppSelector(selectBoundariesMap);
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const records = useAppSelector(selectRecords);
  const cmap = useAppSelector(selectColormap);
  const subtractItemName = useAppSelector(selectSubtractItemName);
  const aggregatePeptides = useAppSelector(selectAggregatePeptides);
  const PCAEigenvalues = useAppSelector(selectPCAEigenvalues);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const excludedPeptides = useAppSelector(selectExcludedPeptides);
  const excludedFirstCycleParamState = useAppSelector(selectExcludedFirstCycleParamState);

  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);
  const humidityCalibrationPositionOffset = useAppSelector(selectHumidityCompensationPositionOffset);
  const humidityCalibrationSubstractionGain = useAppSelector(selectHumidityCompensationSubstractionGain);

  const chemicalCalibrationItemNames = useAppSelector(selectChemicalCalibrationItemNames);

  const sigmaMultiplier = useAppSelector(selectSigmaMultiplier);
  const [pipe, setPipe] = useState<PipeType>(PipeType.Normalized);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    fetchAuthorizedAPIEndpoint(`/compute/pca?session_id=${sessionID}&pipe=${pipe}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        boundariesMap,
        excludedRecordIDs,
        aggregatePeptides,
        subtractItemName,
        PCAEigenvectors: pcaEigenvectorsLocked && pca !== null ? pca.Vectors : null,
        excludedSpots,
        excludedPeptides,
        excludedFirstCycleParamState,

        humidityCompensation: {
          calibrantName: humidityCalibrationCalibrantName,
          positionOffset: humidityCalibrationPositionOffset,
          SubstractionGain: humidityCalibrationSubstractionGain,
        },
        chemicalCalibrationItemNames,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((receivedPCA: PCASet) => {
        if (records === undefined) {
          return;
        }
        let _effectiveRecords: AryRecord[] = [];
        records.forEach((r) => {
          if (!excludedRecordIDs.includes(r.ID) && r.ItemName !== subtractItemName) {
            _effectiveRecords.push(r);
          }
        });
        setEffectiveRecords(_effectiveRecords);
        if (receivedPCA) {
          receivedPCA.Projections.forEach((row, i) => {
            row.forEach((_, j) => {
              receivedPCA.Projections[i][j] *= 100;
            });
          });
          setPCA(receivedPCA);
          if (receivedPCA.Variances !== null) {
            // Only update variances (eigenvalues) if they come with the PCA
            // Otherwise just continue to use previous ones
            dispatch(setPCAEigenvalues(receivedPCA.Variances));
          }
          if (receivedPCA.Vectors !== null) {
            dispatch(setPCAEigenvectors(receivedPCA.Vectors));
          }
        }
        setError('');
      })
      .catch((e) => {
        Promise.resolve(e).then((resp: { Reason: string }) => {
          setError(resp.Reason);
        });
      });
  }, [
    authState,
    sessionID,
    boundariesMap,
    excludedRecordIDs,
    aggregatePeptides,
    subtractItemName,
    excludedSpots,
    excludedPeptides,
    records,
    excludedFirstCycleParamState,
    humidityCalibrationCalibrantName,
    humidityCalibrationPositionOffset,
    humidityCalibrationSubstractionGain,
    chemicalCalibrationItemNames,
    pipe,
  ]);

  if (error !== '') {
    return (
      <div>
        <h3 style={{ margin: 'auto', marginBottom: 10, textAlign: 'center' }}>PCA</h3>
        <Alert type="error" message={error} style={{ borderRadius: 5 }} />
      </div>
    );
  }

  if (pca === null || pca === undefined || cmap === undefined) {
    return null;
  }

  if (records === undefined) {
    return null;
  }

  try {
    // Regroup projections by label (clusterize) and compute the ellipses
    const groupedProjections: Record<string, number[][]> = getGroupedMap(pca.Labels, pca.Projections);
    const groupedEffectiveRecords: Record<string, AryRecord[]> = getGroupedMap(pca.Labels, effectiveRecords);

    const groupedEllipses: Record<string, number[]> = {};
    Object.entries(groupedProjections).forEach(([label, projs]) => {
      groupedEllipses[label] = getGaussianEllipse(projs);
    });

    // Optimize the ellipses (or just scale them with a custom sigma value if the user has provided one)
    const optimizedGroupedEllipses = scaleEllipsesSigma(groupedEllipses, sigmaMultiplier);

    let pcaEigenvaluesSum = sum(PCAEigenvalues);
    const pcaExplainedVarianceRatio = PCAEigenvalues.map((e) => e / pcaEigenvaluesSum);

    const pcaFigure = getPcaFigure(``, cmap, pcaExplainedVarianceRatio, groupedProjections, groupedEllipses, optimizedGroupedEllipses);

    const cqs = getCqs(pca.Projections, pca.Labels);

    let data = pcaFigure.data as Plotly.ScatterData[];
    let layout = pcaFigure.layout as Plotly.Layout;

    if (!isNaN(cqs)) {
      layout = {
        ...layout,
        ...{
          title: {
            text: `Global CQS: ${cqs.toFixed(1)}%`,
            font: {
              size: 14,
            },
          },
        },
      };
    }

    data.forEach((tr) => {
      let cycles = groupedEffectiveRecords[tr.name].map((r) => r.CycleNumber);
      let ids = groupedEffectiveRecords[tr.name].map((r) => r.ID);
      tr.hoverinfo = 'text';
      tr.text = cycles.map((c, i) => {
        let record: Partial<AryRecord> = {
          ItemName: tr.name,
          CycleNumber: c,
          ID: ids[i] as number,
        };
        return getRecordNameWithBrB(record);
      });
    });

    return (
      <div tabIndex={0} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
        <h3 style={{ margin: 'auto', marginBottom: 10 }}>PCA</h3>
        <Col style={{ marginBottom: 10 }}>
          <Space align="center" style={{ display: 'flex', justifyContent: 'center' }}>
            <Dropdown
              overlay={
                <Menu
                  items={Object.entries(PipeType).map(([label, value]) => {
                    return {
                      key: label,
                      label: pipeLabels[value],
                      onClick: () => {
                        setPipe(value);
                      },
                    };
                  })}
                />
              }
            >
              <Tooltip title="Pipe">
                <Button style={{ borderRadius: '5px' }}>
                  <Space>
                    {pipeLabels[pipe]}
                    <DownOutlined />
                  </Space>
                </Button>
              </Tooltip>
            </Dropdown>
          </Space>
        </Col>
        {!disabledPdf && (
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-14pt', zIndex: 800 }}>
            <Popover
              style={{ display: 'flex' }}
              trigger={'hover'}
              content={
                <div>
                  <p>
                    {(function () {
                      switch (pcaEigenvectorsLocked) {
                        case false:
                          return (
                            <span>
                              PCA axes are <b>unlocked</b>, new signatures will <b>force it to update</b>
                            </span>
                          );
                        case true:
                          return (
                            <span>
                              PCA axes are <b>locked</b>, newly calculated signatures will be <b>only projected</b> onto them
                            </span>
                          );
                      }
                    })()}
                  </p>
                </div>
              }
            >
              {(function () {
                switch (pcaEigenvectorsLocked) {
                  case false:
                    return <FontAwesomeIcon icon="unlock-alt" onClick={() => setPCAEigenvectorsLocked(true)} className="clickable-icon" />;
                  case true:
                    return <FontAwesomeIcon icon="lock" onClick={() => setPCAEigenvectorsLocked(false)} className="clickable-icon" />;
                }
              })()}
            </Popover>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              {chemicalCalibrationItemNames && chemicalCalibrationItemNames.length > 0 && (
                <Popover style={{ display: 'flex' }} trigger={'hover'} content="Chemical calibration is active">
                  <FontAwesomeIcon icon="balance-scale" style={{ marginRight: 10, fontSize: '13pt' }} />
                </Popover>
              )}
              {humidityCalibrationCalibrantName && (
                <Popover style={{ display: 'flex' }} trigger={'hover'} content="Humidity correction is active">
                  <FontAwesomeIcon icon="droplet" style={{ marginRight: 10, fontSize: '13pt' }} />
                </Popover>
              )}
              <ArrowsAltOutlined className="clickable-icon" onClick={() => setIsVisibleModal(true)} />
            </Col>
          </div>
        )}
        <Plot
          divId="pca_scatter_plot"
          debug={true}
          data={data}
          layout={{
            ...defaultPlotlyArguments.layout,
            ...layout,
          }}
          useResizeHandler={true}
          config={defaultPlotlyArguments.config}
          style={defaultPlotlyArguments.style}
        />
        <FullscreenGraphicModal title="PCA" visible={isVisibleModal} onCancel={() => setIsVisibleModal(false)}>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Plot
              divId="pca_scatter_plot"
              debug={true}
              data={data}
              layout={{
                ...defaultPlotlyArguments.layout,
                ...layout,
              }}
              useResizeHandler={true}
              config={defaultPlotlyArguments.config}
              // Specific styles as we want to keep PCA square even in the modal view
              style={{ ...defaultPlotlyArguments.style, width: undefined, aspectRatio: '4/3' }}
            />
            <RecordsLegend />
          </div>
        </FullscreenGraphicModal>
      </div>
    );
  } catch (e) {
    console.log(e);
    return null;
  }
};
