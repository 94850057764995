import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { RunMetadata, TSDBRef } from '../../types/runsType';

type runsType = {
  runsMap: Record<TSDBRef['Name'], RunMetadata[]>;
  chosenTSDB: TSDBRef | null | undefined;
  startPeriod: string;
  availableTSDB: TSDBRef[] | undefined;
};

const initialState: runsType = {
  runsMap: {},
  chosenTSDB: null,
  startPeriod: '-2w',
  availableTSDB: undefined,
};

export const runsSlice = createSlice({
  name: 'runs',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setRunsMap: (state, action: PayloadAction<Record<TSDBRef['Name'], RunMetadata[]>>) => {
      state.runsMap = action.payload;
    },
    setAvailableTSDB: (state, action: PayloadAction<TSDBRef[]>) => {
      state.availableTSDB = action.payload;
    },
    setChosenTSDB: (state, action: PayloadAction<TSDBRef | null | undefined>) => {
      state.chosenTSDB = action.payload;
    },
    setStartPeriod: (state, action: PayloadAction<string>) => {
      state.startPeriod = action.payload;
    },
  },
});

export const { setRunsMap, setChosenTSDB, setStartPeriod, setAvailableTSDB } = runsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRunsMap = (state: RootState) => state.runs.runsMap;
export const selectChosenTSDB = (state: RootState) => state.runs.chosenTSDB;
export const selectStartPeriod = (state: RootState) => state.runs.startPeriod;
export const selectAvailableTSDB = (state: RootState) => state.runs.availableTSDB;

export default runsSlice.reducer;
