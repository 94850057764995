import { InputNumber } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectRecords, selectThresholdIntensity, setThresholdIntensity } from '../../../features/analysisConfig/analysisConfigSlice';
import { FeatureFlag, UserClaimsWithTSDB } from '../../../types/userType';

type ThresholdIntensitySettingsProps = {
  userInfo: UserClaimsWithTSDB | null;
};
const ThresholdIntensitySettings: React.FC<ThresholdIntensitySettingsProps> = ({ userInfo }) => {
  const dispatch = useAppDispatch();
  const records = useAppSelector(selectRecords);
  const threshold = useAppSelector(selectThresholdIntensity);

  const uniqueItemNames = Array.from(new Set(records?.map((r) => r.ItemName)));
  uniqueItemNames.sort();

  return (
    <div style={{ marginBottom: 30 }}>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <span>Threshold intensity (%)</span>
      </div>
      <InputNumber
        disabled={userInfo === null || userInfo.feature_flags.includes(FeatureFlag.AALightViewQualityControl)}
        value={threshold}
        style={{ width: '100%', marginTop: 10, borderRadius: '2px' }}
        defaultValue={10}
        placeholder="10"
        onChange={(value: number) => {
          dispatch(setThresholdIntensity(value));
        }}
      />
    </div>
  );
};

export default ThresholdIntensitySettings;
