import { useOktaAuth } from '@okta/okta-react';
import { UserClaims } from '@okta/okta-auth-js';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Popover } from 'antd';
import { fetchAuthorizedAPIEndpoint } from '../../utils';

type UserInfoWidgetProps = {};

const UserInfoWidget: React.FC<UserInfoWidgetProps> = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaims | null>(null);

  const [licenseEndTs, setLicenseEndTs] = useState<number | null | undefined>(undefined);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims);
      }
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    fetchAuthorizedAPIEndpoint('/user/get_trial_period', authState)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((data: { LicenseEndTs: number }) => {
        setLicenseEndTs(data.LicenseEndTs);
      });
  }, [authState, oktaAuth]);

  if (userInfo === null) {
    return null;
  }

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      overlayInnerStyle={{ borderRadius: 5 }}
      title={
        <div style={{ textAlign: 'center' }}>
          <span style={{ fontWeight: 'bold' }}>{userInfo.name}</span>
          <br />
          <span>
            <i>{userInfo.email}</i>
          </span>
        </div>
      }
      content={
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="primary"
              block
              onClick={() => {
                return oktaAuth.signOut();
              }}
            >
              Logout
            </Button>
          </div>
          <div style={{ textAlign: 'center', marginTop: 10 }}>
            {licenseEndTs ? (
              <>
                License valid through <b>{new Date(licenseEndTs).toDateString()}</b>
              </>
            ) : null}
          </div>
          <div style={{ textAlign: 'center', marginTop: 10 }}>
            <p style={{ fontSize: 12, marginBottom: 0 }}>
              Digital Olfaction Hub <b>{process.env.REACT_APP_VERSION}</b>
            </p>
            <p style={{ fontSize: 12, marginBottom: 0 }}>© 2022 Aryballe</p>
          </div>
        </>
      }
    >
      <FontAwesomeIcon icon="user-cog" style={{ width: 20, height: 20, cursor: 'pointer' }} />
    </Popover>
  );
};

export default UserInfoWidget;
