import { CrumbsType } from '../components/breadcrumb/Breadcrumb';
import { RunMetadata } from './runsType';

export interface SessionInfoType {
  Crumbs: CrumbsType[];
  VisibleSettings: boolean;
  VisibleDeleteRuns: boolean;
}

export interface SessionUser {
  ID: string;
  Email: string;
  GivenName: string;
  FamilyName: string;
}

export interface Session {
  ID: string;
  User: SessionUser;
  IsLocked: boolean;
  CreatedAt: string;
  UpdatedAt: string;
  Description: string;
  Runs: RunMetadata[];
  DiskSize: number;
}

export enum SessionType {
  Analysis = 'Analysis',
  QualityControl = 'Quality control',
}
