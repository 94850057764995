import { AryRecord } from '../../types/analysisTypes';
import AnnexSensogram from './annexSensogram';

type AnnexPdfProps = {
  effectiveRecords: AryRecord[];
};

const AnnexPdf: React.FC<AnnexPdfProps> = ({ effectiveRecords }) => {
  return (
    <>
      <AnnexSensogram effectiveRecords={effectiveRecords} />
    </>
  );
};

export default AnnexPdf;
