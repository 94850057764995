import { Alert, Col, Row, Switch, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { HtmlDescription } from '../../components/HtmlDescription';
import { selectIsIncludeExperimentPlan, setIsIncludeExperimentPlan } from '../../features/analysisConfig/pdfSlice';
import { RunMetadata } from '../../types/runsType';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../utils';
import Pagebreak from './pageBreak';
import { saveParamsInDatabase } from './pdfContainer';

type ExperimentPlanPdfProps = {};

const ExperimentPlanPdf: React.FC<ExperimentPlanPdfProps> = () => {
  const { authState } = useOktaOrQueryAuth();
  const [runs, setRuns] = useState<RunMetadata[]>([]);
  const { sessionID } = useParams<{ sessionID: string }>();
  const includeExperimentPlan = useAppSelector(selectIsIncludeExperimentPlan);

  const dispatch = useAppDispatch();

  // Load runs
  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    if (sessionID !== '') {
      fetchAuthorizedAPIEndpoint(`/runs?session_id=${sessionID}`, authState)
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            throw new Error();
          }
        })
        .then((runs: RunMetadata[]) => {
          if (runs != null) {
            setRuns(runs);
            console.log(runs);
          }
        });
    }
  }, [authState, sessionID]);

  return (
    <div id="experimentPlanPdf">
      <div className="pdf-invisible" style={{ marginTop: 20, marginBottom: 20 }}>
        <Row>
          <Col style={{ marginRight: 10 }}>
            <Switch
              checked={includeExperimentPlan}
              onChange={() => {
                dispatch(setIsIncludeExperimentPlan(!includeExperimentPlan));
                saveParamsInDatabase({ includeExperimentPlan: !includeExperimentPlan }, sessionID, authState);
              }}
            ></Switch>
          </Col>
          <Col>Include experiment plan</Col>
        </Row>
      </div>
      {includeExperimentPlan && (
        <div>
          <Row justify="start">
            <Col>
              <Typography.Title level={4}>2.3 Experiment plan</Typography.Title>
            </Col>
          </Row>
          <Row justify="start">
            <Col xs={24}>
              {runs.map((run) =>
                run.ExperimentPlan.length !== 0 ? (
                  <>
                    <h3>{run.ID}</h3>
                    <pre style={{ whiteSpace: 'pre-wrap', marginBottom: 50 }}>
                      <HtmlDescription style={{ fontSize: 16 }}>{run.ExperimentPlan.replace(/\\r\\n/g, '<br />').replace(/\\n/g, '<br />')}</HtmlDescription>
                    </pre>
                  </>
                ) : (
                  <Alert description="The plan of this run is not available." type="info" style={{ borderRadius: 5 }} />
                )
              )}
            </Col>
          </Row>
          <Pagebreak />
        </div>
      )}
    </div>
  );
};

export default ExperimentPlanPdf;
