import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import analysisConfigReducer from '../features/analysisConfig/analysisConfigSlice';
import sessionInfoReducer from '../features/analysisConfig/sessionInfoSlice';
import pdfReducer from '../features/analysisConfig/pdfSlice';
import runsReducer from '../features/analysisConfig/runsSlice';

export const store = configureStore({
  reducer: {
    analysisConfig: analysisConfigReducer,
    sessionInfo: sessionInfoReducer,
    pdf: pdfReducer,
    runs: runsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
