import Plot from 'react-plotly.js';
import { chemicalFamiliesColorPalette } from '../../../compute/colormap';
import { defaultPlotlyArguments } from '../../../compute/utils';
import { PredictedChemicalFamily } from '../../../types/analysisTypes';

export const ChemicalFamiliesPlot: React.FC<{ chemicalFamilies: PredictedChemicalFamily }> = (props: { chemicalFamilies: PredictedChemicalFamily }) => {
  const { chemicalFamilies } = props;
  return (
    <Plot
      data={[
        {
          type: 'pie',
          values: chemicalFamilies.Probabilities,
          labels: chemicalFamilies.Targets.map((t) => t.Name),
          textinfo: 'label',
          insidetextorientation: 'radial',
          automargin: false,
          marker: {
            colors: chemicalFamilies.Targets.map((t) => chemicalFamiliesColorPalette[t.Name]),
          },
        },
      ]}
      useResizeHandler={true}
      layout={defaultPlotlyArguments.layout}
      config={defaultPlotlyArguments.config}
      style={defaultPlotlyArguments.style}
    />
  );
};
