import { useEffect, useState } from 'react';
import { useAppSelector } from '../app/hooks';
import { defaultColorPalette } from '../compute/colormap';
import { renderColoredTag } from '../compute/utils';
import { selectColormap, selectColormapRef, selectExcludedRecordIDs, selectRecords } from '../features/analysisConfig/analysisConfigSlice';
import { AryRecord, ComparisonMeasuresType } from '../types/analysisTypes';

type RecordsLegendProps = {
  qualityControlMode?: boolean;
};

const RecordsLegend: React.FC<RecordsLegendProps> = ({ qualityControlMode }) => {
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const records = useAppSelector(selectRecords);
  const [effectiveRecordsLabels, setEffectiveLabels] = useState<string[]>();
  const cmap = useAppSelector(selectColormap);
  const cmapRef = useAppSelector(selectColormapRef);
  const [_cmap, setCmap] = useState<Record<string, string> | undefined>();

  useEffect(() => {
    if (qualityControlMode && cmapRef !== undefined) {
      setCmap({ ...cmapRef[ComparisonMeasuresType.Reference], ...cmapRef[ComparisonMeasuresType.Test], ...cmapRef[ComparisonMeasuresType.Other] });
    } else if (cmap !== undefined) {
      setCmap(cmap);
    }
  }, [cmap, cmapRef, qualityControlMode]);

  useEffect(() => {
    if (records != null) {
      let _effectiveRecords: AryRecord[] = [];
      records.forEach((r) => {
        if (!excludedRecordIDs.includes(r.ID)) _effectiveRecords.push(r);
      });
      const effectiveRecordsLabels = Array.from(new Set(_effectiveRecords.map((r) => r.ItemName))).sort();
      setEffectiveLabels(effectiveRecordsLabels);
    }
  }, [excludedRecordIDs, records]);

  if (_cmap === undefined) return null;

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', marginTop: 50, flexWrap: 'wrap' }}>
      {effectiveRecordsLabels?.map((itemName) => renderColoredTag(qualityControlMode ? _cmap[itemName] : defaultColorPalette[_cmap[itemName]], <>{itemName}</>))}
    </div>
  );
};

export default RecordsLegend;
