import { UserClaims } from '@okta/okta-auth-js/lib/types';
import { AccessToken } from '@okta/okta-auth-js/lib/types';

export enum FeatureFlag {
  ReltimeSensogramEnabledDepr = 'realtime_sensorgram',
  RunExperimentEnabled = 'run_experiment',
  RoleAryballe = 'role_aryballe',
  FullSensogramEnabled = 'full_sensorgram',
  AANonAggregatedPeptidesAccessEnabled = 'aa_access_to_nonaggregated_peptides',
  AAChemicalFamiliesAccessEnabled = 'aa_access_to_chemical_families',
  AAHumidityCompensationAccessEnabled = 'aa_humidity_compensation',
  AAChemicalCalibrationAccessEnabled = 'aa_access_to_chemical_calibration',
  AADeleteRunsEnabled = 'aa_delete_runs',
  AALightViewQualityControl = 'aa_light_view_quality_control',
}

export enum SaasAccessType {
  Authorized = 'authorized',
  Expired = 'expired',
}

export interface UserClaimsWithTSDB extends UserClaims {
  tsdb_url: string;
  tsdb_token: string;
  tsdb_org: string;
  main_tsdb_name: string;
  tsdb_refs: string[];
  feature_flags: FeatureFlag[];
  saas_access: SaasAccessType | undefined;
  saas_exp_timestamp: number | undefined;
}

export interface CustomAuthState {
  accessToken?: Partial<AccessToken>;
  isAuthenticated?: boolean;
}

export interface IOktaOrQueryAuth {
  authState: CustomAuthState | null;
}
