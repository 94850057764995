import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { useState } from 'react';
import { useMediaQuery } from '../../App';
import { getAPINodeEndpoint } from '../../endpoint';
import {} from '../../features/analysisConfig/pdfSlice';
import { useOktaOrQueryAuth } from '../../utils';

type ButtonExportPdfProps = {
  sessionID: string;
};

const ButtonExportPdf: React.FC<ButtonExportPdfProps> = ({ sessionID }) => {
  const isRowBased = useMediaQuery('(max-width: 768px)');
  const isCollapsed = true;
  const [loading, setLoading] = useState<boolean>(false);
  const { authState } = useOktaOrQueryAuth();

  const exportPDF = async () => {
    setLoading(true);
    const data = await fetch(`${getAPINodeEndpoint()}/export/pdf?accessToken=${authState?.accessToken?.accessToken}&url=${encodeURI(`${sessionID}/pdf`)}`);
    setLoading(false);
    window.open(window.URL.createObjectURL(await data.blob()), '_blank');
  };

  return (
    <Row justify="end">
      <Col className="pdf-invisible">
        <Header
          style={{
            background: 'white',
            height: 50,
            lineHeight: '50px',
            padding: '0 30px',
            position: 'fixed',
            bottom: isRowBased ? 48 : 0,
            right: 0,
            width: isRowBased ? '100%' : `calc(100% - ${isCollapsed ? '60px' : '240px'})`,
            zIndex: 2,
            boxShadow: '1px -1px 4px #00000010',
          }}
        >
          <Row align="middle" justify="end" style={{ height: '100%' }}>
            {loading ? (
              <Button type="primary">
                <LoadingOutlined />
              </Button>
            ) : (
              <Button type="primary" onClick={exportPDF}>
                Generate PDF
              </Button>
            )}
          </Row>
        </Header>
      </Col>
    </Row>
  );
};

export default ButtonExportPdf;
