import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { CloudDBPage } from './routes/clouddb_page';
import { CSVPage } from './routes/csv_page';
import { DashboardPage } from './routes/dashbord/dashboard';
import { HomePage } from './routes/home/home';
import { SessionsPage } from './routes/sessions';
import PdfContainer from './routes/pdf/pdfContainer';
import { SQLitePage } from './routes/sqlite_page';
import { StatsPage } from './routes/stats';
import TrialPage from './routes/trial';
import { UploadPage } from './routes/upload_page';

export type MenuEntry = {
  label: string;
  icon: IconProp;
  route: AppRoute;
  Component: React.FC;
};

export enum AppRoute {
  DASHBOARD = '/',
  TRIAL = '/main',
  SOURCES = '/sources',
  SESSIONS = '/sessions',
  CLOUD = '/clouddb',
  SQLITE = '/localdb',
  HTML = '/htmlreport',
  ANALYSE = '/dashboard/:sessionID',
  PDF = '/dashboard/:sessionID/pdf',
  CSV = '/dashboard/:sessionID/csv',
}

export const defaultMenuRoute = AppRoute.DASHBOARD;

const iconForAppRoute = {
  [AppRoute.DASHBOARD]: 'dashboard' as IconName,
  [AppRoute.SOURCES]: 'cloud' as IconName,
  [AppRoute.SESSIONS]: 'save' as IconName,
  [AppRoute.CLOUD]: 'search' as IconName,
  [AppRoute.SQLITE]: 'database' as IconName,
  [AppRoute.HTML]: 'file-upload' as IconName,
};

export const sideMenuContent: MenuEntry[] = [
  {
    label: 'Home',
    icon: iconForAppRoute[AppRoute.DASHBOARD],
    route: AppRoute.DASHBOARD,
    Component: HomePage,
  },
  {
    label: 'Manage Data Sources',
    icon: iconForAppRoute[AppRoute.SOURCES],
    route: AppRoute.SOURCES,
    Component: StatsPage,
  },
  {
    label: 'Saved Sessions',
    icon: iconForAppRoute[AppRoute.SESSIONS],
    route: AppRoute.SESSIONS,
    Component: SessionsPage,
  },
  {
    label: 'Browse Olfactive Data',
    icon: iconForAppRoute[AppRoute.CLOUD],
    route: AppRoute.CLOUD,
    Component: CloudDBPage,
  },
];

export const sideMenu2Content: MenuEntry[] = [
  {
    label: 'Browse Local Storage',
    icon: '' as IconName,
    route: AppRoute.SQLITE,
    Component: SQLitePage,
  },
  {
    label: 'HTML Report',
    icon: '' as IconName,
    route: AppRoute.HTML,
    Component: UploadPage,
  },
];

export const sideRouteContent: MenuEntry[] = [
  ...sideMenuContent,
  ...sideMenu2Content,
  {
    label: 'Analyze :sessionID',
    icon: '' as IconName,
    route: AppRoute.ANALYSE,
    Component: DashboardPage,
  },
  {
    label: 'PDF',
    icon: '' as IconName,
    route: AppRoute.PDF,
    Component: PdfContainer,
  },
  {
    label: 'CSV',
    icon: '' as IconName,
    route: AppRoute.CSV,
    Component: CSVPage,
  },
  {
    label: 'Trial',
    icon: '' as IconName,
    route: AppRoute.TRIAL,
    Component: TrialPage,
  },
];

export const getTrueDashboardRoute = (sessionID: string, route: string) => {
  return route.includes(':sessionID') ? route.replaceAll(':sessionID', sessionID) : route;
};
export const getTrueDashboardLabel = (sessionID: string, label: string) => {
  return label.includes(':sessionID') ? label.replaceAll(':sessionID', sessionID) : label;
};
