import { Col, Input, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectStudySubtitle, selectStudyTitle, setStudySubtitle, setStudyTitle } from '../../features/analysisConfig/pdfSlice';
import { useOktaOrQueryAuth } from '../../utils';
import { saveParamsInDatabase } from './pdfContainer';

type titlePdfProps = {};

const TitlePdf: React.FC<titlePdfProps> = () => {
  const studyTitle = useAppSelector(selectStudyTitle);
  const studySubtitle = useAppSelector(selectStudySubtitle);
  const dispatch = useAppDispatch();

  const { sessionID } = useParams<{ sessionID: string }>();
  const { authState } = useOktaOrQueryAuth();

  return (
    <div id="titlePdf">
      <Row justify="center">
        <Col xs={24}>
          <Input.TextArea
            size="large"
            bordered={false}
            autoSize={true}
            style={{ fontWeight: '600', fontSize: '2em', width: '100%', textAlign: 'center', marginBottom: 10 }}
            placeholder="Enter study title.."
            onChange={(e) => dispatch(setStudyTitle(e.target.value))}
            onBlur={(e) => saveParamsInDatabase({ studyTitle: e.target.value }, sessionID, authState)}
            value={studyTitle}
          ></Input.TextArea>
        </Col>
        <Col xs={24} className={!studySubtitle ? 'pdf-invisible' : undefined}>
          <Input.TextArea
            size="large"
            bordered={false}
            autoSize={true}
            style={{ fontWeight: '500', fontSize: '18px', width: '100%', textAlign: 'center', marginBottom: 25, paddingBottom: 30 }}
            placeholder="Enter study subtitle"
            onChange={(e) => dispatch(setStudySubtitle(e.target.value))}
            onBlur={(e) => saveParamsInDatabase({ studySubtitle: e.target.value }, sessionID, authState)}
            value={studySubtitle}
          ></Input.TextArea>
        </Col>
        <Col className="pdf-invisible" style={{ textAlign: 'center', marginBottom: 10 }}>
          <i>
            <b>Note:</b> you can modify titles, fields, captions and even draw on top of charts. This note won't appear in the final document.
          </i>
        </Col>
      </Row>
    </div>
  );
};

export default TitlePdf;
