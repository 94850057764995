import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from '../../app/store';

export type PdfType = {
  studyTitle?: string | undefined;
  company?: string | undefined;
  division?: string | undefined;
  author?: string | undefined;
  generationDate?: string | undefined;
  abstract?: string | undefined;
  summaryRuns?: string | undefined;
  summaryRecords?: string | undefined;
  observationsSensogram?: string | undefined;
  observationsSignatures?: string | undefined;
  observationsDiscrimination?: string | undefined;
  observationsConfusion?: string | undefined;
  observationsIntensity?: string | undefined;
  includeSensogramsAnnex?: boolean;
  includeConfusionMatrix?: boolean;
  drawHumidity?: boolean;
  drawTemperature?: boolean;
  observationsSingleSensogram?: string | undefined;
  studySubtitle?: string | undefined;
  includeExperimentPlan?: boolean;
};

const initialState: PdfType = {
  studyTitle: undefined,
  company: undefined,
  division: undefined,
  author: undefined,
  generationDate: moment(new Date(), 'YYYY-MM-DD').format('YYYY/MM/DD'),
  abstract: undefined,
  summaryRuns: undefined,
  summaryRecords: undefined,
  observationsSensogram: undefined,
  observationsSignatures: undefined,
  observationsDiscrimination: undefined,
  observationsConfusion: undefined,
  observationsIntensity: undefined,
  includeSensogramsAnnex: false,
  includeConfusionMatrix: false,
  drawHumidity: false,
  drawTemperature: false,
  observationsSingleSensogram: undefined,
  studySubtitle: undefined,
  includeExperimentPlan: true,
};

export const pdfSlice = createSlice({
  name: 'pdf',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setStudyTitle: (state, action: PayloadAction<string | undefined>) => {
      state.studyTitle = action.payload;
    },
    setCompany: (state, action: PayloadAction<string | undefined>) => {
      state.company = action.payload;
    },
    setDivision: (state, action: PayloadAction<string | undefined>) => {
      state.division = action.payload;
    },
    setAuthor: (state, action: PayloadAction<string | undefined>) => {
      state.author = action.payload;
    },
    setGenerationDate: (state, action: PayloadAction<string | undefined>) => {
      state.generationDate = action.payload;
    },
    setAbstract: (state, action: PayloadAction<string | undefined>) => {
      state.abstract = action.payload;
    },
    setSummaryRuns: (state, action: PayloadAction<string | undefined>) => {
      state.summaryRuns = action.payload;
    },
    setSummaryRecords: (state, action: PayloadAction<string | undefined>) => {
      state.summaryRecords = action.payload;
    },
    setObservationsSensogram: (state, action: PayloadAction<string | undefined>) => {
      state.observationsSensogram = action.payload;
    },
    setObservationsSignatures: (state, action: PayloadAction<string | undefined>) => {
      state.observationsSignatures = action.payload;
    },
    setObservationsDiscrimination: (state, action: PayloadAction<string | undefined>) => {
      state.observationsDiscrimination = action.payload;
    },
    setObservationsConfusion: (state, action: PayloadAction<string | undefined>) => {
      state.observationsConfusion = action.payload;
    },
    setObservationsIntensity: (state, action: PayloadAction<string | undefined>) => {
      state.observationsIntensity = action.payload;
    },
    setIsIncludeSensogramsAnnex: (state, action: PayloadAction<boolean | undefined>) => {
      state.includeSensogramsAnnex = action.payload;
    },
    setIsIncludeConfusionMatrix: (state, action: PayloadAction<boolean | undefined>) => {
      state.includeConfusionMatrix = action.payload;
    },
    setIsDrawHumidity: (state, action: PayloadAction<boolean>) => {
      state.drawHumidity = action.payload;
    },
    setIsDrawTemperature: (state, action: PayloadAction<boolean>) => {
      state.drawTemperature = action.payload;
    },
    setObservationsSingleSensogram: (state, action: PayloadAction<string | undefined>) => {
      state.observationsSingleSensogram = action.payload;
    },
    setStudySubtitle: (state, action: PayloadAction<string | undefined>) => {
      state.studySubtitle = action.payload;
    },
    setIsIncludeExperimentPlan: (state, action: PayloadAction<boolean | undefined>) => {
      state.includeExperimentPlan = action.payload;
    },
    resetPdfConfig: (state) => {
      return initialState;
    },
  },
});

export const {
  setStudyTitle,
  setCompany,
  setDivision,
  setAuthor,
  setGenerationDate,
  setAbstract,
  setSummaryRuns,
  setSummaryRecords,
  setObservationsSensogram,
  setObservationsSignatures,
  setObservationsDiscrimination,
  setObservationsConfusion,
  setObservationsIntensity,
  setIsIncludeSensogramsAnnex,
  setIsIncludeConfusionMatrix,
  setIsDrawHumidity,
  setIsDrawTemperature,
  setObservationsSingleSensogram,
  setStudySubtitle,
  setIsIncludeExperimentPlan,
  resetPdfConfig,
} = pdfSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectStudyTitle = (state: RootState) => state.pdf.studyTitle;
export const selectCompany = (state: RootState) => state.pdf.company;
export const selectDivision = (state: RootState) => state.pdf.division;
export const selectAuthor = (state: RootState) => state.pdf.author;
export const selectGenerationDate = (state: RootState) => state.pdf.generationDate;
export const selectAbstract = (state: RootState) => state.pdf.abstract;
export const selectSummaryRuns = (state: RootState) => state.pdf.summaryRuns;
export const selectSummaryRecords = (state: RootState) => state.pdf.summaryRecords;
export const selectObservationsSensogram = (state: RootState) => state.pdf.observationsSensogram;
export const selectObservationsSignatures = (state: RootState) => state.pdf.observationsSignatures;
export const selectObservationsDiscrimination = (state: RootState) => state.pdf.observationsDiscrimination;
export const selectObservationsConfusion = (state: RootState) => state.pdf.observationsConfusion;
export const selectObservationsIntensity = (state: RootState) => state.pdf.observationsIntensity;
export const selectIsIncludeSensogramsAnnex = (state: RootState) => state.pdf.includeSensogramsAnnex;
export const selectIsIncludeConfusionMatrix = (state: RootState) => state.pdf.includeConfusionMatrix;
export const selectIsDrawHumidity = (state: RootState) => state.pdf.drawHumidity;
export const selectIsDrawTemperature = (state: RootState) => state.pdf.drawTemperature;
export const selectObservationsSingleSensogram = (state: RootState) => state.pdf.observationsSingleSensogram;
export const selectStudySubtitle = (state: RootState) => state.pdf.studySubtitle;
export const selectIsIncludeExperimentPlan = (state: RootState) => state.pdf.includeExperimentPlan;

export default pdfSlice.reducer;
