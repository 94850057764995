export const isInternalTSDB = (tsdbURL?: string) => {
  return tsdbURL !== undefined && tsdbURL.match(/influxdb.prod.ary/gi) !== null;
};

export const EXTERNAL_BACKEND_ENDPOINT_MAMBO = `https://mambo.hub.aryballe.com/goapi`
export const EXTERNAL_BACKEND_ENDPOINT_STAGING = `https://staging.api.hub.aryballe.com/api`;
export const EXTERNAL_BACKEND_ENDPOINT_PROD = `https://prod.api.hub.aryballe.com/api`;

export const getEffectiveExternalEndpoint = () => {
  if (window.location.hostname === 'localhost') {
    return `http://${window.location.hostname}:8105`; // Substitute port and force HTTP
  } else {
    return EXTERNAL_BACKEND_ENDPOINT_PROD;
  }
};

export const getAPIEndpoint = (tsdbURL?: string) => {
  if (window.location.hostname === 'localhost') {
    return `http://${window.location.hostname}:8105`; // Substitute port
  }

  if (window.location.hostname === 'client') {
    return `http://server:8105`; // Substitute port
  }

  if (window.location.host.match(/^mambo/i)) {
    return EXTERNAL_BACKEND_ENDPOINT_MAMBO;
  }

  if (window.location.host.match(/^staging/i)) {
    return EXTERNAL_BACKEND_ENDPOINT_STAGING;
  }

  return EXTERNAL_BACKEND_ENDPOINT_PROD;
};

export const getAPINodeEndpoint = () => {
  if (window.location.hostname === 'localhost') {
    return `http://${window.location.hostname}:8001`; // Substitute port
  }
  if (window.location.host.match(/^staging/i)) {
    return `https://staging.pdf.hub.aryballe.com/puppeteer`;
  } else {
    return `https://prod.pdf.hub.aryballe.com/puppeteer`;
  }
};
