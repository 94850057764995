import { useOktaAuth } from '@okta/okta-react';
import { Button, Card, Checkbox, Col, Collapse, Row, Select, Tag, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import SectionPage from '../components/section/SectionPage';
import { TableSkeleton } from '../components/table_skeleton';
import { defaultColorPalette, getColormap } from '../compute/colormap';
import { getPeptideSetType, renderColoredTag, renderDeviceID, renderFwVersion, renderPeptideSetType, renderSwVersion, renderTimestamp } from '../compute/utils';
import { PeptideSet } from '../types/analysisTypes';
import { RunMetadata, TSDBRef } from '../types/runsType';
import { UserClaimsWithTSDB } from '../types/userType';
import { fetchAuthorizedAPIEndpoint } from '../utils';

interface RecentRuns {
  TSDBRef: TSDBRef;
  RunMetadatas: RunMetadata[];
}

export const StatsPage: FC = () => {
  const { authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);

  const [availableTSDBRefs, setAvailableTSDBRefs] = useState<TSDBRef[]>([]);
  const [startParam, setStartParam] = useState('-2w');
  const [allRecentRuns, setAllRecentRuns] = useState<RecentRuns[]>([]);
  const [isRunsListLoading, setIsRunsListLoading] = useState(true);

  const [shouldIncludeInternalDBs, setShouldIncludeInternal] = useState(false);

  const promiseTolistRecentRuns = (tsdbRef: TSDBRef) => {
    return new Promise<RecentRuns>((resolve, reject) => {
      fetchAuthorizedAPIEndpoint(`/clouddb/list_runs?start=${startParam}`, authState, {
        method: 'POST',
        body: JSON.stringify(tsdbRef),
      })
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            resolve({
              TSDBRef: tsdbRef,
              RunMetadatas: [],
            });
          }
        })
        .then((receivedRuns: RunMetadata[] | null) => {
          if (receivedRuns === null) {
            receivedRuns = [];
          }
          resolve({
            TSDBRef: tsdbRef,
            RunMetadatas: receivedRuns,
          });
        })
        .catch((e: Error) => {
          console.log(e.name, e.cause, e.message);
          reject(e);
        });
    });
  };

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState]);

  useEffect(() => {
    if (userInfo === null) {
      return;
    }
    if (userInfo !== null) {
      var _tsdbRefs: TSDBRef[] = [];
      userInfo.tsdb_refs.forEach((tsdbRefStr) => {
        let [name, url, token] = tsdbRefStr.split('~');
        let org: string = '';
        let urlOrgRe = /(.*?)\/orgs\/(.*?)$/;
        let m = url.match(urlOrgRe);
        if (m !== null) {
          url = m[1]; // 0 is the full match
          org = m[2];
        }
        if (name && url && token && org) {
          let tsdbRef: TSDBRef = {
            Name: name,
            URL: url,
            Org: org,
            Token: token,
          };
          _tsdbRefs.push(tsdbRef);
        } else {
          console.log('Failed to parse tsdb ref: ', { tsdbRefStr, name, url, org, token });
        }
      });
      _tsdbRefs.sort((a, b) => a.Name.localeCompare(b.Name));
      var _chosenTSDBRef: TSDBRef | null = null;
      if (_tsdbRefs.length === 0) {
        _chosenTSDBRef = {
          Name: userInfo.main_tsdb_name || 'default',
          URL: userInfo.tsdb_url,
          Org: userInfo.tsdb_org,
          Token: userInfo.tsdb_token,
        };
        // Populate the refs with this "default" ref
        _tsdbRefs.push(_chosenTSDBRef);
      } else {
        _tsdbRefs.forEach((tsdbRef) => {
          if (tsdbRef.Name === userInfo.main_tsdb_name) {
            _chosenTSDBRef = tsdbRef;
          }
          // If still tsdb ref not found, take the first one (we already checked if it has more than 0 members)
          _chosenTSDBRef = { ..._tsdbRefs[0] };
        });
      }
      setAvailableTSDBRefs(_tsdbRefs);
    }
  }, [userInfo]);

  useEffect(() => {
    setIsRunsListLoading(true);
    var promises: Promise<RecentRuns>[] = [];
    availableTSDBRefs.forEach((tsdbRef) => {
      if (!tsdbRef.URL.match(/prod.ary/) || shouldIncludeInternalDBs) {
        let promise = promiseTolistRecentRuns(tsdbRef);
        promises.push(promise);
      }
    });

    Promise.all(promises).then((fulfilledValues: RecentRuns[]) => {
      // Exclude cleaning runs
      fulfilledValues.sort((a, b) => b.RunMetadatas.filter((r) => r.Name !== 'clean').length - a.RunMetadatas.filter((r) => r.Name !== 'clean').length);
      setAllRecentRuns(fulfilledValues);
      setIsRunsListLoading(false);
    });
  }, [availableTSDBRefs, startParam, shouldIncludeInternalDBs]);

  if (userInfo === null) {
    return null;
  }

  var hasAccessToInternalDBs: boolean = false;
  for (let i = 0; i < availableTSDBRefs.length; i++) {
    let tsdbRef = availableTSDBRefs[i];
    if (tsdbRef.URL.match(/prod.ary/)) {
      hasAccessToInternalDBs = true;
    }
  }

  return (
    <SectionPage>
      <Row justify="end" align="middle" style={{ marginBottom: '10px' }}>
        {hasAccessToInternalDBs ? (
          <Col>
            <Checkbox
              style={{ marginRight: '20px' }}
              checked={shouldIncludeInternalDBs}
              onChange={() => {
                setShouldIncludeInternal(!shouldIncludeInternalDBs);
              }}
            >
              Include internal DBs
            </Checkbox>
          </Col>
        ) : null}
        <Col>
          <span>Load stats for: </span>
          <Select
            style={{ minWidth: '150px' }}
            onChange={(value) => {
              setStartParam(value);
            }}
            value={startParam}
          >
            <Select.Option key="-2w" value="-2w">
              Last two weeks
            </Select.Option>
            <Select.Option key="-1mo" value="-1mo">
              Last month
            </Select.Option>
            <Select.Option key="-3mo" value="-3mo">
              Last quarter
            </Select.Option>
          </Select>
        </Col>
      </Row>
      <Row>
        {isRunsListLoading ? (
          <TableSkeleton />
        ) : (
          allRecentRuns.map((recentRuns) => {
            if (recentRuns.RunMetadatas.length === 0) {
              return null;
            }

            let effectiveRuns = recentRuns.RunMetadatas.filter((run) => run.Name !== 'clean');

            const uniqueDevicesSet: Set<string> = new Set();
            const uniquePeptideSetsSet: Set<PeptideSet> = new Set();
            const uniqueItemsNamesSet: Set<string> = new Set();
            const uniqueSWVersions: Set<string> = new Set();
            const uniqueFWVersions: Set<string> = new Set();

            var peptideSetSwitchDate: Date | null = null;

            effectiveRuns.forEach((run) => {
              uniqueDevicesSet.add(run.Device.ID);
              if (run.Device?.Info?.SpotsGrid) {
                uniquePeptideSetsSet.add(getPeptideSetType(run.Device.Info.SpotsGrid));
                if (uniquePeptideSetsSet.size > 1 && peptideSetSwitchDate === null) {
                  peptideSetSwitchDate = new Date(run.TimestampStart);
                }
              }
              uniqueSWVersions.add(run.SWVersion);
              if (run.Device?.Info?.Versions?.fsp_refdesign_fw) {
                uniqueFWVersions.add(run.Device.Info.Versions.fsp_refdesign_fw);
              }

              run.ItemNames.forEach((itemName) => {
                uniqueItemsNamesSet.add(itemName);
              });
            });

            const latestSWVersion: string = Array.from(uniqueSWVersions).sort((a, b) => b.localeCompare(a))[0];
            const latestFWVersion: string = Array.from(uniqueFWVersions).sort((a, b) => b.localeCompare(a))[0];
            const latestPeptideSet: PeptideSet = Array.from(uniquePeptideSetsSet).sort((a, b) => b - a)[0];

            let flags = (
              <>
                {renderPeptideSetType(latestPeptideSet)} {renderSwVersion(latestSWVersion)} {renderFwVersion(latestFWVersion)}
              </>
            );

            const cmap = getColormap(Array.from(uniqueItemsNamesSet));
            //</Row>{renderExposureIcon(record.calibrationExposure)}
            // Name +       ---     Flags +
            // Nb runs                +
            // Active devices         +
            // Unique items           +
            // Recent PeptideSets     +
            // Latest SW version      +
            // LAtest FW version      +
            // Activity graph?

            // <> Show inactive customers
            // <> Show internal databases (aryballe)
            return (
              <Col>
                <Card
                  style={{ margin: '20px', borderRadius: 7, boxShadow: '4px 4px 4px 0 #07062310', minWidth: '250px', maxWidth: '500px' }}
                  title={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span style={{ maxWidth: '200px' }}>
                        <Typography.Text ellipsis={true}>
                          <b>{recentRuns.TSDBRef.Name.toUpperCase()}</b>
                        </Typography.Text>
                      </span>
                      <span>{flags}</span>
                    </div>
                  }
                  hoverable={false}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      minHeight: '250px',
                    }}
                  >
                    <div>
                      <b>Runs:</b> {effectiveRuns.length}
                      <br />
                      <b>Devices:</b>
                      {uniqueDevicesSet.size > 1 ? <br /> : null}
                      {Array.from(uniqueDevicesSet).map((deviceID) => (
                        <Tag>{renderDeviceID(deviceID)}</Tag>
                      ))}
                      <br />
                      <b>SW Verison:</b> {latestSWVersion} {renderSwVersion(latestSWVersion)}
                      <br />
                      <b>FW Verison:</b> {latestFWVersion} {renderFwVersion(latestFWVersion)}
                      <br />
                      <b>Peptide Sets:</b> {Array.from(uniquePeptideSetsSet).map((ps) => renderPeptideSetType(ps))} {peptideSetSwitchDate ? <>(Swapped on {renderTimestamp(peptideSetSwitchDate)})</> : null}
                      <br style={{ marginBottom: '20px' }} />
                      <p>
                        <Collapse>
                          <Collapse.Panel key={`${recentRuns.TSDBRef.Name}-items`} header="Items">
                            {Array.from(uniqueItemsNamesSet).map((itemName) => renderColoredTag(defaultColorPalette[cmap[itemName]], <>{itemName}</>))}
                          </Collapse.Panel>
                        </Collapse>
                      </p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                      <Button type="default" target="_blank" href={`/clouddb?tsdb_ref_name=${recentRuns.TSDBRef.Name}`}>
                        Browse
                      </Button>
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })
        )}
      </Row>
    </SectionPage>
  );
};
