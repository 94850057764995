import { Slider } from 'antd';
import { SliderMarks } from 'antd/lib/slider';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { aryballeColorPalette } from '../../compute/colormap';
import './Slider.css';

type SliderComponentProps = {
  autoFocus?: boolean;
  prefixCls?: string;
  tooltipPrefixCls?: string;
  reverse?: boolean;
  min?: number;
  max?: number;
  step?: null | number;
  marks?: SliderMarks;
  dots?: boolean;
  included?: boolean;
  disabled?: boolean;
  vertical?: boolean;
  tipFormatter?: null | ((value?: number) => React.ReactNode);
  className?: string;
  id?: string;
  style?: React.CSSProperties;
  tooltipVisible?: boolean;
  tooltipPlacement?: TooltipPlacement;
  getTooltipPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  defaultValue?: number;
  value?: number;
  onChange?: (value: number) => void;
  onAfterChange?: (value: number) => void;
  handleStyle?: React.CSSProperties;
  trackStyle?: React.CSSProperties;
};

const SliderComponent: React.FC<SliderComponentProps> = ({
  defaultValue = 20,
  autoFocus = true,
  value,
  onChange,
  onAfterChange,
  handleStyle = { background: aryballeColorPalette.blue, border: aryballeColorPalette.blue },
  trackStyle = { background: aryballeColorPalette.blue, height: 6, borderRadius: 50 },
  prefixCls,
  tooltipPrefixCls,
  reverse,
  min,
  max,
  step,
  marks,
  dots,
  included,
  disabled = false,
  vertical,
  tipFormatter,
  className,
  id,
  style,
  tooltipVisible,
  tooltipPlacement,
  getTooltipPopupContainer,
}) => {
  return (
    <Slider
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      range={false}
      value={value}
      onChange={onChange}
      onAfterChange={onAfterChange}
      handleStyle={handleStyle}
      trackStyle={trackStyle}
      prefixCls={prefixCls}
      tooltipPrefixCls={tooltipPrefixCls}
      reverse={reverse}
      min={min}
      max={max}
      step={step}
      marks={marks}
      dots={dots}
      included={included}
      disabled={disabled}
      vertical={vertical}
      tipFormatter={tipFormatter}
      className={className}
      id={id}
      style={style}
      tooltipVisible={tooltipVisible}
      tooltipPlacement={tooltipPlacement}
      getTooltipPopupContainer={getTooltipPopupContainer}
    />
  );
};

export default SliderComponent;
