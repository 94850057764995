import { Col, Row, Typography } from 'antd';
import { useAppSelector } from '../../app/hooks';
import { IntensityPanel } from '../../components/graphic/intensity/Intensity';
import { Caption } from '../../components/summary/caption';
import { selectColormap } from '../../features/analysisConfig/analysisConfigSlice';
import { selectIsIncludeConfusionMatrix, selectObservationsIntensity, setObservationsIntensity } from '../../features/analysisConfig/pdfSlice';
import { AryRecord, IntensitySource } from '../../types/analysisTypes';
import { useOktaOrQueryAuth } from '../../utils';
import Legend from './legend';
import Pagebreak from './pageBreak';

type IntensityPdfProps = {
  effectiveRecords: AryRecord[];
  disabledPdf: boolean;
  sessionID: string;
};

const IntensityPdf: React.FC<IntensityPdfProps> = ({ effectiveRecords, sessionID, disabledPdf }) => {
  const cmap = useAppSelector(selectColormap);
  const observationsIntensity = useAppSelector(selectObservationsIntensity);
  const isIncludeConfusionMatrix = useAppSelector(selectIsIncludeConfusionMatrix);

  const { authState } = useOktaOrQueryAuth();

  return (
    <div id="intensityPdf">
      <Row justify="start">
        <Col>
          <Typography.Title level={4}>1.4. Intensity</Typography.Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col sm={12} span={24}>
          <div style={{ width: '100%', aspectRatio: '1/1' }}>
            <IntensityPanel sessionID={sessionID} disabledPdf={disabledPdf} />
          </div>
        </Col>
        <Col sm={12} span={24}>
          <div style={{ width: '100%', aspectRatio: '1/1' }}>
            <IntensityPanel sessionID={sessionID} defaultIntensitySource={+IntensitySource.Humidity} disabledPdf={disabledPdf} />
          </div>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Legend effectiveRecords={effectiveRecords} cmap={cmap} />
        </Col>
      </Row>
      <Caption
        title={isIncludeConfusionMatrix ? 'Fig 5' : 'Fig 4'}
        subtitle="Intensity metrics of measured samples"
        setObservations={setObservationsIntensity}
        observations={observationsIntensity}
        sessionID={sessionID}
        authState={authState}
        name="observationsIntensity"
      />
      <Pagebreak />
    </div>
  );
};

export default IntensityPdf;
