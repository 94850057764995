import { setCrumbs } from '../../features/analysisConfig/sessionInfoSlice';
import { Content } from 'antd/lib/layout/layout';
import { Route, Switch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getTrueDashboardLabel, getTrueDashboardRoute, sideRouteContent } from '../../routes';
import { selectSessionID } from '../../features/analysisConfig/analysisConfigSlice';

const AppContent: React.FC = () => {
  const sessionID = useAppSelector(selectSessionID);

  const dispatch = useAppDispatch();

  return (
    <Content style={{ marginTop: 50 }}>
      <Switch>
        {sideRouteContent.map(({ route, Component }, key) => (
          <Route
            exact
            path={route}
            key={route}
            render={(props) => {
              const crumbs = sideRouteContent
                .filter(({ route }) => props.match.path.includes(route))
                .map(({ route, label }) => {
                  const newLabel = getTrueDashboardLabel(sessionID, label);
                  const newRoute = getTrueDashboardRoute(sessionID, route);
                  return {
                    route: newRoute,
                    label: newLabel,
                  };
                });
              dispatch(setCrumbs(crumbs));
              return <Component />;
            }}
          />
        ))}
      </Switch>
    </Content>
  );
};

export default AppContent;
