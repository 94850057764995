import { LoadingOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import { CSSProperties, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { selectAggregatePeptides, selectBoundariesMap, selectColormap, selectExcludedPeptides, selectExcludedRecordIDs, selectExcludedSpots, selectRecords, selectSubtractItemName } from '../../../../features/analysisConfig/analysisConfigSlice';
import { AryRecord, AuxiliarySensorType, EligibleAditionalSensorType, TemperatureSeriesType } from '../../../../types/analysisTypes';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../../../utils';
import { SingleSensogramFigure } from './SingleSensogramFigure';

export const SingleSensogramStatic: React.FC<{
  sessionID: string;
  recordID: number;

  drawHumidity: boolean;
  drawTemperature: boolean;
  drawAuxiliarySensor: boolean;
  drawAdditionalSensor: boolean;

  temperatureType: TemperatureSeriesType;
  auxiliarySensorType: AuxiliarySensorType;
  additionalSensorType: EligibleAditionalSensorType | undefined;

  overridePlotlyLayout?: Partial<Plotly.Layout>;
  overridePlotlyConfig?: Partial<Plotly.Config>;
  style?: CSSProperties;
}> = (props) => {
  const { sessionID, recordID, drawHumidity, drawTemperature, drawAuxiliarySensor, temperatureType, auxiliarySensorType, drawAdditionalSensor, additionalSensorType, overridePlotlyConfig, overridePlotlyLayout, style } = props;

  const { authState } = useOktaOrQueryAuth();

  const aggregatePeptides = useAppSelector(selectAggregatePeptides);
  const boundariesMap = useAppSelector(selectBoundariesMap);
  const subtractItemName = useAppSelector(selectSubtractItemName);
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const records = useAppSelector(selectRecords);
  const cmap = useAppSelector(selectColormap);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const excludedPeptides = useAppSelector(selectExcludedPeptides);

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [record, setRecord] = useState<AryRecord | null>(null);
  const [sensors, setSensors] = useState<string[] | undefined>();

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    setIsLoading(true);
    if (records) setSensors(records?.find((record: AryRecord) => record.ID === recordID)?.Sensors);
    fetchAuthorizedAPIEndpoint(`/record?session_id=${sessionID}&record_id=${recordID}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        aggregatePeptides,
        subtractItemName,
        excludedRecordIDs,
        excludedSpots,
        excludedPeptides,
      }),
    })
      .then((resp) => {
        setIsLoading(false);
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((record: AryRecord) => {
        setError('');
        setRecord(record);
      })
      .catch((e) => {
        Promise.resolve(e).then((resp: { Reason: string }) => {
          setError(resp.Reason);
        });
      });
  }, [authState, sessionID, recordID, aggregatePeptides, excludedRecordIDs, subtractItemName]);

  if (error !== '') {
    return (
      <div>
        <Alert type="error" message={error} />
      </div>
    );
  }

  if (isLoading) {
    return <LoadingOutlined />;
  }

  if (record === null) {
    return null;
  }

  // Need them tobe able to mutate boundaries
  const boundaries = boundariesMap[recordID];

  return (
    <div style={style}>
      <SingleSensogramFigure
        record={record}
        sensors={sensors}
        boundaries={boundaries}
        drawHumidity={drawHumidity}
        humidityReferencePosition={null}
        drawAuxiliarySensor={drawAuxiliarySensor}
        drawTemperature={drawTemperature}
        auxiliarySensorType={auxiliarySensorType}
        drawAdditionalSensor={drawAdditionalSensor}
        additionalSensorType={additionalSensorType}
        temperatureType={temperatureType}
        cmap={cmap}
        mutateBoundaries={() => {}}
        overridePlotlyConfig={overridePlotlyConfig}
        overridePlotlyLayout={overridePlotlyLayout}
      />
    </div>
  );
};
