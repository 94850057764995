import { useOktaAuth } from '@okta/okta-react';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import SectionPage from '../../components/section/SectionPage';
import { UserClaimsWithTSDB } from '../../types/userType';
import FilterDatabase from './FilterDatabase';
import DataSourcesHome from './routesHome/DataSourcesHome';
import SavedSessionsHome from './routesHome/SavedSessionsHome';
import SqliteHome from './routesHome/SqliteHome';

export const HomePage: React.FC = () => {
  const { authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState]);

  return (
    <>
      <SectionPage backgroundColor="transparent" boxShadow={false} paddingTop={0}>
        <SectionPage id="sectionHome" boxShadow={false} paddingTop={50} paddingBottom={50} height="70vh" heightBody="100%">
          <Row style={{ height: '100%' }} align="middle" justify="center">
            <Col span={24} md={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <h1 style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 30 }}>Select your database</h1>
              <p style={{ marginBottom: 30 }}>Choose the database where the data you want to analyze are contained</p>
              <FilterDatabase userInfo={userInfo} />
            </Col>
          </Row>
        </SectionPage>
        <SectionPage backgroundColor="transparent" boxShadow={false} paddingTop={10}>
          <Row justify="space-between" align="stretch" style={{ marginBottom: 10 }} gutter={[16, 0]}>
            <Col md={8} span={24}>
              <DataSourcesHome />
            </Col>
            <Col md={8} span={24}>
              <SavedSessionsHome />
            </Col>
            {/* <Col md={6} span={24}>
              <HtmlReportHome />
            </Col> */}
            <Col md={8} span={24}>
              <SqliteHome />
            </Col>
          </Row>
        </SectionPage>
      </SectionPage>
    </>
  );
};
