import { ColumnHeightOutlined, DownloadOutlined, FileTextOutlined, FileZipOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons';
import { Button, Col, Input, Radio, Row, Typography } from 'antd';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import CardSection from '../components/section/CardSection';
import { getAPIEndpoint } from '../endpoint';

enum SensogramDispositionType {
  SingleCsv = 'single_csv',
  MultiZip = 'multi_zip',
}

enum SignaturesNormType {
  Normalized = 'normalized',
  Raw = 'raw',
}

export const CSVPage: FC = () => {
  const { sessionID } = useParams<{ sessionID: string }>();

  const [signaturesNormType, setSignaturesNormType] = useState<SignaturesNormType>(SignaturesNormType.Normalized);
  const [sensogramDispositionType, setSensogramDispositionType] = useState<SensogramDispositionType>(SensogramDispositionType.SingleCsv);
  const [filenamePrefix, setFilenamePrefix] = useState(sessionID);

  return (
    <Row justify="center">
      <Col xs={18} style={{ minHeight: '100vh' }}>
        <Row>
          <Col>
            <div style={{ margin: '20px' }}>
              <CardSection>
                <Typography.Title level={3}>Sensograms</Typography.Title>
                <Row>
                  <Col>
                    <Radio.Group buttonStyle="solid" value={sensogramDispositionType} onChange={(e) => setSensogramDispositionType(e.target.value)}>
                      <Radio.Button value={SensogramDispositionType.SingleCsv}>
                        <FileTextOutlined />
                        Single-file CSV
                      </Radio.Button>
                      <Radio.Button value={SensogramDispositionType.MultiZip}>
                        <FileZipOutlined />
                        Multi-file ZIP
                      </Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                  <Col>
                    {(function () {
                      switch (sensogramDispositionType) {
                        case SensogramDispositionType.SingleCsv:
                          return (
                            <Typography.Paragraph>
                              Generates a <b>single CSV</b> file with all frames of all records stacked one onto another, in timely order
                            </Typography.Paragraph>
                          );
                        case SensogramDispositionType.MultiZip:
                          return (
                            <Typography.Paragraph>
                              Generates a <b>ZIP archive</b> with records dumped each in their own CSV file
                            </Typography.Paragraph>
                          );
                      }
                    })()}
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <Input
                      placeholder="Enter file name.."
                      value={filenamePrefix}
                      onChange={(e) => setFilenamePrefix(e.target.value)}
                      addonAfter={(function () {
                        switch (sensogramDispositionType) {
                          case SensogramDispositionType.SingleCsv:
                            return '.sensograms.csv';
                          case SensogramDispositionType.MultiZip:
                            return '.sensograms.zip';
                        }
                      })()}
                    ></Input>
                  </Col>
                  <Col>
                    <Button type="primary">
                      <a href={`${getAPIEndpoint()}/export/records/csv?session_id=${sessionID}&disposition_mode=${sensogramDispositionType}&filename_prefix=${filenamePrefix}`} target="_blank">
                        <DownloadOutlined />
                      </a>
                    </Button>
                  </Col>
                </Row>
              </CardSection>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ margin: '20px', marginBottom: '20vh' }}>
              <CardSection>
                <Typography.Title level={3}>Signatures</Typography.Title>
                <Row>
                  <Col>
                    <Radio.Group buttonStyle="solid" value={signaturesNormType} onChange={(e) => setSignaturesNormType(e.target.value)}>
                      <Radio.Button value={SignaturesNormType.Normalized}>
                        <VerticalAlignMiddleOutlined />
                        Normalized
                      </Radio.Button>
                      <Radio.Button value={SignaturesNormType.Raw}>
                        <ColumnHeightOutlined />
                        Raw
                      </Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                  <Col>
                    {(function () {
                      switch (signaturesNormType) {
                        case SignaturesNormType.Normalized:
                          return (
                            <Typography.Paragraph>
                              Single CSV file containing <b>L2-normalized</b> signatures of the dataset. Normalized signatures are best in <b>discrimination</b> studies where <b>quality</b> of odors play most important role.
                            </Typography.Paragraph>
                          );
                        case SignaturesNormType.Raw:
                          return (
                            <Typography.Paragraph>
                              Single CSV file containing <b>raw</b> signatures of the dataset. Can be used to extract <b>quantitative metrics</b> or to perform a particular normalization.
                            </Typography.Paragraph>
                          );
                      }
                    })()}
                    <Typography.Paragraph>Does not contain excluded records. Signatures are extracted at defined analyte zones.</Typography.Paragraph>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <Input placeholder="Enter file name.." value={filenamePrefix} onChange={(e) => setFilenamePrefix(e.target.value)} addonAfter={`${signaturesNormType}.signatures.csv`}></Input>
                  </Col>
                  <Col>
                    <Button type="primary">
                      <a rel="noreferrer" href={`${getAPIEndpoint()}/export/signatures/csv?session_id=${sessionID}&norm_type=${signaturesNormType}&filename_prefix=${filenamePrefix}`} target="_blank">
                        <DownloadOutlined />
                      </a>
                    </Button>
                  </Col>
                </Row>
              </CardSection>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
