import { Divider } from 'antd';

const Pagebreak: React.FC = () => {
  return (
    <div className="pagebreak">
      <Divider />
    </div>
  );
};
export default Pagebreak;
