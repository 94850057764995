import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { CrumbsType } from '../../components/breadcrumb/Breadcrumb';
import { AppRoute } from '../../routes';
import { SessionInfoType } from '../../types/sessionType';

const initialState: SessionInfoType = {
  Crumbs: [
    {
      route: AppRoute.DASHBOARD,
      label: 'Home',
    },
  ],
  VisibleSettings: false,
  VisibleDeleteRuns: false,
};

export const sessionInfoSlice = createSlice({
  name: 'sessionInfo',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCrumbs: (state, action: PayloadAction<CrumbsType[]>) => {
      state.Crumbs = action.payload;
    },
    setVisibleSettings: (state, action: PayloadAction<boolean>) => {
      state.VisibleSettings = action.payload;
    },
    setVisibleDeleteRuns: (state, action: PayloadAction<boolean>) => {
      state.VisibleDeleteRuns = action.payload;
    },
  },
});

export const { setCrumbs, setVisibleSettings, setVisibleDeleteRuns } = sessionInfoSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCrumbs = (state: RootState) => state.sessionInfo.Crumbs;
export const selectVisibleSettings = (state: RootState) => state.sessionInfo.VisibleSettings;
export const selectVisibleDeleteRuns = (state: RootState) => state.sessionInfo.VisibleDeleteRuns;

export default sessionInfoSlice.reducer;
