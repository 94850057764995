import { defaultColorPalette } from '../../compute/colormap';
import { renderColoredTag } from '../../compute/utils';
import { AnalysisConfig, AryRecord } from '../../types/analysisTypes';

const Legend: React.FC<{ effectiveRecords: AryRecord[]; cmap: AnalysisConfig['Colormap'] }> = (props) => {
  const { cmap } = props;
  if (cmap === undefined) {
    return null;
  }
  return (
    <div style={{ marginTop: '30px' }}>
      {Object.entries(cmap).map(([k, v]) => {
        return renderColoredTag(defaultColorPalette[v], <>{k}</>);
      })}
    </div>
  );
};
export default Legend;
