import { Alert, AlertProps, Button, Col, message, Row, Select, Space, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import { AnalysisConfig, PeptideSet } from '../types/analysisTypes';
import { RunsTable } from '../components/runs_table';
import { useOktaAuth } from '@okta/okta-react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { resetAnalysisConfig } from '../features/analysisConfig/analysisConfigSlice';
import { TableSkeleton } from '../components/table_skeleton';
import SectionPage from '../components/section/SectionPage';
import { getPeptideSetType, renderPeptideSetType } from '../compute/utils';
import { fetchAuthorizedAPIEndpoint, useQueryParam } from '../utils';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Header } from 'antd/lib/layout/layout';
import { useMediaQuery } from '../App';
import DeleteRuns from '../components/DeleteRuns';
import { selectVisibleDeleteRuns, setVisibleDeleteRuns } from '../features/analysisConfig/sessionInfoSlice';
import { setAvailableTSDB } from '../features/analysisConfig/runsSlice';
import { FeatureFlag, UserClaimsWithTSDB } from '../types/userType';
import { RunMetadata, SelectedRun, SourceType, TSDBRef } from '../types/runsType';

export const CloudDBSelectorComponent: FC = () => {
  const [runsMap, setRunsMap] = useState<Record<TSDBRef['Name'], RunMetadata[]>>({});
  const [isError, setIsError] = useState<boolean>(false);
  const [isRunsListLoading, setIsRunsListLoading] = useState(true);
  const [isTransferringHandle, setIsTransferringHandle] = useState<boolean>(false);
  const [retryCounter, setRetryCounter] = useState<number>(0);
  const [selectedRunsMap, setSelectedRuns] = useState<Record<TSDBRef['Name'], SelectedRun[]>>({});
  const [availableTSDBRefs, setAvailableTSDBRefs] = useState<TSDBRef[]>([]);
  const [chosenTSDBRef, setChosenTSDBRef] = useState<TSDBRef | null | undefined>(null);
  const [userInfo, setUserInfo] = useState<UserClaimsWithTSDB | null>(null);
  const [selectedDeleteRuns, setSelectedDeleteRuns] = useState<string[]>([]);

  const [startParam, setStartParam] = useQueryParam('start', '-2w');
  const [chosenTSDBRefName, setChosenTSDBRefName] = useQueryParam('tsdb_ref_name', '');

  const visibleDeleteRuns = useAppSelector<boolean>(selectVisibleDeleteRuns);

  const { authState } = useOktaAuth();
  const isRowBased = useMediaQuery('(max-width: 768px)');

  const isCollapsed: boolean = true;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (availableTSDBRefs.length === 0) {
      return;
    }
    for (let i = 0; i < availableTSDBRefs.length; i++) {
      let tsdbRef = availableTSDBRefs[i];
      if (chosenTSDBRefName === tsdbRef.Name) {
        if (selectedRunsMap[tsdbRef.Name] === undefined) {
          selectedRunsMap[tsdbRef.Name] = [];
        }
        setChosenTSDBRef(tsdbRef);
        return;
      }
    }
    setChosenTSDBRef(undefined);
  }, [chosenTSDBRefName]);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      if (authState.idToken !== undefined && authState.idToken.claims !== undefined) {
        setUserInfo(authState.idToken.claims as UserClaimsWithTSDB);
      }
    }
  }, [authState]);

  useEffect(() => {
    dispatch(resetAnalysisConfig());
    if (userInfo === null || chosenTSDBRef === null || chosenTSDBRef === undefined) {
      return;
    }
    setIsRunsListLoading(true);

    fetchAuthorizedAPIEndpoint(
      `/clouddb/list_runs?start=${startParam}`,
      authState,
      {
        method: 'POST',
        body: JSON.stringify(chosenTSDBRef),
      },
      chosenTSDBRef.URL
    )
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw new Error();
        }
      })
      .then((runs: RunMetadata[] | null) => {
        if (runs === null) {
          runs = [];
        }
        var _runsMap = { ...runsMap };
        _runsMap[chosenTSDBRef.Name] = runs.map((run) => (run = { ...run, Source: { Name: chosenTSDBRef.Name, Type: SourceType.TSDB } }));
        console.log(_runsMap[chosenTSDBRef.Name]);
        setRunsMap(_runsMap);
        setIsRunsListLoading(false);
      })
      .catch((e: Error) => {
        setIsError(true);
        setIsRunsListLoading(false);
      });
  }, [userInfo, retryCounter, startParam, chosenTSDBRef]);

  // Executes ones once after tyhe userInfo is available. It will parse the claims into tsdbRefs list
  useEffect(() => {
    if (userInfo !== null) {
      var _tsdbRefs: TSDBRef[] = [];
      userInfo.tsdb_refs.forEach((tsdbRefStr) => {
        let [name, url, token] = tsdbRefStr.split('~');
        let org: string = '';
        let urlOrgRe = /(.*?)\/orgs\/(.*?)$/;
        let m = url.match(urlOrgRe);
        if (m !== null) {
          url = m[1]; // 0 is the full match
          org = m[2];
        }
        if (name && url && token && org) {
          let tsdbRef: TSDBRef = {
            Name: name,
            URL: url,
            Org: org,
            Token: token,
          };
          _tsdbRefs.push(tsdbRef);
        } else {
          console.log('Failed to parse tsdb ref: ', { tsdbRefStr, name, url, org, token });
        }
      });
      _tsdbRefs.sort((a, b) => a.Name.localeCompare(b.Name));
      var _chosenTSDBRef: TSDBRef | null = null;
      if (_tsdbRefs.length === 0) {
        _chosenTSDBRef = {
          Name: userInfo.main_tsdb_name || 'default',
          URL: userInfo.tsdb_url,
          Org: userInfo.tsdb_org,
          Token: userInfo.tsdb_token,
        };
        // Populate the refs with this "default" ref
        _tsdbRefs.push(_chosenTSDBRef);
      } else {
        _tsdbRefs.forEach((tsdbRef) => {
          if (tsdbRef.Name === userInfo.main_tsdb_name) {
            _chosenTSDBRef = tsdbRef;
          }
        });
        // If still tsdb ref not found, take the first one (we already checked if it has more than 0 members)
        if (_chosenTSDBRef === null) {
          _chosenTSDBRef = { ..._tsdbRefs[0] };
        }
      }
      setAvailableTSDBRefs(_tsdbRefs);
      dispatch(setAvailableTSDB(_tsdbRefs));
      if (_chosenTSDBRef !== null) {
        if (selectedRunsMap[_chosenTSDBRef.Name] === undefined) {
          selectedRunsMap[_chosenTSDBRef.Name] = [];
        }
        if (chosenTSDBRefName === '') {
          setChosenTSDBRefName(_chosenTSDBRef.Name);
          setChosenTSDBRef(_chosenTSDBRef);
        } else {
          for (let i = 0; i < _tsdbRefs.length; i++) {
            let tsdbRef = _tsdbRefs[i];
            if (chosenTSDBRefName === tsdbRef.Name) {
              if (selectedRunsMap[tsdbRef.Name] === undefined) {
                selectedRunsMap[tsdbRef.Name] = [];
              }
              setChosenTSDBRefName(tsdbRef.Name);
              setChosenTSDBRef(tsdbRef);
              return;
            }
          }
          setChosenTSDBRef(undefined);
        }
      } else {
        console.log('Failed to deduce the chosen TSDBRef', { _tsdbRefs, userInfo });
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (selectedDeleteRuns.length === 0) setVisibleDeleteRuns(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (userInfo === null || chosenTSDBRef === null) {
    // Case of still loading the page
    return null;
  }

  if (chosenTSDBRef === undefined && availableTSDBRefs.length > 0) {
    setChosenTSDBRef(availableTSDBRefs[0]);
  }

  if (chosenTSDBRef === undefined) {
    // Case of chosen TSDB to not exist in userInfo claims
    return (
      <>
        <SectionPage>
          <Row>
            <Col>
              <Alert
                message={
                  <>
                    Could not find the <b>{chosenTSDBRefName.toUpperCase()}</b>'s Cloud DB.
                    <br />
                    Please make sure if you are a <b>member of</b> the appropriate group.
                    <br />
                    For this, check your <b>Okta</b> account at{' '}
                    <a href="https://auth.aryballe.com" rel="noreferrer" target="_blank">
                      https://auth.aryballe.com
                    </a>{' '}
                    or contact <a href="mailto:support@aryballe.com">support@aryballe.com</a>
                    <br />
                    indicating you wish to join the group in question
                  </>
                }
              ></Alert>
            </Col>
          </Row>
        </SectionPage>
      </>
    );
  }

  // Case of TSDB-less account
  if (availableTSDBRefs.length === 1 && (chosenTSDBRef.Org === '' || chosenTSDBRef.Token === '' || chosenTSDBRef.URL === '')) {
    return (
      <SectionPage>
        <Row>
          <Col>
            <Alert
              message={
                <>
                  Your account does not have any associated Cloud Databases.
                  <br />
                  If you beleive this is due to an error, please check your <b>Okta</b> account at{' '}
                  <a href="https://auth.aryballe.com" target="_blank" rel="noreferrer">
                    https://auth.aryballe.com
                  </a>
                  <br />
                  or contact <a href="mailto:support@aryballe.com">support@aryballe.com</a>
                </>
              }
            ></Alert>
          </Col>
        </Row>
      </SectionPage>
    );
  }

  if (isError) {
    var msg: JSX.Element | null = null;
    var type: AlertProps['type'] = 'info';
    if (chosenTSDBRef.URL.match(/influxdb.prod.ary/i)) {
      msg = (
        <span>
          Aryballe security policy requires users of internally hosted CloudDB to be either
          <br />
          on site @mambo, either use the corporate VPN.
          <br />
          If you need help with VPN installation, please contact <a href="mailto:helpdesk@aryballe.com">IT Helpdesk</a>
        </span>
      );
    } else {
      type = 'error';
      msg = (
        <span>
          An error occurred when connecting to <b>Cloud Storage</b>.
        </span>
      );
    }
    return (
      <SectionPage>
        <Row>
          <Col span={24}>
            <Alert
              style={{ textAlign: 'center', marginTop: '30vh' }}
              showIcon
              type={type}
              message={msg}
              action={
                <Space>
                  <Button
                    size="small"
                    onClick={() => {
                      setIsError(false);
                      setRetryCounter(retryCounter + 1);
                    }}
                  >
                    Retry
                  </Button>
                </Space>
              }
            />
          </Col>
        </Row>
      </SectionPage>
    );
  }

  if (runsMap[chosenTSDBRef.Name] === undefined) {
    runsMap[chosenTSDBRef.Name] = [];
  }

  let uniqueItemsSet: Set<string> = new Set();
  runsMap[chosenTSDBRef.Name].forEach((run) => {
    run.ItemNames.forEach((itemName) => {
      uniqueItemsSet.add(itemName);
    });
  });
  let uniqueItems: string[] = Array.from(uniqueItemsSet);
  uniqueItems.sort();

  var allSelectedRuns: SelectedRun[] = [];
  Object.entries(selectedRunsMap).forEach(([_, runs]) => {
    runs.forEach((run) => {
      allSelectedRuns.push(run);
    });
  });

  const isDisabled = () => {
    return allSelectedRuns.length === 0;
  };

  const callForHandleIDs = (runs: SelectedRun[]) => {
    return new Promise<string[]>((resolve, reject) => {
      var handleIDs: string[] = [];
      if (userInfo === null || chosenTSDBRef === null) {
        throw null;
      }
      if (runs.length === 0) {
        throw null;
      }

      var shouldNotAggregatePeptides: boolean = false;
      userInfo.feature_flags.forEach((ff) => {
        if (ff === FeatureFlag.AANonAggregatedPeptidesAccessEnabled) {
          shouldNotAggregatePeptides = true;
        }
        if (ff === FeatureFlag.RoleAryballe) {
          shouldNotAggregatePeptides = true;
        }
      });

      let query = new URLSearchParams({
        run_uids: runs.map((run) => run.UID).join(','),
        stream_progress: '0',
      });
      if (shouldNotAggregatePeptides) {
        query.set('aggregate_peptides', '0');
      }

      fetchAuthorizedAPIEndpoint(
        `/clouddb/get_runs/handles?${query.toString()}`,
        authState,
        {
          method: 'POST',
          body: JSON.stringify(runs[0].TSDBRef),
        },
        chosenTSDBRef.URL
      )
        .then((resp: Response) => {
          return resp.json();
        })
        .then((body: { HandleIDs: string[] }) => {
          handleIDs = body.HandleIDs;
          resolve(handleIDs);
        })
        .catch((e) => {
          setIsTransferringHandle(false);
          setIsRunsListLoading(false);
          if (typeof e.then === 'function') {
            e.then((resp: { Reason: string }) => {
              message.error(`Error occured during data fetching: ${resp.Reason}`, 5);
            });
          } else {
            throw e;
          }
        })
        .catch((e) => {
          message.error(`Unexpected error occured. Probably a network or DB error. Please contact support: ${e}`, 5);
          throw e;
        });
    });
  };
  const handleContinue = () => {
    if (userInfo === null || chosenTSDBRef === null) {
      return;
    }

    setIsTransferringHandle(true);
    var promises: Promise<string[]>[] = [];
    Object.entries(selectedRunsMap).forEach(([k, selectedRuns]) => {
      if (selectedRuns.length !== 0) {
        availableTSDBRefs.forEach((tsdbRef) => {
          if (tsdbRef.Name === k) {
            selectedRuns.forEach((selectedRun) => {
              selectedRun.TSDBRef = tsdbRef;
            });
            promises.push(callForHandleIDs(selectedRuns));
          }
        });
      }
    });
    Promise.all(promises).then((fulfilledValues) => {
      const allHandleIDs: string[] = fulfilledValues.flat();

      fetchAuthorizedAPIEndpoint(`/init_session?handle_ids=${allHandleIDs.join(',')}`, authState)
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            throw resp.json();
          }
        })
        .catch((e) => {
          setIsTransferringHandle(false);
          e.then((resp: { Reason: string }) => {
            message.error(`Error occured during data digestion in the cloud: ${resp.Reason}`, 5);
          });
          return;
        })
        .then((analysisConfig: AnalysisConfig) => {
          dispatch(resetAnalysisConfig());
          window.open(`/dashboard/${analysisConfig.SessionID}`, '_blank');
          setIsTransferringHandle(false);
        });
    });
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Col style={{ width: visibleDeleteRuns ? 'calc(100% - 300px)' : '100%' }}>
          <SectionPage backgroundColor={undefined} marginBottom={120}>
            <Row justify={availableTSDBRefs.length > 1 ? 'space-between' : 'end'}>
              {availableTSDBRefs.length > 1 ? (
                <Col style={{ marginBottom: 20, marginTop: -24 }}>
                  <span>Source Database: </span>
                  <Select
                    style={{ minWidth: '200px', marginTop: '10px' }}
                    disabled={isTransferringHandle}
                    onChange={(value) => {
                      if (typeof value !== 'string') {
                      } else {
                        setChosenTSDBRefName(value);
                      }
                    }}
                    value={chosenTSDBRefName}
                  >
                    {availableTSDBRefs.map((tsdbRef) => {
                      return (
                        <Select.Option key={tsdbRef.Name} value={tsdbRef.Name}>
                          {tsdbRef.Name.toUpperCase()}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Col>
              ) : null}
              <Col style={{ marginBottom: 20, marginTop: -24 }}>
                <span>Preload experiments for: </span>
                <Select
                  style={{ minWidth: '150px', marginTop: '10px' }}
                  onChange={(value) => {
                    setStartParam(value);
                  }}
                  value={startParam}
                >
                  <Select.Option key="all-time" value="0">
                    All-time
                  </Select.Option>
                  <Select.Option key="-12mo" value="-12mo">
                    Last year
                  </Select.Option>
                  <Select.Option key="-6mo" value="-6mo">
                    Last semester
                  </Select.Option>
                  <Select.Option key="-3mo" value="-3mo">
                    Last quarter
                  </Select.Option>
                  <Select.Option key="-2w" value="-2w">
                    Last two weeks
                  </Select.Option>
                </Select>
              </Col>
            </Row>
            {isRunsListLoading ? (
              <TableSkeleton />
            ) : (
              <>
                <Row>
                  <Col span={24}>
                    <RunsTable
                      runs={runsMap[chosenTSDBRef.Name]}
                      sourceRef={chosenTSDBRef}
                      selectedRunsMap={selectedRunsMap}
                      setSelectedRunsMap={setSelectedRuns}
                      selectedDeleteRuns={selectedDeleteRuns}
                      setSelectedDeleteRuns={setSelectedDeleteRuns}
                    />
                  </Col>
                </Row>
              </>
            )}
          </SectionPage>
          <Row>
            <Header
              style={{
                background: 'white',
                padding: '10px 30px',
                position: 'fixed',
                bottom: isRowBased ? 48 : 0,
                lineHeight: '10px',
                minHeight: 93,
                left: isRowBased ? '0px' : isCollapsed ? '60px' : '240px',
                width: isRowBased ? '100%' : `calc(100% - (${isCollapsed ? '60px' : '240px'} + ${visibleDeleteRuns ? '300px' : '0px'}))`,
                zIndex: 2,
                boxShadow: '1px -1px 4px #00000010',
              }}
            >
              {!isRunsListLoading && (
                <>
                  <Col>
                    <Row align="top" style={{ marginBottom: 10 }}>
                      {Object.entries(selectedRunsMap).map(([tsdbName, selectedRuns]) => {
                        if (selectedRuns.length === 0) {
                          return null;
                        }
                        for (let i = 0; i < availableTSDBRefs.length; i++) {
                          let tsdbRef = availableTSDBRefs[i];
                          if (tsdbRef.Name === tsdbName) {
                            return (
                              <>
                                <Typography.Text
                                  copyable={{
                                    icon: [<CloseOutlined style={{ color: 'red' }} />, <CheckOutlined />],
                                    tooltips: ['Unselect these runs', ''],
                                    onCopy: (e) => {
                                      let _selectedRuns = { ...selectedRunsMap };
                                      _selectedRuns[tsdbName] = [];
                                      setSelectedRuns(_selectedRuns);
                                    },
                                  }}
                                >
                                  Selected <b>{selectedRuns.length}</b> runs from {tsdbName.toUpperCase()}'s Cloud DB{' '}
                                  {(function () {
                                    var uniquePpetideSets = new Set<PeptideSet>();
                                    if (runsMap[tsdbName] === undefined) {
                                      return '(?)';
                                    }
                                    selectedRuns.forEach((selectedRun) => {
                                      runsMap[selectedRun.TSDBRef.Name].forEach((run) => {
                                        if (run.UID === selectedRun.UID) {
                                          let ps = getPeptideSetType(run.Device?.Info?.SpotsGrid);
                                          uniquePpetideSets.add(ps);
                                        }
                                      });
                                    });
                                    return Array.from(uniquePpetideSets).map((ps) => renderPeptideSetType(ps));
                                  })()}
                                </Typography.Text>
                                <br></br>
                              </>
                            );
                          }
                        }
                        // Case when there are runs selected for an unavailable database sources
                        return (
                          <>
                            Selected <b>{selectedRuns.length}</b> from an unavailbale Database ({tsdbName.toUpperCase()}). These runs will be omitted.
                          </>
                        );
                      })}
                    </Row>
                    <Row justify="center" style={{ marginTop: '0px' }}>
                      <Col xs={24}>
                        <Button onClick={handleContinue} size="large" style={{ width: '100%', borderRadius: 5 }} type="primary" disabled={isDisabled()} loading={isTransferringHandle}>
                          Continue
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
            </Header>
          </Row>
        </Col>

        <DeleteRuns selectedDeleteRuns={selectedDeleteRuns} setSelectedDeleteRuns={setSelectedDeleteRuns} runsMap={runsMap} startParam={startParam} chosenTSDBRef={chosenTSDBRef} setIsRunsListLoading={setIsRunsListLoading} setRunsMap={setRunsMap} />
      </div>
    </>
  );
};

export const CloudDBPage: FC = () => {
  return <CloudDBSelectorComponent />;
};
