import { Alert } from 'antd';
import { Data, Layout } from 'plotly.js';
import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { useAppSelector } from '../../../app/hooks';
import { defaultPlotlyArguments } from '../../../compute/utils';
import {
  selectAggregatePeptides,
  selectBoundariesMap,
  selectChemicalCalibrationItemNames,
  selectExcludedPeptides,
  selectExcludedRecordIDs,
  selectExcludedSpots,
  selectHumidityCompensationCalibrantName,
  selectHumidityCompensationPositionOffset,
  selectHumidityCompensationSubstractionGain,
  selectRecords,
  selectSubtractItemName,
} from '../../../features/analysisConfig/analysisConfigSlice';
import { AryRecord, ConfusionMatrix } from '../../../types/analysisTypes';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../../utils';

type DistanceMatrixProps = {
  sessionID: string;
  seletedModelsConfusion: string | undefined;
};

const ConfusionMatrixPanel: React.FC<DistanceMatrixProps> = ({ sessionID, seletedModelsConfusion }) => {
  const { authState } = useOktaOrQueryAuth();

  const [error, setError] = useState<string | undefined>();
  const [confusionMatrix, setConfusionMatrix] = useState<ConfusionMatrix>();

  const boundariesMap = useAppSelector<Record<number, number[]>>(selectBoundariesMap);
  const excludedRecordIDs = useAppSelector<number[]>(selectExcludedRecordIDs);
  const subtractItemName = useAppSelector<string>(selectSubtractItemName);
  const aggregatePeptides = useAppSelector<boolean>(selectAggregatePeptides);
  const records = useAppSelector<AryRecord[] | undefined>(selectRecords);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const excludedPeptides = useAppSelector(selectExcludedPeptides);

  const humidityCalibrationCalibrantName = useAppSelector(selectHumidityCompensationCalibrantName);
  const humidityCalibrationPositionOffset = useAppSelector(selectHumidityCompensationPositionOffset);
  const humidityCalibrationSubstractionGain = useAppSelector(selectHumidityCompensationSubstractionGain);

  const chemicalCalibrationItemNames = useAppSelector(selectChemicalCalibrationItemNames);

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    if (!records || records.length === 0) {
      setError('No confusion matrix because no records');
      return;
    } else setError(undefined);

    if (seletedModelsConfusion === undefined) return;

    fetchAuthorizedAPIEndpoint(`/compute/confmat?session_id=${sessionID}&model_name=${seletedModelsConfusion}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        boundariesMap,
        excludedRecordIDs,
        aggregatePeptides,
        subtractItemName,
        excludedPeptides,
        excludedSpots,
        humidityCompensation: {
          calibrantName: humidityCalibrationCalibrantName,
          positionOffset: humidityCalibrationPositionOffset,
          SubstractionGain: humidityCalibrationSubstractionGain,
        },
        chemicalCalibrationItemNames,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw resp.json();
        }
      })
      .then((receivedMatrix: ConfusionMatrix) => {
        console.log(receivedMatrix);
        if (receivedMatrix.PredictedLabels === null || receivedMatrix.TrueLabels === null || receivedMatrix.Values === null) setError('No labels or values in confusion matrix.');
        setConfusionMatrix(receivedMatrix);
      })
      .catch((e) => {
        Promise.resolve(e).then((resp: { Reason: string }) => {
          console.log(resp);
          setError(resp.Reason);
        });
      });
  }, [
    authState,
    records,
    aggregatePeptides,
    boundariesMap,
    excludedRecordIDs,
    sessionID,
    subtractItemName,
    excludedPeptides,
    excludedSpots,
    humidityCalibrationCalibrantName,
    humidityCalibrationPositionOffset,
    humidityCalibrationSubstractionGain,
    chemicalCalibrationItemNames,
    seletedModelsConfusion,
  ]);

  if (error !== undefined || !confusionMatrix || confusionMatrix.PredictedLabels === null || confusionMatrix.TrueLabels === null || confusionMatrix.Values === null) {
    return <Alert type="error" message={error} style={{ borderRadius: 5, lineHeight: 1, marginTop: 20 }} />;
  }

  var data: Data[] = [
    {
      x: confusionMatrix?.PredictedLabels,
      y: confusionMatrix?.TrueLabels,
      z: confusionMatrix?.Values,
      type: 'heatmap',
      colorscale: 'RdBu',
      showscale: false,
    },
  ];

  var layout: Partial<Layout> = {
    annotations: [],
    xaxis: {
      ticks: '',
      side: 'top',
      automargin: true,
      title: 'Predicted label',
      type: 'category',
      range: [-0.5, confusionMatrix?.PredictedLabels.length - 0.5],
    },
    yaxis: {
      ticks: '',
      ticksuffix: ' ',
      automargin: true,
      title: 'True label',
      type: 'category',
      range: [confusionMatrix?.TrueLabels.length - 0.5, -0.5],
    },
  };

  if (confusionMatrix && confusionMatrix.Values.length < 15) {
    for (let i = 0; i < confusionMatrix.TrueLabels.length; i++) {
      for (let j = 0; j < confusionMatrix.PredictedLabels.length; j++) {
        let textColor = 'white';
        let result = {
          x: confusionMatrix.PredictedLabels[j],
          y: confusionMatrix.TrueLabels[i],
          text: confusionMatrix.Values[i][j].toFixed(0),
          font: {
            family: 'Arial',
            size: 12,
            color: textColor,
          },
          showarrow: false,
        };
        layout?.annotations?.push(result);
      }
    }
  }

  return (
    <div tabIndex={0} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
      <Plot
        divId="distance_matrix_scatter_plot"
        debug={true}
        data={data}
        layout={{
          ...defaultPlotlyArguments.layout,
          ...layout,
        }}
        useResizeHandler={true}
        config={defaultPlotlyArguments.config}
        style={defaultPlotlyArguments.style}
      />
    </div>
  );
};

export default ConfusionMatrixPanel;
