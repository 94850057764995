import { Select } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectInterpretation, setInterpretation } from '../../../features/analysisConfig/analysisConfigSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arycolor } from '../../../assets/css/color';
import { defaultColorPalette } from '../../../compute/colormap';
import { FeatureFlag, UserClaimsWithTSDB } from '../../../types/userType';

type InterpretationResultSettingsProps = {
  userInfo: UserClaimsWithTSDB | null;
};

const InterpretationResultSettings: React.FC<InterpretationResultSettingsProps> = ({ userInfo }) => {
  const dispatch = useAppDispatch();
  const interpretation = useAppSelector(selectInterpretation);

  const options = [
    {
      label: (
        <>
          <FontAwesomeIcon icon="check" style={{ color: defaultColorPalette.green, marginRight: 10 }} />
          <span>Pass when similar</span>
        </>
      ),
      value: 1,
    },
    {
      label: (
        <>
          <FontAwesomeIcon icon="times" style={{ color: defaultColorPalette.red, marginRight: 10 }} />
          <span>Fail when similar</span>
        </>
      ),
      value: -1,
    },
    {
      label: (
        <>
          <FontAwesomeIcon icon="circle" style={{ color: defaultColorPalette.greater, marginRight: 10 }} />
          <span>NA</span>
        </>
      ),
      value: 0,
    },
  ];

  return (
    <div style={{ marginBottom: 30 }}>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <span>Comparison interpretation</span>
      </div>
      <Select
        value={interpretation}
        style={{ width: '100%', marginTop: 10 }}
        disabled={userInfo === null || userInfo.feature_flags.includes(FeatureFlag.AALightViewQualityControl)}
        showSearch={true}
        allowClear={true}
        options={options}
        onChange={(value: number) => {
          dispatch(setInterpretation(value));
        }}
      />
    </div>
  );
};

export default InterpretationResultSettings;
