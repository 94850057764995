import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCloud,
  faDatabase,
  faFileAlt,
  faDashboard,
  faUserCog,
  faInfoCircle,
  faFlask,
  faListOl,
  faCog,
  faUnlockAlt,
  faLock,
  faLockOpen,
  faChartSimple,
  faShareSquare,
  faCheck,
  faCogs,
  faCaretDown,
  faCaretRight,
  faTable,
  faProjectDiagram,
  faFileExport,
  faPlus,
  faMinus,
  faFilter,
  faArrowTrendUp,
  faArrowTrendDown,
  faCheckCircle,
  faCircle,
  faSearch,
  faCircleQuestion,
  faExclamationTriangle,
  faExternalLinkAlt,
  faSave,
  faFileUpload,
  faQuestionCircle,
  faCommentDots,
  faBookOpen,
  faHandsHelping,
  faTrash,
  faTimes,
  faPlusSquare,
  faSearchPlus,
  faDroplet,
  faBalanceScale,
  faTimesCircle,
  faSync,
  faArrowsSpin,
} from '@fortawesome/free-solid-svg-icons';
import './compute/colormap.sass';
import './assets/css/style.css';
import 'antd/dist/antd.css';

library.add(
  faProjectDiagram,
  faCloud,
  faDatabase,
  faFileAlt,
  faDashboard,
  faUserCog,
  faInfoCircle,
  faFlask,
  faListOl,
  faCog,
  faUnlockAlt,
  faLock,
  faLockOpen,
  faChartSimple,
  faShareSquare,
  faCheck,
  faCogs,
  faCaretDown,
  faCaretRight,
  faTable,
  faFileExport,
  faPlus,
  faMinus,
  faFilter,
  faArrowTrendUp,
  faArrowTrendDown,
  faCheckCircle,
  faCircle,
  faSearch,
  faCircleQuestion,
  faExclamationTriangle,
  faExternalLinkAlt,
  faSave,
  faFileUpload,
  faQuestionCircle,
  faCommentDots,
  faBookOpen,
  faHandsHelping,
  faTrash,
  faTimes,
  faTimesCircle,
  faPlusSquare,
  faSearchPlus,
  faDroplet,
  faBalanceScale,
  faSync,
  faArrowsSpin
);

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
