import { useAppSelector } from '../../../app/hooks';
import { selectSensogramRenderType } from '../../../features/analysisConfig/analysisConfigSlice';
import { SensogramRenderType } from '../../../types/analysisTypes';

import { ItemSignaturesWidget } from './ItemSignaturesWidget';
import { MultiSignaturesWidget } from './MultiSignaturesWidget';

import { SingleSignatureWidget } from './SingleSignatureWidget';

export const SignaturePanel: React.FC<{ sessionID: string }> = ({ sessionID }) => {
  const sensogramRenderType = useAppSelector(selectSensogramRenderType);
  return (
    <div style={{ width: '100%', height: '40vh', display: 'flex', flexDirection: 'row' }}>
      {(function () {
        switch (sensogramRenderType) {
          case +SensogramRenderType.Record:
            return <SingleSignatureWidget />;
          case +SensogramRenderType.Item:
            return <ItemSignaturesWidget />;
          case +SensogramRenderType.Dataset:
            return <MultiSignaturesWidget />;
          default:
            return null;
        }
      })()}
    </div>
  );
};
