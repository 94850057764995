import { Alert } from 'antd';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { selectAggregatePeptides, selectBoundariesMap, selectCurrentRecordID, selectExcludedPeptides, selectExcludedRecordIDs, selectExcludedSpots, selectSubtractItemName } from '../../../features/analysisConfig/analysisConfigSlice';
import { PredictedChemicalFamily } from '../../../types/analysisTypes';
import { fetchAuthorizedAPIEndpoint, useOktaOrQueryAuth } from '../../../utils';
import { ChemicalFamiliesPlot } from './ChemicalFamiliesPlot';

export const SignaturePie: React.FC<{ sessionID: string }> = ({ sessionID }) => {
  const recordID = useAppSelector(selectCurrentRecordID);

  const { authState } = useOktaOrQueryAuth();

  const aggregatePeptides = useAppSelector(selectAggregatePeptides);
  const subtractItemName = useAppSelector(selectSubtractItemName);
  const excludedRecordIDs = useAppSelector(selectExcludedRecordIDs);
  const excludedSpots = useAppSelector(selectExcludedSpots);
  const excludedPeptides = useAppSelector(selectExcludedPeptides);
  const [chemicalFamilies, setChemicalFamilies] = useState<PredictedChemicalFamily | null>(null);

  const boundariesMap = useAppSelector(selectBoundariesMap);

  useEffect(() => {
    if (authState === null || !authState.accessToken) {
      return;
    }
    var boundaries = boundariesMap[recordID];
    if (boundaries === undefined) {
      return;
    }
    fetchAuthorizedAPIEndpoint(`/compute/chemical_families?session_id=${sessionID}&record_id=${recordID}`, authState, {
      method: 'POST',
      body: JSON.stringify({
        sessionID,
        aggregatePeptides,
        boundariesMap,
        excludedRecordIDs,
        subtractItemName,
        excludedSpots,
        excludedPeptides,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          throw new Error();
        }
      })
      .then((data: PredictedChemicalFamily) => {
        setChemicalFamilies(data);
      })
      .catch((e) => {
        setChemicalFamilies(null);
      });
  }, [authState, sessionID, recordID, boundariesMap, excludedRecordIDs, subtractItemName, excludedPeptides, excludedSpots]);

  if (chemicalFamilies === null) {
    return (
      <>
        <div style={{ margin: '20px' }}>
          <Alert
            type="info"
            message={
              <>
                This peptide set is not supported by current <b>Chemical families</b> prediction model
              </>
            }
          ></Alert>
        </div>
      </>
    );
  }

  return <ChemicalFamiliesPlot chemicalFamilies={chemicalFamilies} />;
};
