type IProps = {
  showTitle: boolean;
};

export const AppLogo: React.FC<IProps> = ({ showTitle }) => {
  const logoSrc = showTitle ? '/aryballe-tm-white-transparent.png' : '/logo64.png';

  const logoWidth = showTitle ? '140px' : '38px';
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <div style={{ marginTop: '16px', textAlign: 'center' }}>
        <img alt="logo" style={{ width: logoWidth, maxWidth: '100%', maxHeight: '100%' }} src={`${logoSrc}`} />
      </div>
      {showTitle ? <span style={{ color: 'white', width: '100%', textAlign: 'center' }}>Digital Olfaction Hub</span> : null}
    </div>
  );
};
